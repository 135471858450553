export const SOFA_7_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['14'],
      queryType: 'String!'
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Begin Date',
      propertyName: 'beginDate',
    },
    {
      dataType: 'string',
      displayName: 'End Date',
      propertyName: 'endDate',
    },

    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
