import { ApMenuItem } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { IOption, ItemRenderer } from '../MultiSelectExtendable.types';
import { OptionItem } from './OptionItem';
import './OptionsList.css';
import MultiSelectFooter from './MultiSelectFooter';

export const OptionsList = (props: {
  options: IOption[];
  multiple?: boolean;
  selected?: IOption[];
  maxItemsExceeded?: boolean;
  level?: number;
  isSelectable?: (o: IOption) => boolean;
  itemRenderer?: ItemRenderer;
  onChange: (option: IOption) => void;
  isSelectAllChecked: boolean;
  clearSelection: () => void;
  applySelection: () => void;
  disabled?: boolean;
  hideFooter?: boolean;
}) => {
  const {
    options,
    multiple,
    selected,
    maxItemsExceeded,
    level = 0,
    isSelectable,
    itemRenderer,
    onChange,
    isSelectAllChecked,
    clearSelection,
    applySelection,
    disabled,
    hideFooter,
  } = props;

  return (
    <>
      {options.map((o, id) => (
        <ApMenuItem
          className={classNames('options-list__menu-item', `level-${level}`, {
            isSelectable: isSelectable ? isSelectable(o) : true,
          })}
          disableCloseOnClick={multiple}
          key={`${id}`}
          onClick={() => onChange(o)}
          disabled={o.disabled}
        >
          <div className="options-list__menu-item__container">
            <OptionItem
              option={o}
              selected={selected}
              multiple={multiple}
              itemRenderer={itemRenderer}
              maxItemsExceeded={maxItemsExceeded}
              isSelectAllChecked={isSelectAllChecked}
            />
          </div>
        </ApMenuItem>
      ))}

      {!hideFooter && (
        <MultiSelectFooter
          clearSelection={clearSelection}
          applySelection={applySelection}
          disabled={disabled}
        />
      )}
    </>
  );
};
