import { useState } from 'react';
import { ApButtonSecondary } from '@alixpartners/ui-components';

import './PreviousExhibitsDropdown.css';

export const PreviousExhibitsDropdown = (props: {
  toggleExhibitHistory: () => void;
}) => {
  const [isActive, setIsActive] = useState(false);
  const { toggleExhibitHistory } = props;

  return (
    <>
      <ApButtonSecondary
        autoFocus={false}
        disabled={false}
        theme="light"
        className="exhibit-exporter__previous-exhibits-btn"
        iconName={isActive ? 'close' : 'keyboard_arrow_down'}
        onClick={() => {
          setIsActive(!isActive);
          toggleExhibitHistory();
        }}
        medium
      >
        Previous Exhibits
      </ApButtonSecondary>
    </>
  );
};
