import { ApCheckbox } from '@alixpartners/ui-components';
import { ItemRendererParams } from 'components/UIComponents/MultiSelectExtendable';
import './CheckboxOptionItem.css';

export const CheckboxOptionItem = ({
  option,
  disabled,
  checked,
}: ItemRendererParams) => (
  <div className={'checkbox-option-item'}>
    <ApCheckbox
      className="checkbox-option-item__checkbox"
      autoFocus={false}
      disabled={disabled}
      id={`checkbox_${option.value}`}
      theme="light"
      type="checkbox"
      checked={checked}
      readOnly
    />
    <div className="checkbox-option-item__label">{option.label}</div>
  </div>
);
