import { ApButtonMain, ApButtonSecondary } from '@alixpartners/ui-components';

interface Props {
  mainLabel?: string;
  disabled?: boolean;
  formId?: string;
  onClick?: () => void;
  onCancel?: () => void;
  secondaryLabel?: string;
}

export const ModalUploadFooter = ({
  mainLabel = 'Save',
  disabled,
  formId,
  onClick,
  onCancel,
  secondaryLabel = 'Cancel',
}: Props) => {
  return (
    <div className="upload-modal__action-group">
      <ApButtonMain
        className="upload-modal__action"
        disabled={disabled}
        type="submit"
        form={formId}
        medium
        onClick={onClick}
      >
        {mainLabel}
      </ApButtonMain>
      <ApButtonSecondary
        className="ap-button-casual cancel-button"
        medium
        onClick={onCancel}
      >
        {secondaryLabel}
      </ApButtonSecondary>
    </div>
  );
};
