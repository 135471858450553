import { ClaimRow } from 'api/endpoints';
import { formatDate, ISO_DATE } from 'utils/formatDate';
import { formatMoney } from 'utils/formatNumber';
import {
  ClaimItemFormatted,
  ClaimTallyItem,
  ClaimTallyItemFormatted,
  ContractItemFormatted,
  ContractRowFormatted,
} from './Counterparty.types';
import { ContractReviewerData } from '../../reducer/contractReviewerReducer';

export function formatClaimTallyItem(item: ClaimTallyItem): ClaimTallyItemFormatted {
  return {
    ...item,
    admin_formatted: formatMoney(item.admin),
    priority_formatted: formatMoney(item.priority),
    secured_formatted: formatMoney(item.secured),
    count_formatted: formatMoney(item.count),
    total_formatted: formatMoney(item.total),
    unsecured_formatted: formatMoney(item.unsecured),
  };
}

export function formatFiledClaimItem(item: ClaimRow): ClaimItemFormatted {
  return {
    ...item,
    currentTotal_formatted: formatMoney(item.currentTotal),
    proposedTotal_formatted: formatMoney(item.proposedTotal),
    originalTotal_formatted: formatMoney(item.originalTotal),
    officialClaimDate_formatted: item.officialClaimDate
      ? formatDate(item.officialClaimDate, ISO_DATE)
      : '-',
    currentDisputed_formatted: String(item.currentDisputed),
    currentContingent_formatted: String(item.currentContingent),
    currentUnliquidated_formatted: String(item.currentUnliquidated),
  };
}

export function formatScheduledClaimItem(item: ClaimRow): ClaimItemFormatted {
  return {
    ...item,
    currentTotal_formatted: formatMoney(item.currentTotal),
    originalTotal_formatted: formatMoney(item.originalTotal),
    proposedTotal_formatted: formatMoney(item.proposedTotal),
    currentDisputed_formatted: String(item.currentDisputed),
    currentContingent_formatted: String(item.currentContingent),
    currentUnliquidated_formatted: String(item.currentUnliquidated),
  };
}

function createCommaSeparatedFullNames(
  contractsReviewers: ContractReviewerData,
): string {
  const fullNames: string[] = contractsReviewers?.userReviewers.map(
    (user) => `${user.firstName} ${user.lastName}`,
  );
  return fullNames.join(', ');
}

export function formatContractItem(
  item: ContractRowFormatted,
): ContractItemFormatted {
  return {
    ...item,
    contractsReviewers: createCommaSeparatedFullNames(item.contractsReviewers),
    rejectionDamageLow_formatted: formatMoney(item.rejectionDamageLow),
    rejectionDamagesHigh_formatted: formatMoney(item.rejectionDamagesHigh),
  };
}
