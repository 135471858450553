import { ActionName } from 'api/endpoints/action-names';
import Excel from 'exceljs';
import { IExhibitState } from 'reducer/exhibits/exhibitReducer';
import { ClaimRow } from '../../../api/endpoints';
import { US_DATE_FORMAT, formatDate } from '../../../utils/formatDate';
import { getClaimNumber } from '../utils/field';
import { IExcelCreator } from './excelExporter';
import {
  createTotalRowStyle,
  defCellStyle,
  defPositionNumberStyle,
} from './utils/excelConst';
import {
  createExcelWorkbook,
  ExcelDocumentHeader,
} from './utils/excelCreateWorkbook';
import { excelAddPageHeader, HeaderPositionCell } from './utils/excelHeader';
import {
  deleteObjectionCommentaryColumn,
  excelAddPageFooter,
  excelAddRow,
  excelAddTableHeaderRow,
  excelAddTotalRow,
} from './utils/excelUtils';
import {
  createClaimAmount,
  createHeaderFooter,
  getAddressLine,
  getTotalCurrency,
} from './utils/exporterUtils';
import { IExhibitWorkbookData, IRowValues, TRowStyle } from './utils/type';

const COLUMN_LIST: ExcelDocumentHeader[] = [
  { header: '', width: 4 },
  { header: 'Original creditor', width: 20 },
  { header: 'Claim transferred to', width: 12 },
  { header: 'Claim/schedule to be disallowed and expunged', width: 13.5 },
  { header: 'Debtor', width: 25 },
  { header: 'Date filed/scheduled', width: 11 },
  { header: 'Claim amount and priority (1)', width: 14.9 },
  { header: 'Basis for objection', width: 17.6 },
  { header: 'Comments', width: 15 },
];

const valuesStyle: TRowStyle = [
  { ...defPositionNumberStyle }, //'positionNumber'
  { ...defCellStyle }, //'originalCreditor'
  { ...defCellStyle }, //'claimTransferredTo'
  {
    ...defCellStyle,
    alignment: { horizontal: 'center', vertical: 'top' },
  }, //'referenceNumber'
  { ...defCellStyle }, //'proposedDebtor'
  {
    ...defCellStyle,
    numFmt: 'mm-dd-yy',
    alignment: { horizontal: 'center', vertical: 'top' },
  }, //'officialClaimDate'
  {
    ...defCellStyle,
    numFmt: '[$$-409]#,##0.00',
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  }, //'claimAmount'
  { ...defCellStyle }, //'objectionBasis'
  { ...defCellStyle }, //'objectionCommentary'
];

const valuesTotalStyle = createTotalRowStyle(valuesStyle.length);

const objectionColId = 9;

const PAGE_HEADER_POS: HeaderPositionCell = {
  left: 1,
  middle: 4,
  right: 7,
  length: 9,
};

export const templateExpungeNoSurviving: IExcelCreator = {
  fileName: 'Expunge_NoSurviving.xlsx',
  getWorkbook: async (exhibitState: IExhibitState, actionName: ActionName) => {
    const excelData = getDataToShow(exhibitState, actionName);
    return await createWorkbook(excelData, exhibitState.displayObjection);
  },
};

async function createWorkbook(
  data: IExhibitWorkbookData,
  displayObjection: boolean | undefined,
): Promise<Excel.Workbook> {
  const workbook = createExcelWorkbook('Sheet1', COLUMN_LIST);
  const firstSheet = workbook.getWorksheet(1);

  excelAddPageHeader(firstSheet, data.headerFooter.header, PAGE_HEADER_POS);
  excelAddTableHeaderRow(firstSheet, COLUMN_LIST);
  excelAddPageFooter(firstSheet, data.headerFooter);

  data.claimsList.forEach((row) => excelAddRow(firstSheet, row, valuesStyle));
  excelAddTotalRow(firstSheet, data.totalRow, valuesTotalStyle);

  deleteObjectionCommentaryColumn(firstSheet, displayObjection, objectionColId);
  return workbook;
}

function getDataToShow(
  exhibitState: IExhibitState,
  actionName: ActionName,
): IExhibitWorkbookData {
  const { structureFooterOptions } = exhibitState;

  // here is a bug. `r.value` is string type. But in real it contains an integer value
  const showClaimCount = !!structureFooterOptions.find((r) => r.value == '3'); // eslint-disable-line eqeqeq

  return {
    headerFooter: createHeaderFooter(exhibitState, actionName),
    claimsList: getPreparedClaimsData(
      exhibitState.claims,
      exhibitState.displayObjection,
      exhibitState.objectionBasis,
    ),
    totalRow: getTotalRow(exhibitState.claims, showClaimCount),
  };
}

function getTotalRow(claims: ClaimRow[], showClaimCount: boolean): IRowValues {
  const values = [
    '', //positionNumber
    'Claims To Be Expunged Totals', //originalCreditor
    '', //claimTransferredTo
    showClaimCount ? `Count: ${claims.length}` : '', //referenceNumber
    '', //proposedDebtor
    '', //officialClaimDate
    getTotalCurrency(claims, 'currentTotal'), //claimAmount
    '', //objectionBasis
  ];

  return { values };
}

const getPreparedClaimsData = (
  claims: ClaimRow[],
  displayObjection: boolean | undefined,
  objectionBasis: string,
) => {
  return claims.map(
    (c, index) =>
      ({
        values: [
          index + 1, //positionNumber
          getAddressLine(c), //originalCreditor
          '', //claimTransferredTo
          getClaimNumber(c), //referenceNumber
          c.proposedDebtor, //proposedDebtor
          formatDate(c.officialClaimDate, US_DATE_FORMAT), //officialClaimDate
          createClaimAmount(c), //claimAmount
          objectionBasis, //objectionBasis
          displayObjection ? c.objectionCommentary : '', //objectionCommentary
        ],
      } as IRowValues),
  );
};
