import { ActionName, ClaimRow, exhibits } from 'api/endpoints';
import Excel from 'exceljs';
import { IExhibitState } from 'reducer/exhibits/exhibitReducer';
import { US_DATE_FORMAT, formatDate } from '../../../utils/formatDate';
import { getClaimNumber } from '../utils/field';
import { IExcelCreator } from './excelExporter';
import {
  createTotalRowStyle,
  defCellStyle,
  defPositionNumberStyle,
} from './utils/excelConst';
import {
  createExcelWorkbook,
  ExcelDocumentHeader,
} from './utils/excelCreateWorkbook';
import { excelAddPageHeader, HeaderPositionCell } from './utils/excelHeader';
import {
  deleteObjectionCommentaryColumn,
  excelAddPageFooter,
  excelAddRow,
  excelAddTableHeaderRow,
  excelAddTotalRow,
} from './utils/excelUtils';
import {
  createClaimAmount,
  createHeaderFooter,
  createReducedClaimAmount,
  getAddressLine,
  getChildDataByRefNumber,
  getTotalCurrency,
} from './utils/exporterUtils';
import { IExhibitWorkbookData, IRowValues, TRowStyle } from './utils/type';

const COLUMN_LIST: ExcelDocumentHeader[] = [
  { header: '', width: 4 },
  { header: 'Original Creditor', width: 18.6 },
  { header: 'Claim to be modified', width: 9.3 },
  { header: 'Original Debtor', width: 15 },
  { header: 'Proposed Debtor', width: 15 },
  { header: 'Date filed/scheduled', width: 10.0 },
  { header: 'Unliquidated', width: 6.3 },
  { header: 'Claim amount and priority (1)', width: 13.6 },
  { header: 'Modified Claim amount and/or priority (1)', width: 13.6 },
  { header: 'Basis for objection', width: 14.2 },
  { header: 'Comments', width: 14.2 },
];

const valuesStyle: TRowStyle = [
  { ...defPositionNumberStyle }, //'positionNumber',
  { ...defCellStyle }, //'originalCreditor',
  {
    ...defCellStyle,
    alignment: { horizontal: 'center', vertical: 'top' },
  }, //'referenceNumber',
  { ...defCellStyle }, //'originalDebtor',
  { ...defCellStyle }, //'proposedDebtor',
  { ...defCellStyle }, //'officialClaimDate',
  { ...defCellStyle }, //'unliquidated',
  {
    ...defCellStyle,
    numFmt: '[$$-409]#,##0.00',
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  }, //'claimAmount',
  {
    numFmt: '[$$-409]#,##0.00',
    ...defCellStyle,
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  }, //'reducedClaimAmount',
  { ...defCellStyle }, //'objectionBasis',
  { ...defCellStyle }, //'objectionCommentary',
];

const valuesTotalStyle = createTotalRowStyle(valuesStyle.length);

const objectionColId = 11;

const PAGE_HEADER_POS: HeaderPositionCell = {
  left: 1,
  middle: 4,
  right: 8,
  length: 11,
};

export const templateAlterClaimAmountProposeDebtor: IExcelCreator = {
  fileName: 'Alter_ClaimAmount_Propose.xlsx',
  getWorkbook: async (exhibitState: IExhibitState, actionName: ActionName) => {
    const childClaims = (await exhibits.getChildClaims(actionName.id)) ?? [];
    const excelData = getDataToShow(exhibitState, actionName, childClaims);
    return await createWorkbook(excelData, exhibitState.displayObjection);
  },
};

async function createWorkbook(
  data: IExhibitWorkbookData,
  displayObjection: boolean | undefined,
): Promise<Excel.Workbook> {
  const workbook = createExcelWorkbook('Sheet1', COLUMN_LIST);
  const firstSheet = workbook.getWorksheet(1);

  excelAddPageHeader(firstSheet, data.headerFooter.header, PAGE_HEADER_POS);
  excelAddTableHeaderRow(firstSheet, COLUMN_LIST);
  excelAddPageFooter(firstSheet, data.headerFooter);

  data.claimsList.forEach((row) => excelAddRow(firstSheet, row, valuesStyle));
  excelAddTotalRow(firstSheet, data.totalRow, valuesTotalStyle);

  deleteObjectionCommentaryColumn(firstSheet, displayObjection, objectionColId);
  return workbook;
}

function getDataToShow(
  exhibitState: IExhibitState,
  actionName: ActionName,
  childClaims: ClaimRow[],
): IExhibitWorkbookData {
  const { structureFooterOptions, displayObjection } = exhibitState;

  // here is a bug. `r.value` is string type. But in real it contains an integer value
  const showClaimCount = !!structureFooterOptions.find((r) => r.value == '3'); // eslint-disable-line eqeqeq

  // we need to remove parent claims when we calculate totals
  // we get the totals from its child claims
  const filteredClaims = exhibitState.claims.filter(
    (claim) =>
      !childClaims.find((c) => c.parentClaimNumber === claim.referenceNumber),
  );

  return {
    headerFooter: createHeaderFooter(exhibitState, actionName),
    claimsList: getPreparedClaimsData(
      exhibitState.claims,
      displayObjection,
      exhibitState.objectionBasis,
      childClaims,
    ),
    totalRow: getTotalRow([...filteredClaims, ...childClaims], showClaimCount),
  };
}

function getTotalRow(claims: ClaimRow[], showClaimCount: boolean): IRowValues {
  const values = [
    '', //positionNumber
    'Claims to be modified totals', //originalCreditor
    showClaimCount ? `Count: ${claims.length}` : '', //referenceNumber
    '', //originalDebtor
    '', //proposedDebtor
    '', //officialClaimDate
    '', //unliquidated
    getTotalCurrency(claims, 'currentTotal'), //claimAmount
    getTotalCurrency(claims, 'proposedTotal'), //reducedClaimAmount
    '', //objectionBasis
  ];

  return { values };
}

const getPreparedClaimsData = (
  claims: ClaimRow[],
  displayObjection: boolean | undefined,
  objectionBasis: string,
  childClaims: ClaimRow[],
) => {
  return claims.map((c, index) => {
    const child = getChildDataByRefNumber(childClaims, c.referenceNumber);

    return {
      values: [
        index + 1, //positionNumber
        getAddressLine(c), //originalCreditor
        getClaimNumber(c), //referenceNumber
        child.originalDebtors ?? c.originalDebtor, //originalDebtor
        child.proposedDebtors ?? c.proposedDebtor, //proposedDebtor
        formatDate(c.officialClaimDate, US_DATE_FORMAT), //officialClaimDate
        c.originalUnliquidated ? 'YES' : 'NO', //unliquidated
        child.claimAmounts ?? createClaimAmount(c), //claimAmount
        child.reducedClaimAmounts ?? createReducedClaimAmount(c), //reducedClaimAmount
        objectionBasis, //objectionBasis
        displayObjection ? c.objectionCommentary : '', //objectionCommentary
      ],
    } as IRowValues;
  });
};
