import classnames from 'classnames';
import './PageHeaderTitle.css';

interface Props {
  title?: string;
  className?: string;
}

export const PageHeaderTitle = ({ title, className }: Props) => {

  return (
    <div className={classnames('page-header-title', className)}>
      <h3>{title}</h3>
    </div>
  );
};
