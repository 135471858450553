import './SingleClaimRelatedClaims.css';
import { ApButtonLink } from '@alixpartners/ui-components';
import { useNavigate } from 'react-router';
import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import { formatDate } from 'utils/formatDate';
import { buildSingleClaimPath } from 'utils/hooks/useClaimToolQueryParams';
import { ClaimRow } from 'api/endpoints';
import { formatMoney } from 'utils/formatNumber';
import { RelatedClaimsData } from '../SingleClaimOverview/util/getRelatedClaims';

interface SingleClaimRelatedClaimsProps {
  relatedClaimsData: RelatedClaimsData;
}

const relatedClaimGridCols: { label: string; propertyName: keyof ClaimRow }[] = [
  { label: 'Claim Number', propertyName: 'referenceNumber' },
  { label: 'Parent Claim Number', propertyName: 'parentClaimNumber' },
  { label: 'Debtor', propertyName: 'currentDebtor' },
  { label: 'Counterparty', propertyName: 'counterpartyName' },
  { label: 'Amount', propertyName: 'currentTotal' },
  { label: 'Claim Description', propertyName: 'scheduledDescription' },
  { label: 'Claim date', propertyName: 'officialClaimDate' },
];

export const SingleClaimRelatedClaims = ({
  relatedClaimsData,
}: SingleClaimRelatedClaimsProps) => {
  const { childClaims = [], parentClaim, relatedClaims = [] } = relatedClaimsData;

  return (
    <div>
      {parentClaim && (
        <section className="related-claims scp-section">
          <div>
            <h1>Parent Claim</h1>
          </div>
          <ClaimTableHeaders />
          <ClaimTableRows claims={[parentClaim]} />
        </section>
      )}
      <section className="related-claims scp-section">
        <div>
          <h1>Related Claims</h1>
        </div>
        <ClaimTableHeaders />
        <ClaimTableRows claims={relatedClaims} />
        <ClaimTableRows claims={childClaims} />
      </section>
    </div>
  );
};

const ClaimTableHeaders = () => (
  <div className="related-claims-headers">
    {relatedClaimGridCols.map((col, i) => {
      return <h5 key={`${col.propertyName}_${i}`}>{col.label}</h5>;
    })}
  </div>
);

interface ClaimTableRowsProps {
  claims: ClaimRow[];
}

const ClaimTableRows = (props: ClaimTableRowsProps) => {
  const { claims } = props;
  const navigate = useNavigate();

  const handleRefNoClick = (id?: number) => {
    if (!id) return;
    navigate(buildSingleClaimPath(id, SectionType.Claims, SubsectionType.AllClaims));
  };
  return (
    <div className="related-claims-content">
      {claims.map((claim, i) => {
        return (
          <div className="related-claims-content-item" key={`${claim.id}_${i}`}>
            {relatedClaimGridCols.map((header, j) => {
              if (header.label === 'Claim Number') {
                return (
                  <ApButtonLink
                    key={`${claim.id}_${header.propertyName}_${i}_${j}`}
                    onClick={() => handleRefNoClick(claim.id)}
                  >
                    {claim[header.propertyName]}
                  </ApButtonLink>
                );
              }
              if (header.propertyName === 'officialClaimDate') {
                return (
                  <p key={`${claim.id}_${header.propertyName}_${i}_${j}`}>
                    {formatDate(claim.officialClaimDate, 'MM-DD-YYYY')}
                  </p>
                );
              }
              if (header.propertyName === 'currentTotal') {
                return (
                  <p key={`${claim.id}_${header.propertyName}_${i}_${j}`}>
                    {formatMoney(claim.currentTotal)}
                  </p>
                );
              }
              return (
                <p key={`${claim.id}_${header.propertyName}_${i}_${j}`}>
                  {String(claim[header.propertyName] ?? '-')}
                </p>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};
