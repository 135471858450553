import {
  ApButtonGroup,
  ApButtonMain,
  ApModal,
  ApRadio,
} from '@alixpartners/ui-components';
import { useState } from 'react';
import { assertUnreachable } from 'utils/assertUnreachable';
import './DataUploadReplaceAppendDataModal.css';
import { ApButtonCasual } from 'components/UIComponents/Buttons';
import { FileComparisonResponse } from 'api/endpoints';

type Props = {
  filesComparisonResponse: FileComparisonResponse;
  onAppend: () => void;
  onReplace: () => void;
  onDismiss: () => void;
};

export const DataUploadReplaceAppendDataModal = ({
  filesComparisonResponse,
  onAppend,
  onReplace,
  onDismiss,
}: Props) => {
  const [value, setValue] = useState<'Replace' | 'Append' | null>(null);

  const onConfirm = () => {
    switch (value) {
      case 'Append':
        onAppend();
        break;
      case 'Replace':
        onReplace();
        break;
      case null:
        break;
      default:
        assertUnreachable(value);
    }
  };

  return (
    <ApModal
      className="data-upload__replace-append-data-modal"
      hasClosed={onDismiss}
      header={
        <>
          Do you wish to “Replace” or “Append” the
          <br />
          existing data?
        </>
      }
      isDismissible
      isOpen
      footer={() => (
        <ApButtonGroup justifyContent="space-between">
          <ApButtonCasual medium onClick={onDismiss}>
            Cancel
          </ApButtonCasual>
          <ApButtonMain medium disabled={value === null} onClick={onConfirm}>
            Confirm
          </ApButtonMain>
        </ApButtonGroup>
      )}
    >
      <div>
        Publishing this files will either replace the exiting data or append the
        exiting data within.
      </div>
      <div className="data-upload__replace-append-data-modal__radio-group">
        <ApRadio
          id="dataUploadReplaceRadio"
          name="radio"
          theme="light"
          type="radio"
          checked={value === 'Replace'}
          onChange={() => setValue('Replace')}
        >
          {`Replace (will affect ${filesComparisonResponse.delete} records)`}
        </ApRadio>
        <ApRadio
          id="dataUploadAppendRadio"
          name="radio"
          theme="light"
          type="radio"
          checked={value === 'Append'}
          onChange={() => setValue('Append')}
        >
          {`Append (will add ${filesComparisonResponse.add} records)`}
        </ApRadio>
      </div>
    </ApModal>
  );
};
