export const SOFA_3_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['7', '8'],
      queryType: 'String!'
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },

    {
      dataType: 'string',
      displayName: 'Case Title',
      propertyName: 'caseTitle',
    },
    {
      dataType: 'string',
      displayName: 'Case Number',
      propertyName: 'caseNumber',
    },
    {
      dataType: 'string',
      displayName: 'Nature of Case',
      propertyName: 'natureOfProceeding',
    },
    {
      dataType: 'string',
      displayName: 'Court / Agency Name',
      propertyName: 'courtAgencyName',
    },

    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },

    {
      dataType: 'string',
      displayName: 'Status of Case (Pending, On appeal, Concluded)',
      propertyName: 'status',
    },
    {
      dataType: 'string',
      displayName: 'Description of the Property',
      propertyName: 'description',
    },
    {
      dataType: 'string',
      displayName: 'Date of Assignment',
      propertyName: 'dateOfAssignment',
    },
    {
      dataType: 'string',
      displayName: 'Value',
      propertyName: 'value',
    },
    {
      dataType: 'string',
      displayName: 'Custodian Name',
      propertyName: 'custodianName',
    },
    {
      dataType: 'string',
      displayName: 'Custodian Address 1',
      propertyName: 'custodianAddress1',
    },
    {
      dataType: 'string',
      displayName: 'Custodian Address 2',
      propertyName: 'custodianAddress2',
    },
    {
      dataType: 'string',
      displayName: 'Custodian Address 3',
      propertyName: 'custodianAddress3',
    },
    {
      dataType: 'string',
      displayName: 'Custodian City',
      propertyName: 'custodianCity',
    },
    {
      dataType: 'string',
      displayName: 'Custodian State',
      propertyName: 'custodianState',
    },
    {
      dataType: 'string',
      displayName: 'Custodian Zip',
      propertyName: 'custodianZip',
    },
    {
      dataType: 'string',
      displayName: 'Custodian Country',
      propertyName: 'custodianCountry',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
