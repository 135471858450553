import classNames from 'classnames';
import { InputComponent } from 'components/UIComponents/EntityTable';
import './ActionNames.css';
import { Datepicker } from 'components/UIComponents/Datepicker';
import { formatDate, ISO_DATE, READABLE_DAY_MONTH_YEAR } from 'utils/formatDate';
import { useAppSelector } from 'hooks/reducerHooks';
import { useEffect } from 'react';
import { ContractActionName } from 'api/endpoints/action-names';

export const ContractActionNameTypeInput: InputComponent<ContractActionName> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className, getValue, setValue } = fromTable;
  const { contractExhibitTypes: exhibitTypes } = useAppSelector(
    (s) => s.selectable.data,
  );

  useEffect(() => {
    if (item && item[columnKey]) {
      setValue(columnKey, item[columnKey]);
    }
  }, [columnKey, item, setValue]);

  const selectedTypeId = getValue(columnKey) ?? '';
  const title = exhibitTypes.find((o) => o.value === selectedTypeId)?.label;

  if (disabled) return <p className="disabled-value">{title}</p>;

  return (
    <select
      name={columnKey}
      className={classNames(className, 'utils-clean-input exhibitSelect')}
      required
      disabled={disabled}
      defaultValue={item?.contractExhibitTypeId}
      value={selectedTypeId}
      onChange={(e) => setValue(columnKey, +e.target.value)}
      title={title ?? ''}
    >
      {/* placeholder */}
      <option disabled value="">
        Select exhibit type
      </option>
      {exhibitTypes.map((o) => (
        <option key={o.value} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
};

export const ContractActionNameHearingDateInput: InputComponent<ContractActionName> =
  ({ columnKey, item, fromTable }) => {
    const { disabled, className, getValue } = fromTable;
    const selectedTypeId = getValue('typeId');

    const required = useAppSelector(
      (s) =>
        s.actionsSelectOptions.data.hearingDateRequiredByActionType[
          selectedTypeId
        ] ?? false,
    );

    const cn = classNames(className, 'utils-clean-input');
    if (disabled)
      return (
        <p className="disabled-value">
          {formatDate(item?.hearingDate, READABLE_DAY_MONTH_YEAR)}
        </p>
      );

    return (
      <Datepicker
        placeholder={item ? '' : 'Select date'}
        required={required}
        disabled={disabled}
        formatLabel={formatDate}
        defaultValue={item?.hearingDate}
        className={cn}
        name={columnKey}
        dateFormat={ISO_DATE}
      />
    );
  };

export const ContractActionNameInput: InputComponent<ContractActionName> = ({
  columnKey,
  item,
  fromTable,
}) => {
  const { disabled, className } = fromTable;
  if (columnKey === 'contractActionName') {
    return (
      <input
        type="text"
        name={columnKey}
        maxLength={50}
        required
        className={classNames(className, 'utils-clean-input')}
        disabled={disabled}
        placeholder="Enter name"
        defaultValue={item?.contractActionName}
      />
    );
  }
  if (columnKey === 'contractExhibitTypeId') {
    return (
      <ContractActionNameTypeInput
        columnKey={columnKey}
        fromTable={fromTable}
        item={item}
      />
    );
  }

  if (columnKey === 'hearingDate') {
    return (
      <ContractActionNameHearingDateInput
        columnKey={columnKey}
        fromTable={fromTable}
        item={item}
      />
    );
  }

  return null;
};
