import { ExhibitExporterTopMenu } from 'components/ExhibitsExport/ExhibitExporterTopMenu';
import { ButtonMainLoader } from 'components/UIComponents/ButtonMainLoader';
import { useMemo, useState } from 'react';
import { error } from 'utils/alert';
import { ExhibitExportPanel } from '../../components/ExhibitsExport/ExhibitExportPanel/ExhibitExportPanel';
import { apiExhibitContract } from './apiExhibitContract';
import { exportExhibitExcelFile } from './exhibitContractExporter';
import { ExhibitContractHistoryTable } from './ExhibitContractHistoryTable';
import { ExhibitDataSection } from './ExhibitDataSection';
import './ExhibitExporterContractPage.css';
import { useExhibitExporterContract } from './useExhibitExporterContract';
import { useExhibitsContractHistory } from './useExhibitsContractHistory';
import { Layout } from 'components/Layout';
import { ApUserRole } from 'components/RolePermission/ApUserRole';

export const ExhibitExporterContractPage = () => {
  const [reportLoading, setReportLoading] = useState(false);

  const data = useExhibitExporterContract();
  const history = useExhibitsContractHistory();

  const allowGenerateExhibit = useMemo(
    () =>
      data.exhibit.actionNameId &&
      data.exhibit.typeId &&
      data.exhibit.contracts.length > 0,
    [data],
  );

  const handleCreateReportClick = async () => {
    setReportLoading(true);

    const { exhibit } = data;
    const exhibitExported = data.getExhibitExported();
    await Promise.all([
      apiExhibitContract.postExhibitExports(exhibit),
      exportExhibitExcelFile(exhibitExported, {
        includeRejDate: data.includeRejDate,
      }),
    ]);

    history.refreshList();
    setReportLoading(false);
  };

  const handleHistoryCopy = async (id: number) => {
    const exhibit = await history.getContractExhibitById(id);
    data.applyNewExhibit(exhibit);
  };

  const handleHistoryDownload = async (id: number) => {
    const exhibit = await history.getExhibitExportedById(id);
    if (!exhibit) {
      error('Something went wrong. Item not found.');
      return;
    }

    exportExhibitExcelFile(exhibit);
  };

  return (
    <ApUserRole>
      <Layout>
        <ExhibitExporterTopMenu />
        <ExhibitExportPanel
          title="Contract Exhibit Exporter"
          showHistory={history.show}
          historyElement={
            <ExhibitContractHistoryTable
              data={history.rows}
              loading={history.loading}
              onCopy={handleHistoryCopy}
              onDownload={handleHistoryDownload}
              onDelete={history.deleteItem}
            />
          }
          onShowHistoryClick={history.showSwitch}
        >
          <ExhibitDataSection
            typeOptions={data.exhibitTypes}
            actionNames={data.actionNames}
            exhibit={data.exhibit}
            loading={data.contractsLoading}
            onChange={data.exhibitChange}
            setIncludeRejDate={data.setIncludeRejDate}
            includeRejDate={data.includeRejDate}
          />

          <ButtonMainLoader
            loading={reportLoading}
            disabled={!allowGenerateExhibit}
            iconName="download"
            onClick={handleCreateReportClick}
          >
            {reportLoading ? 'Preparing Exhibit File' : 'Generate Exhibit (.xlsx)'}
          </ButtonMainLoader>
        </ExhibitExportPanel>
      </Layout>
    </ApUserRole>
  );
};
