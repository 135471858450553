import { ApButtonSecondary } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { ApProgressBar } from 'components/ApProgressBar';
import { ErrorMessage } from 'components/UIComponents/ErrorMessage/ErrorMessage';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { WarningMessage } from 'components/UIComponents/WarningMessage/WarningMessage';
import { ClaimsContractsAdditionalExtensions } from 'pages/ClaimsContractsToolPage/types';
import { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { IUploadItem } from 'types/dataUploaderTypes';
import './FileUploadPanel.css';

const warningMessage =
  'When uploading files, please make sure the filenames contain only one pair of brackets';
const errorMessage =
  'Filename(s) invalid. Please check that you only have one pair of brackets in the filename and if you do, please contact the Ch11 Support team.';

export type Extension =
  | ClaimsContractsAdditionalExtensions
  //documents
  | '.xlsx'
  | '.xls'
  | '.xlsm'
  | '.pdf'
  | '.docx'
  | '.doc'
  //image
  | '.png'
  | '.gif'
  | '.jpg'
  | '.jpeg'
  | '.jfif'
  | '.jif'
  | '.heic' // Image used on the Mac/IPhone
  | '.webp'
  // emails
  | '.eml'
  | '.msg'
  | '.pst'
  | '.edb'
  | '.ost'
  | '.mbox'
  // zip for contract images
  | '.zip';

interface IProps {
  onUpload: (files: File[]) => void;

  disabled?: boolean;
  maxFiles?: number;
  accept?: Extension[] | Extension;
  isScheduleSection?: boolean;
  schedule?: string;
  fileToBeUploaded?: IUploadItem;
  hideWarningMessage?: boolean;
  caption?: JSX.Element;
  hideUploadIcon?: boolean;
  className?: string;
}

type FileWithSchedulePart = File & { schedulePart?: string };

export const FileUploadPanel = ({
  onUpload,
  disabled,
  maxFiles,
  accept = ['.xlsx', '.xls'],
  isScheduleSection,
  schedule,
  fileToBeUploaded,
  hideWarningMessage,
  caption,
  hideUploadIcon,
  className,
}: IProps) => {
  const [isUploading, setIsUploading] = useState(false);
  const [fileNameInvalid, setFileNameInvalid] = useState(false);

  const isFilenameInvalid = (fileName: string) =>
    // checking for multiple pair of brackets
    // only one pair is accepted
    fileName.indexOf('(') < fileName.lastIndexOf('(') ||
    fileName.indexOf(')') < fileName.lastIndexOf(')');

  const handleDrop = (files: FileWithSchedulePart[]) => {
    if (files.some((file) => isFilenameInvalid(file.name))) {
      setFileNameInvalid(true);
      return;
    }
    if (isScheduleSection) {
      files.forEach((file) => (file.schedulePart = schedule));
      setIsUploading(true);
    }
    setFileNameInvalid(false);
    onUpload(files);
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    maxFiles,
    accept,
    multiple: !isScheduleSection,
    disabled,
    onDrop: handleDrop,
  });

  useEffect(() => {
    if (!fileToBeUploaded) setIsUploading(false);
  }, [fileToBeUploaded]);

  return (
    <div
      className={classNames('fileupload-panel', className, {
        'fileupload-panel__dragging': isDragActive,
      })}
    >
      {fileNameInvalid && isScheduleSection && (
        <>
          <ErrorMessage errorMessage={errorMessage} />
        </>
      )}
      <div className="fileupload-panel__dragdrop" {...getRootProps()}>
        <input {...getInputProps()} />
        {!isScheduleSection && !hideUploadIcon && (
          <div>
            <SvgIcon iconName="svgImageFile" width={96} height={96} />
          </div>
        )}
        <div className="fileupload-panel__caption">
          {!!caption && caption}
          {!isUploading && !caption && (
            <>
              {!fileNameInvalid && !isScheduleSection && !hideWarningMessage && (
                <WarningMessage warningMessage={warningMessage} />
              )}
              <div> Drag your file here </div>
              <div>
                or
                <span className={`${isScheduleSection ? '' : 'link-text'}`}>
                  {' '}
                  browse{' '}
                </span>
                files to upload
              </div>
              {fileNameInvalid && !isScheduleSection && (
                <ErrorMessage errorMessage={errorMessage} />
              )}
            </>
          )}
          {isScheduleSection && <ApButtonSecondary>Browse</ApButtonSecondary>}
          {fileToBeUploaded && isUploading && (
            <div>
              {fileToBeUploaded.displayName}
              <ApProgressBar progress={fileToBeUploaded.uploadProgress ?? 0} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
