import './MatchingUpload.css';
import { WarningMessage } from 'components/UIComponents/WarningMessage/WarningMessage';
import { MatchingSection } from '../../components/MatchingSection';
import { FileUploadPanel } from 'pages/DataUploaderPage/components/FileUploadPanel';
import { ApButtonMain, ApLoaderDefault } from '@alixpartners/ui-components';
import { IUploadItem } from 'types/dataUploaderTypes';
import { MatchingPreviouslyUpload } from './MatchingPreviouslyUploaded';
import { FileUploadSupportedFormats } from 'components/FileUploadSupportedFormats/FileUploadSupportedFormats';
import { useMatchingContext } from '../../MatchingContextProvider';

export const MatchingUpload = () => {
  const { onUpload, prevUploaded, fetchingFiles } = useMatchingContext();

  const handleOnUpload = (files: File[]) => {
    const items = files.map(
      (file) => ({ displayName: file.name, file } as IUploadItem),
    );
    onUpload(items);
  };

  return (
    <MatchingSection>
      <div className="matching-upload__header">
        <h4>Upload data for matching</h4>
        <WarningMessage warningMessage="When uploading files, please make sure the filenames contain only one pair of brackets" />
      </div>
      <FileUploadPanel
        onUpload={handleOnUpload}
        hideUploadIcon
        accept={['.xls', '.xlsx']}
        caption={
          <p className="flex-align-center pos-relative ">
            <span>
              Drag your <b>Matching file</b> here or browse files to upload <b>match suggestion file</b>
              <FileUploadSupportedFormats supportedFormats={'.xlsx, .xls'} />
            </span>
            <ApButtonMain medium>Browse</ApButtonMain>
          </p>
        }
        maxFiles={1}
      />
      <div className="matching-status__header">
        Previously uploaded matching files
      </div>
      <div className="matching__prev-uploaded">
        {fetchingFiles ? (
          <ApLoaderDefault loaderSize="lg" />
        ) : (
          prevUploaded.map((p, id) => (
            <MatchingPreviouslyUpload key={`${p.id}_${id}`} file={p} />
          ))
        )}
      </div>
    </MatchingSection>
  );
};
