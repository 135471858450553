import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SectionItem } from './ClaimsMenuSectionTabs';
import { RoleType } from 'utils/AppInitializer/application-user';

const CLIENT_SECTIONS: SectionItem[] = [
  {
    type: SectionType.Dashboard,
    label: 'DASHBOARD',
  },
  {
    type: SectionType.Claims,
    label: 'CLAIMS',
    resetFetchStatus: true,
  },
  {
    type: SectionType.Contracts,
    label: 'CONTRACTS',
    resetFetchStatus: true,
  },
];

const AP_SECTIONS: SectionItem[] = [
  ...CLIENT_SECTIONS,
  {
    type: SectionType.MatchingHomepage,
    label: 'MATCHING',
    subsections: [SectionType.MatchingExport, SectionType.Matching],
  },
  {
    type: SectionType.ExhibitsExport,
    label: 'EXHIBITS',
  },
  {
    type: SectionType.Documents,
    label: 'DOCUMENTS',
  },
  {
    type: SectionType.Reports,
    label: 'REPORTS',
  },
];

const ROLE_TYPE_MAP: { [key in RoleType]: SectionItem[] } = {
  AP: AP_SECTIONS,
  Client: CLIENT_SECTIONS,
};

export const getSections = (roleType: RoleType) => ROLE_TYPE_MAP[roleType];
