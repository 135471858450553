import {
  destringifyFieldValues,
  LogEntry,
  ClaimContractLogPaginationSort,
} from './claimLogs';
import { CustomErrorMap, downloadFile, get } from 'api/apiRequest';

export type ContractLogEntryResult = {
  entries: LogEntry[];
  currentPage: number;
  currentSort: ClaimContractLogPaginationSort;
  totalPages: number;
  totalEntries: number;
  entriesPerPage: number;
  _requestTime?: string;
  contractId: number;
};

export const contractLogs = {
  get: async (contractId: number) => {
    const _requestTime = new Date().toISOString();

    const _result: any = await get(`v1/ContractChangeLog?contractId=${contractId}`);
    const result: any = destringifyFieldValues(_result);
    if (!result) return null;

    result.contractId = contractId;
    result._requestTime = _requestTime;

    // BE does not yet support the other fields apart from entries
    // filling up here
    result.currentPage = 1;
    result.currentSort = 'created-date-desc';
    result.entriesPerPage = result.entries.length + 1;
    result.totalPages = 1;
    result.totalEntries = result.entries.length;

    return result;
  },
  excelExportAll: async (fileName: string) =>
    await downloadFile(
      'v1/ContractChangeLog/excelExport/all',
      fileName,
      AUDIT_DOWNLOAD_ERRORS_MAP,
    ),
};

export const AUDIT_DOWNLOAD_ERRORS_MAP: CustomErrorMap = {
  404: {
    title: 'Download failed',
    message: 'No data found',
  },
};
