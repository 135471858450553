import { UsersLogsData } from 'reducer/usersLogsReducer';
import { formatDate, READABLE_MONTH_DAY_YEAR } from 'utils/formatDate';

export const UserItem = ({ name, date }: Pick<UsersLogsData, 'date' | 'name'>) => {
  return (
    <div className="users-content__details-item">
      <p>{name}</p>
      <p>{formatDate(date, READABLE_MONTH_DAY_YEAR)}</p>
    </div>
  );
};
