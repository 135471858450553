export const SCHEDULE_AB_5_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: ['RawMaterials', 'WorkInProgress', 'FinishedGoods', 'Other'],
      dataType: 'enum',
      displayName: 'Type of Asset',
      propertyName: 'typeOfAsset',
      queryType: 'ScheduleTypeOfAsset'
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Description',
      propertyName: 'description',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Date of Last Physical Inventory',
      propertyName: 'lastPhysicalInventoryDate',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Net Book Value',
      propertyName: 'netBookValue',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Valuation Method',
      propertyName: 'valuationMethod',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Perishable',
      propertyName: 'perishable',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Appraised',
      propertyName: 'appraised',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Has Property Been Purchased with last 20 days',
      propertyName: 'last20Days',
    },

    {
      allowedValues: null,
      dataType: 'decimal',
      displayName: 'Current Value',
      propertyName: 'currentValue',
    },
    // {
    //   allowedValues: null,
    //   dataType: 'string',
    //   displayName: 'Book Value',
    //   propertyName: 'bookValue',
    // },
    // {
    //   allowedValues: null,
    //   dataType: 'string',
    //   displayName: 'Book Value Method',
    //   propertyName: 'bookValueMethod',
    // },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
