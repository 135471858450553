import {
  ApButtonDanger,
  ApButtonGroup,
  ApButtonMain,
  ApModal,
  ApToggleSwitch,
  ApButtonIcon,
} from '@alixpartners/ui-components';
import {
  Row,
  Cell,
} from '../sections/SingleContractCounterpartiesAndDebtors/CounterpartiesAndDebtorsTable';
import { useEffect, useMemo, useState } from 'react';
import { Debtor } from '../sections/SingleContractCounterpartiesAndDebtors/SingleContractCounterpartiesAndDebtorsTable';
import { CounterpartiesOrDebtors } from 'api/endpoints';
import { upperFirst } from 'lodash';
import './CounterpartiesDebtorsTableRow.css';

type PrimaryOrDeleteFunc = (id: number) => Promise<void>;

export const CounterpartiesDebtorsRow = ({
  debtors,
  contractId,
  item,
  handleDelete,
  handlePrimaryToggle,
}: {
  debtors?: Debtor;
  item: CounterpartiesOrDebtors;
  contractId: string;
  handleDelete: PrimaryOrDeleteFunc;
  handlePrimaryToggle: PrimaryOrDeleteFunc;
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  const onDelete = () => {
    handleDelete(item.id);
    setOpenDeleteModal(false);
  };
  const renderFooter = () => {
    return (
      <ApButtonGroup className="counterparties-and-debtors__modal-footer">
        <ApButtonMain onClick={() => setOpenDeleteModal(false)}>Cancel</ApButtonMain>
        <ApButtonDanger className="ap-button-casual" onClick={onDelete}>
          Yes, remove
        </ApButtonDanger>
      </ApButtonGroup>
    );
  };

  const isCounterparty = useMemo(() => debtors === 'counterparty', [debtors]);

  // For removing default tooltip from Label title in ApToggleSwitch component
  useEffect(() => {
    const labelElement = document.querySelector('label[title]');
    if (labelElement) {
      labelElement.removeAttribute('title');
    }
  }, []);

  return (
    <Row>
      <Cell style={{ width: '60%' }}>
        {isCounterparty ? item?.counterpartyName : item?.debtorName}
      </Cell>
      {isCounterparty && <Cell>{item?.matchCode || ''}</Cell>}
      <td>
        <div className="table-actions flex-center">
          <ApToggleSwitch
            className="counterparties-and-debtors__toggle-switch"
            id={`${item.id}_${item.isPrimary}`}
            checked={item.isPrimary}
            disabled={item.isPrimary}
            onChange={() => handlePrimaryToggle(item.id)}
          >
            Primary
          </ApToggleSwitch>
          <div className="counterparties-and-debtors__divide">&nbsp;</div>
          <ApButtonIcon
            iconName="delete"
            onClick={() => setOpenDeleteModal(true)}
            disabled={item.isPrimary}
            medium
          />
          <ApModal
            isOpen={openDeleteModal}
            footer={renderFooter}
            hasClosed={() => setOpenDeleteModal(false)}
          >
            <h3 className="counterparties-and-debtors__modal-title">
              Are you sure you want to remove the following {debtors} from Contract
              <b> #{contractId}</b> ?
            </h3>
            <div className="counterparties-and-debtors__modal-content">
              <div className="flex-align-center">
                <h4>{upperFirst(debtors)} name</h4>
                {isCounterparty && <h4>Match code</h4>}
              </div>
              <div className="flex-align-center">
                <p>{isCounterparty ? item?.counterpartyName : item?.debtorName}</p>
                {isCounterparty && <p>{item.matchCode}</p>}
              </div>
            </div>
          </ApModal>
        </div>
      </td>
    </Row>
  );
};
