import { ContractRow } from 'api/endpoints';
import { GraphQlApolloClient } from 'api/graphql';
import { graphqlGetContracts } from 'utils/agGridHotchocolate/graphqlGetContracts';
import { AgGridFilterModel } from 'utils/agGridHotchocolate/types';

export const RELATED_CONTRACT_COLS: {
  label: string;
  propertyName: keyof ContractRow;
}[] = [
  { label: 'Contract number', propertyName: 'referenceNumber' },
  { label: 'Parent contract number', propertyName: 'parentContractNumber' },
  { label: 'Debtor', propertyName: 'primaryDebtor' },
  { label: 'Counterparty', propertyName: 'counterpartyName' },
  { label: 'Contract title', propertyName: 'description' },
  { label: 'Contract date', propertyName: 'contractDate' },
];


export interface RelatedContractsData {
  relatedContracts: ContractRow[];
  relatedContractsCount: number;
}

export const getRelatedContracts = async (
  parentContractNumber: string | undefined,
  client: GraphQlApolloClient
) => {

  if (!parentContractNumber) return {
    relatedContracts: [],
    relatedContractsCount: 0
  }

  const filterModel: AgGridFilterModel = {
    parentContractNumber: {
      filterType: 'text',
      type: 'equals',
      filter: parentContractNumber,
    },
  };

  const relatedContracts = await graphqlGetContracts({ filterModel }, client, RELATED_CONTRACT_COLS.map((c) => c.propertyName));
  let relatedContractsCount = relatedContracts.length - 1;

  return {
    relatedContracts,
    relatedContractsCount,
  };
};
