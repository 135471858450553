import { ClaimRow } from 'api/endpoints';
import { GraphQlApolloClient } from 'api/graphql';
import { graphqlGetClaims } from 'utils/agGridHotchocolate/graphqlGetClaims';
import { AgGridFilterModel } from 'utils/agGridHotchocolate/types';

const RELATED_CLAIM_COLS: string[] = [
  'referenceNumber',
  'parentClaimNumber',
  'currentDebtor',
  'counterpartyName',
  'currentTotal',
  'officialClaimDate',
];

export interface RelatedClaimsData {
  childClaims: ClaimRow[];
  relatedClaims: ClaimRow[];
  parentClaim?: ClaimRow;
  relatedClaimsCount: number;
}

export const getRelatedClaims = async (
  parentClaimNumber: string | undefined,
  claimReferenceNumber: string | undefined,
  client: GraphQlApolloClient
) => {

  const relatedClaimsAsync = getClaims('parentClaimNumber', parentClaimNumber, client);
  const childClaimsAsync = getClaims('parentClaimNumber', claimReferenceNumber, client);
  const parentClaimAsync = getClaims('referenceNumber', parentClaimNumber, client);

  const result = await Promise.all([
    relatedClaimsAsync,
    childClaimsAsync,
    parentClaimAsync,
  ]);
  const [relatedClaims, childClaims, parentClaim] = result;

  let relatedClaimsCount = relatedClaims.length + childClaims.length;
  if (parentClaimNumber && parentClaimNumber !== '') relatedClaimsCount += 1;

  return {
    childClaims,
    relatedClaims,
    parentClaim: parentClaim[0],
    relatedClaimsCount,
  };
};

const getClaims = async (
  filterName: string,
  filter: string | undefined,
  client: GraphQlApolloClient,
) => {
  if (!filter || filter === '') return [];

  const filterModel: AgGridFilterModel = {
    [filterName]: {
      filterType: 'text',
      type: 'equals',
      filter,
    },
  };
  return await graphqlGetClaims({ filterModel }, client, RELATED_CLAIM_COLS);
};
