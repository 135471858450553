import { ApButtonMain, ApModal } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { InputText } from 'components/UIComponents/InputText';
import Select from 'components/UIComponents/Select';
import { useId } from 'hooks/useId';
import { DataContentBox } from 'pages/DataUploaderPage/components/DataContentBox';
import {
  Extension,
  FileUploadPanel,
} from 'pages/DataUploaderPage/components/FileUploadPanel';
import { useCallback, useState } from 'react';
import { BrowserFileRow } from './FileRowLite';
import './UploadModal.css';
import { formDataToSubmitData } from './UploadModal.utils';

export interface SubmitEntity {
  file: File;
  documentType: string;
  referenceNumber: string | null;
}

export type SubmitData = {
  entries: SubmitEntity[];
  comment: string | null;
};

const extensions: Extension[] = [
  //documents
  '.xlsx',
  '.xls',
  '.xlsm',
  '.pdf',
  '.docx',
  '.doc',
  //image
  '.png',
  '.gif',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.jif',
  '.heic', // Image used on the Mac/IPhone
  '.webp',
  //emails
  '.eml',
  '.msg',
  '.pst',
  '.edb',
  '.ost',
  '.mbox',
  // zip
  '.zip',
];

export interface UploadTypeOptions {
  label: string;
  value: string;
}

export type UploadModalProps = {
  title?: string;
  label: string;
  isOpen?: boolean;
  typeOptions?: UploadTypeOptions[];
  options?: {
    commentMaxLength?: number;
    withExtension?: Extension[] | Extension;
    warning?: string;
    maxOneFile?: boolean;
    hideComments?: boolean;
  };
  onClosed: () => void;
  onSubmit: (items: SubmitData) => void;
};

const TITLE_DEFAULT = 'Upload new document(s)';

export const UploadModal = ({
  title = TITLE_DEFAULT,
  label,
  isOpen = true,
  typeOptions,
  options = {},
  onClosed,
  onSubmit,
}: UploadModalProps) => {
  const [userSelectedFiles, setUserSelectedFiles] = useState<File[]>([]);
  const formId = useId();
  const [formStatus, setFormStatus] = useState<'idle' | 'invalid' | 'valid'>('idle');
  const { commentMaxLength, withExtension, warning, maxOneFile, hideComments } =
    options;

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();
      const form = e.target as HTMLFormElement;
      const isValidForm = form.checkValidity();
      setFormStatus(isValidForm ? 'valid' : 'invalid');
      if (!isValidForm) return;

      const formData = new FormData(form);
      const submitData = formDataToSubmitData(formData, userSelectedFiles);

      onSubmit(submitData);
      setUserSelectedFiles([]);
      onClosed();
    },
    [onClosed, userSelectedFiles, onSubmit],
  );

  const renderFooter = useCallback(
    (_footerProps: { handleClose?(): void }) => {
      return (
        <>
          <ApButtonMain type="submit" form={formId} className="upload-modal__action">
            Upload
          </ApButtonMain>
        </>
      );
    },
    [formId],
  );

  const handleDelete = useCallback(
    (file: File) => {
      const newFiles = userSelectedFiles.filter((f) => f.name !== file.name);
      setUserSelectedFiles(newFiles);
      setFormStatus('idle');
    },
    [userSelectedFiles],
  );

  const handleClose = useCallback(() => {
    setUserSelectedFiles([]);
    onClosed();
  }, [onClosed]);

  const handleUpload = useCallback((files: File[]) => {
    setUserSelectedFiles((prev) => [...files, ...prev]);
    setFormStatus('idle');
  }, []);

  const hasFiles = userSelectedFiles.length > 0;

  return (
    <div>
      <ApModal
        className={classNames('upload-modal utils-form', {
          'upload-modal__hide-footer': !hasFiles,
          'utils-form-invalid': formStatus === 'invalid',
        })}
        header={title}
        isOpen={isOpen}
        hasClosed={handleClose}
        isDismissible={true}
        footer={renderFooter}
      >
        <DataContentBox label={label} warning={warning}>
          {((maxOneFile && !hasFiles) || !maxOneFile) && (
            <FileUploadPanel
              accept={withExtension ?? extensions}
              onUpload={handleUpload}
              maxFiles={maxOneFile ? 1 : undefined}
            />
          )}
        </DataContentBox>

        <form id={formId} onSubmit={handleSubmit} autoComplete="off" noValidate>
          <div className="upload-modal__files">
            {userSelectedFiles.map((item) => (
              <div className="upload-modal-appear" key={item.name}>
                <BrowserFileRow file={item} onDelete={handleDelete} />
                {!!typeOptions?.length && (
                  <div className="upload-modal__file-options">
                    <fieldset className="utils-no-appearance">
                      <Select
                        autoComplete="false"
                        name={`documentType`}
                        required
                        className="normalize-pad"
                        placeholder="Please select"
                        options={typeOptions}
                        label="Documentation type"
                      />
                    </fieldset>
                    <fieldset className="utils-no-appearance">
                      <InputText
                        className="normalize-pad"
                        name={`referenceNumber`}
                        label="Docket number (if applicable)"
                      />
                    </fieldset>
                  </div>
                )}
              </div>
            ))}
          </div>
          {hasFiles && !hideComments && (
            <InputText
              role="input"
              name="comment"
              className={classNames(
                'upload-modal__comment',
                'upload-modal__normalize-pad',
                'upload-modal-appear',
              )}
              label="Comment"
              inputComponent="textarea"
              maxLength={commentMaxLength}
            />
          )}
        </form>
      </ApModal>
    </div>
  );
};
