export const SOFA_9_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['16', '17'],
      queryType: 'String!'
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },

    {
      dataType: 'bool',
      displayName: 'Does the Debtor Collect Personal Information',
      propertyName: 'yesNo',
    },
    {
      dataType: 'string',
      displayName: 'Description of Information Collected',
      propertyName: 'description',
    },
    {
      dataType: 'string',
      displayName: 'Privacy Policy',
      propertyName: 'privacyPolicy',
    },

    {
      dataType: 'string',
      displayName: 'Name of Plan',
      propertyName: 'nameOfPlan',
    },
    {
      dataType: 'string',
      displayName: 'EIN',
      propertyName: 'ein',
    },
    {
      dataType: 'bool',
      displayName: 'Plan Terminated',
      propertyName: 'planTerminated',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
