import { ApLoaderDefault, ApIcon } from '@alixpartners/ui-components';
import { ApButtonCasual } from 'components/UIComponents/Buttons';
import './ExportButton.css';

interface ExportButtonProps {
  onClick: () => void;
  exporting: boolean;
}

export const ExportButton = (props: ExportButtonProps) => (
  <ApButtonCasual className="export-button" onClick={props.onClick}>
    {props.exporting ? (
      <>
        <ApLoaderDefault loaderSize="sm" /> Generating file
      </>
    ) : (
      <>
        <ApIcon iconName="save_alt" /> Export Entities
      </>
    )}
  </ApButtonCasual>
);
