import { useEffect, useRef, useState } from 'react';
import './DropdownAddCategory.css';
import { ApButtonSecondary, ApIcon, ApMenuItem } from '@alixpartners/ui-components';
import { IOption } from 'components/UIComponents/MultiSelect';
import classNames from 'classnames';

export type SetterNameValues =
  | 'majorCategoryId'
  | 'majorCategoryName'
  | 'contractCategoryId'
  | 'categoryName';

type DropdownProps = {
  options: IOption[];
  handleClick: (name: SetterNameValues, value: any) => void;
  addButtonText?: string;
  className?: string;
  formSubmitted?: boolean;
  valueSetters: {
    optionClickSetter: SetterNameValues;
    inputSetter: SetterNameValues;
  };
};

export const DropdownAddCategory = ({
  options,
  handleClick,
  addButtonText = 'Add a new item',
  className,
  formSubmitted,
  valueSetters: { optionClickSetter, inputSetter },
}: DropdownProps) => {
  const [selectedOption, setSelectedOption] = useState<IOption | undefined>();
  const [opened, setOpened] = useState(false);
  const [showAddInput, setShowAddInput] = useState(false);

  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const inputRef = useRef<HTMLInputElement | null>(null);

  const handleFirstOptionClick = () => {
    setOpened((prev) => !prev);
    setShowAddInput(false);
  };

  const handleOptionSelected = (option: IOption) => {
    handleClick(optionClickSetter, option.value);
    setSelectedOption(option);
    setOpened(false);
  };

  const handleAddInputChange = (option: IOption) => {
    handleClick(inputSetter, option.value);
    setSelectedOption(option);
  };

  // close dropdown when clicking outside
  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target))
        setOpened(false);
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  useEffect(() => {
    if (showAddInput && inputRef.current) inputRef.current.focus();
    if (formSubmitted) setSelectedOption(undefined);
  }, [inputRef, showAddInput, formSubmitted]);

  return (
    <div
      className={classNames('dropdown-add-category', className)}
      ref={dropdownRef}
    >
      <ApButtonSecondary
        className="dropdown-add-category__selected"
        onClick={handleFirstOptionClick}
        type="button"
        medium
      >
        {selectedOption?.label ?? 'Select a category:'}
      </ApButtonSecondary>
      {opened && (
        <div className="dropdown-add-category__options">
          <ApButtonSecondary
            iconName="baseline_add"
            className="dropdown-add-category_add-btn"
            onClick={() => setShowAddInput((prev) => !prev)}
            type="button"
            medium
          >
            Add {addButtonText}
          </ApButtonSecondary>
          {showAddInput && (
            <input
              ref={inputRef}
              className="dropdown-add-category_add-input"
              type="text"
              placeholder={`Enter the ${addButtonText} `}
              onChange={(e) =>
                handleAddInputChange({
                  label: e.target.value,
                  value: e.target.value,
                })
              }
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleClick(inputSetter, selectedOption?.value);
                  setOpened(false);
                }
              }}
            />
          )}
          {options.map((option, id) => (
            <ApMenuItem
              key={`${option.value}_${id}`}
              onClick={() => handleOptionSelected(option)}
              className={classNames('dropdown-add-category__options-button', {
                selected: selectedOption?.label === option.label,
              })}
            >
              <div className="dropdown-add-category__options-content">
                {option.label}
                {selectedOption?.label === option.label && (
                  <ApIcon iconName="outline_done" />
                )}
              </div>
            </ApMenuItem>
          ))}
        </div>
      )}
    </div>
  );
};
