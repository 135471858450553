import './SingleContractRelatedContracts.css';
import { ApButtonLink } from '@alixpartners/ui-components';
import { useNavigate } from 'react-router';
import { buildSingleContractPath } from 'utils/hooks/useContractToolQueryParams';
import { SectionType, SubsectionType } from 'pages/ClaimsContractsToolPage/types';
import { formatDate } from 'utils/formatDate';
import {
  RELATED_CONTRACT_COLS,
  RelatedContractsData,
} from '../SingleContractOverview/util/getRelatedContracts';

export const SingleContractRelatedContracts = ({
  relatedContractsData,
  selectedContractId,
}: {
  selectedContractId: number;
  relatedContractsData: RelatedContractsData;
}) => {
  const navigate = useNavigate();

  const handleRefNoClick = (id?: number) => {
    if (!id) return;
    navigate(
      buildSingleContractPath(id, SectionType.Contracts, SubsectionType.AllClaims),
    );
  };

  return (
    <section className="related-contracts scp-section">
      <div className="related-contracts-headers">
        {RELATED_CONTRACT_COLS.map((col) => {
          return <h5 key={col.propertyName}>{col.label}</h5>;
        })}
      </div>
      <div className="related-contracts-content">
        {relatedContractsData.relatedContracts
          .filter((contract) => contract.id !== selectedContractId)
          .map((contract) => {
            return (
              <div className="related-contracts-content-item" key={contract.id}>
                {RELATED_CONTRACT_COLS.map((header) => {
                  if (
                    header.propertyName === 'referenceNumber' ||
                    header.propertyName === 'parentContractNumber'
                  ) {
                    return (
                      <ApButtonLink
                        key={`${contract.id}_${header.propertyName}`}
                        onClick={() => handleRefNoClick(contract.id)}
                      >
                        {contract[header.propertyName]}
                      </ApButtonLink>
                    );
                  }
                  if (header.propertyName === 'contractDate') {
                    return (
                      <p key={`${contract.id}_${header.propertyName}`}>
                        {formatDate(contract.contractDate, 'MM-DD-YYYY')}
                      </p>
                    );
                  }
                  return (
                    <p key={`${contract.id}_${header.propertyName}`}>
                      {String(contract[header.propertyName] ?? '-')}
                    </p>
                  );
                })}
              </div>
            );
          })}
      </div>
    </section>
  );
};
