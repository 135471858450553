export const SOFA_8_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['15'],
      queryType: 'String!'
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Facility Name',
      propertyName: 'name',
    },
    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },

    {
      dataType: 'string',
      displayName: 'Nature of Business',
      propertyName: 'nature',
    },
    {
      dataType: 'string',
      displayName: 'Location (if Different)',
      propertyName: 'location',
    },
    {
      dataType: 'string',
      displayName: 'No. of Patients in Debtors Care',
      propertyName: 'patients',
    },
    {
      dataType: 'string',
      displayName: 'Records Kept Electronically',
      propertyName: 'electronic',
    },
    {
      dataType: 'string',
      displayName: 'Paper Records Kept',
      propertyName: 'paper',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
