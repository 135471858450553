import { Link } from 'react-router-dom';
import { DashboardTaskInfo } from 'reducer/userDashboardTasksReducer';
import { formatMoney } from 'utils/formatNumber';

export const DashboardTaskCount = ({
  task,
  taskPercentage,
  firstTask,
}: {
  task: DashboardTaskInfo;
  taskPercentage?: number;
  firstTask?: boolean;
}) => {
  const {
    to: taskTo,
    estimatesRejectionDamageHigh,
    estimatesRejectionDamageLow,
    totalProposed,
    count,
  } = task;

  return (
    <>
      {/* claim & contract item count */}
      {taskTo ? (
        // first task already has onClick handler
        !firstTask ? (
          <Link to={taskTo}>{count}</Link>
        ) : (
          <span>{count}</span>
        )
      ) : (
        ''
      )}

      {/* rejection low amount */}
      {estimatesRejectionDamageLow !== undefined && (
        <>
          <pre>Low: </pre>
          {formatMoney(estimatesRejectionDamageLow)}
        </>
      )}

      <span className="task-percentage">
        {/* claims totalProposed */}
        {totalProposed !== undefined && `${formatMoney(totalProposed)}`}

        {/* contract percentage */}
        {taskPercentage !== undefined && `${taskPercentage}%`}

        {/* rejection high amount */}
        {estimatesRejectionDamageHigh !== undefined && (
          <>
            <pre>High: </pre>
            {formatMoney(estimatesRejectionDamageHigh)}
          </>
        )}

        {/* Cure amount */}
        {!taskTo && count !== undefined && formatMoney(count)}
      </span>
    </>
  );
};
