export const SOFA_12_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['22', '23', '24'],
      queryType: 'String!'
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Case Title',
      propertyName: 'caseTitle',
    },
    {
      dataType: 'string',
      displayName: 'Case Number',
      propertyName: 'caseNumber',
    },
    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },

    {
      dataType: 'string',
      displayName: 'Nature of Case',
      propertyName: 'natureOfCase',
    },
    {
      dataType: 'string',
      displayName: 'Status (Pending, On appeal, Concluded)',
      propertyName: 'status',
    },

    {
      dataType: 'string',
      displayName: 'Gov Unit Name',
      propertyName: 'govName',
    },
    {
      dataType: 'string',
      displayName: 'Gov Address 1',
      propertyName: 'govAddress1',
    },
    {
      dataType: 'string',
      displayName: 'Gov Address 2',
      propertyName: 'govAddress2',
    },
    {
      dataType: 'string',
      displayName: 'Gov Address 3',
      propertyName: 'govAddress3',
    },
    {
      dataType: 'string',
      displayName: 'Gov City',
      propertyName: 'govCity',
    },
    {
      dataType: 'string',
      displayName: 'Gov State',
      propertyName: 'govState',
    },
    {
      dataType: 'string',
      displayName: 'Gov Zip',
      propertyName: 'govZip',
    },
    {
      dataType: 'string',
      displayName: 'Gov Country',
      propertyName: 'govCountry',
    },
    {
      dataType: 'string',
      displayName: 'Environmental Law',
      propertyName: 'environmentalLaw',
    },
    {
      dataType: 'string',
      displayName: 'Notice Date',
      propertyName: 'noticeDate',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
