import { ApButtonMain } from '@alixpartners/ui-components';
import './MatchingSectionCard.css';

interface MatchingSectionCardProps {
  section: string;
  description: string;
  buttonDescription: string;
  handleClick: () => void;
  buttonDisabled?: boolean;
}

export const MatchingSectionCard = ({
  section,
  description,
  buttonDescription,
  handleClick,
  buttonDisabled = false,
}: MatchingSectionCardProps) => {
  return (
    <div className="matching-section-card__container">
      <h3>Match using {section}</h3>
      <p>{description}</p>
      <ApButtonMain disabled={buttonDisabled} onClick={handleClick} medium>
        {buttonDescription}
      </ApButtonMain>
    </div>
  );
};
