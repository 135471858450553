import { FetchPolicy, gql } from '@apollo/client';
import {
  ActionStatus,
  ClaimEditChangeOptions,
  ClaimEditData,
  ClaimEditValues,
  claims,
  configuration,
} from 'api/endpoints';
import { clientClaims } from 'api/endpoints/clientClaims';
import useGraphql from 'api/graphql/useGraphql';
import { ReviewersBatchItem } from 'components/ReviewersEdit/ReviewersEdit.utils';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import _, { intersection } from 'lodash';
import { GridColumnsSectionType } from 'pages/ClaimsContractsToolPage/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Base,
  CANSSliceType,
  clearToSend,
} from 'reducer/claimActionNameSelectionReducer';
import { ClaimReviewerData } from 'reducer/claimReviewerReducer';
import { fetchUserDashboardTasksThunk } from 'reducer/thunks/dashboard-thunks';
import { USER_ROLE } from 'utils/AppInitializer/application-user';
import { useApplicationUser } from 'utils/AppInitializer/hooks/useApplicationUser';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { survivingClaimNumberUniq } from '../util/valueFormatter';
import { useEditableField } from './useEditableField';
import useEditActionStatus from './useEditActionStatus';
import { getRelatedClaims, RelatedClaimsData } from 'pages/SingleClaimPage/sections/SingleClaimOverview/util/getRelatedClaims';

const idColumns = [
  'id',
  'originalDebtorId',
  'currentDebtorId',
  'proposedDebtorId',
  'alixPartnersDebtorId',
  'clientDebtorId',
  'counselDebtorId',
  'pORDebtorId',
  'majorCategoryId',
  'minorCategoryId',
  'actionNameId',
  'actionExhibitTypeId',
  'actionSubStatusId',
];

export type EditFieldFunc = (
  field: string,
  value: string | number | boolean | ReviewersBatchItem,
) => unknown;
export type ChangeOptionsHook = [
  ClaimEditChangeOptions,
  React.Dispatch<React.SetStateAction<ClaimEditChangeOptions>>,
];

const DEFAULT_CHANGE_OPTIONS: ClaimEditChangeOptions = { comment: '' };

const USER_TRANSACTION_ROLE = [
  USER_ROLE.TransactionApprover,
  USER_ROLE.TransactionProposer,
];

type ConfirmationModal = {
  message: string;
  onConfirm: (confirm: boolean) => void;
};

export interface EditClaimHook {
  loading: boolean;
  isEdited: boolean;
  claim: ClaimEditValues | undefined;
  changeOptionsHook: ChangeOptionsHook;
  isSaving: boolean;
  claimId: number;
  confirmationModal: ConfirmationModal | undefined;
  editField: EditFieldFunc;
  saveButtonClick: () => unknown;
  relatedClaimsData: RelatedClaimsData;
  isEditable: (
    field: string,
    column?: string,
    actionStatus?: ActionStatus | undefined,
  ) => boolean;
}

export function useEditClaim(): EditClaimHook {
  const dispatch = useAppDispatch();
  const user = useApplicationUser();
  const [isEdited, setEdited] = useState(true);
  const [isSaving, setSaving] = useState(false);
  const [loading, setLoading] = useState(true);
  const [confirmationModal, setConfirmationModal] = useState<ConfirmationModal>();

  const changeOptionsHook = useState(DEFAULT_CHANGE_OPTIONS);
  const [changeOptions, setChangeOptions] = changeOptionsHook;
  const [claimEdits, setClaimEdits] = useState<Partial<ClaimEditValues>>({});
  const [originalClaim, setOriginalClaim] = useState<ClaimEditValues | undefined>();
  const [client, authorized] = useGraphql();
  const applicationUser = useApplicationUser();
  const { isEditable } = useEditableField();
  const { editActionField } = useEditActionStatus(originalClaim);
  const { isClient } = useAppUserType();
  const { showMine } = useAppSelector((s) => s.userDashboardTasks.slice);
  const [relatedClaimsData, setRelatedClaimsData] = useState<RelatedClaimsData>({} as RelatedClaimsData);

  const {
    claimActionNameSelection: { toSend: actionEdits, type: actionNameEditsType },
    claimReviewer: claimReviewers,
  } = useAppSelector((state) => state);

  const userHasTransactionRole = useMemo(
    () => !!intersection(applicationUser.data?.roles, USER_TRANSACTION_ROLE).length,
    [applicationUser.data?.roles],
  );

  let { id } = useParams();
  const claimId = Number(id);

  const load = useCallback(
    async (policy?: FetchPolicy) => {
      setLoading(true);
      const params = {
        query: await getEditClaim(),
        fetchPolicy: policy,
        variables: { id: claimId },
      };
      const result = await client.query(params);
      const item = extractClaimItem(result.data);
      const relatedClaims = await getRelatedClaims(item?.parentClaimNumber, item?.referenceNumber, client);
      setRelatedClaimsData(relatedClaims);
      setOriginalClaim(item);
      setLoading(false);
    },
    [claimId, client],
  );

  useEffect(() => {
    if (!authorized) return;
    load();
  }, [authorized, load]);

  useEffect(() => {
    if (actionNameEditsType === CANSSliceType.Touched) {
      setEdited(true);
    }
  }, [actionNameEditsType]);

  const editField = useCallback(
    (field: string, value: string | number | boolean | ReviewersBatchItem) => {
      if (
        [
          'actionTypeId',
          'actionSubstatusId',
          'actionNameId',
          'actionStatus',
        ].includes(field)
      ) {
        editActionField(field, value as string);
        return;
      }

      const existingValue = _.get(claimEdits, field) ?? _.get(originalClaim, field);
      if (value === existingValue) return;

      setClaimEdits({ ...claimEdits, [field]: value });
      setEdited(true);
    },
    [claimEdits, originalClaim, editActionField],
  );

  const saveData = useCallback(
    async (data: ClaimEditData) => {
      if (isSaving) return;

      setSaving(true);
      if (userHasTransactionRole) {
        await claims.edit(data);
      } else {
        await clientClaims.edit(data);
      }

      setSaving(false);
      setChangeOptions(DEFAULT_CHANGE_OPTIONS);
      setClaimEdits({});

      await load('network-only');
      if (user?.data)
        dispatch(fetchUserDashboardTasksThunk({ user: user.data, showMine }));
      dispatch(clearToSend({}));
    },
    [
      dispatch,
      isSaving,
      load,
      setChangeOptions,
      user.data,
      userHasTransactionRole,
      showMine,
    ],
  );

  const validateData = useCallback(async (data: ClaimEditData) => {
    setSaving(true);
    const warningsList = await claims.warnings(data);
    const result = warningsList?.[0];
    setSaving(false);

    return result;
  }, []);

  const saveButtonClick = useCallback(async () => {
    if (!isEdited || isSaving || !originalClaim) return;

    const dataToSave = createDataToSave(
      originalClaim,
      claimEdits,
      actionEdits,
      claimReviewers.data,
      changeOptions,
    );

    if (isClient) {
      saveData(dataToSave);
      return;
    }

    const validationError = await validateData(dataToSave);

    if (!validationError) {
      saveData(dataToSave);
      return;
    }

    setConfirmationModal({
      message: validationError,
      onConfirm: (confirmed: boolean) => {
        if (confirmed) saveData(dataToSave);
        setConfirmationModal(undefined);
      },
    });
  }, [
    isEdited,
    isSaving,
    originalClaim,
    claimEdits,
    actionEdits,
    claimReviewers.data,
    changeOptions,
    isClient,
    validateData,
    saveData,
  ]);

  const claim = useMemo(
    () => (originalClaim ? { ...originalClaim, ...claimEdits } : undefined),
    [claimEdits, originalClaim],
  );

  return {
    loading,
    isEdited,
    isSaving,
    claim,
    changeOptionsHook,
    claimId,
    confirmationModal,
    isEditable,
    editField,
    saveButtonClick,
    relatedClaimsData,
  };
}

const createDataToSave = (
  originalClaim: ClaimEditValues,
  claimEdits: Partial<ClaimEditValues>,
  actionEdits: Base['toSend'],
  reviewers: ClaimReviewerData,
  changeOptions: ClaimEditChangeOptions,
): ClaimEditData => {
  //todo: FIX BUG: looks like fields in the ClaimEditValues type are invalid
  const values = {
    ...claimEdits,
    survivingClaimNumber: survivingClaimNumberUniq(claimEdits.survivingClaimNumber),
    actionNameId: actionEdits.actionNameId,
    actionExhibitTypeId: actionEdits.actionTypeId,
    actionSubStatusId: actionEdits.actionSubstatusId,
    actionStatus: actionEdits.actionStatus,
    claimReviewerUserGroupIds: reviewers.userGroupReviewers,
    claimReviewerUserIds: reviewers.userReviewers,
  } as ClaimEditValues;

  return {
    claimId: originalClaim.id,
    values,
    changeOptions,
  };
};

function extractClaimItem(data: any): ClaimEditValues | undefined {
  const item: ClaimEditValues | undefined = data?.claims?.items?.[0];
  if (!item) return undefined;

  return {
    ...item,
    _fetchTimestamp: new Date().toISOString(),
  };
}

const getEditClaim = async () => {
  const allGridColumns =
    (await configuration.getGridColumns(GridColumnsSectionType.Claim)) ?? [];
  const filteredColumns = allGridColumns
    // We need to filter non-dynamic cols and only enabled dynamic fields
    .filter((el) => !(el.propertyName.includes('custom') && !el.enabled))
    .map((c) => c.propertyName)
    .sort();

  const requestCols = [...idColumns, ...filteredColumns];

  return gql`
      query GetMatchCodeClaims($id: Int!) {
        claims(where: {
          id: { eq: $id }
        }) {
          totalCount
          items {
            ${requestCols.join(' ')}
          }
        }
      }
    `;
};
