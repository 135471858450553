import {
  SettlementDocumentGet,
  SettlementDocumentType,
  settlements,
} from 'api/endpoints/settlements';
import { UploadTypeOptions } from 'components/ModalWindows/UploadModal';
import { formatDate, READABLE_DAY_MONTH_YEAR } from 'utils/formatDate';

export interface SettlementDocument extends SettlementDocumentGet {
  documentTypeName: string;
  createdDateFormatted: string;
}

export const apiGetSettlementDocuments = async (id: number) => {
  const [documents = [], statuses = []] = await Promise.all([
    settlements.getDocuments(id),
    settlements.documentTypes(),
  ]);

  const statusOptions = statuses.map(mapStatusToOptions);
  const resultDocuments = documents.map((r) => mapDocumentsFormatted(r, statuses));

  return [resultDocuments, statusOptions] as const;
};

function mapDocumentsFormatted(
  document: SettlementDocumentGet,
  statuses: SettlementDocumentType[],
): SettlementDocument {
  return {
    ...document,
    documentTypeName:
      statuses.find((r) => r.id === document.settlementDocumentTypeId)
        ?.description ?? '',
    createdDateFormatted: formatDate(document.createdDate, READABLE_DAY_MONTH_YEAR),
  };
}

function mapStatusToOptions(r: SettlementDocumentType) {
  const result: UploadTypeOptions = {
    label: r.description,
    value: `${r.id}`,
  };

  return result;
}
