import React, { useMemo } from 'react';
import classNames from 'classnames';
import {
  ClaimTallyItem,
  ContractItemFormatted,
  ContractRowFormatted,
  claimKey,
  ClaimTableType,
  ClaimItemFormatted,
} from './Counterparty.types';
import {
  formatClaimTallyItem,
  formatContractItem,
  formatFiledClaimItem,
  formatScheduledClaimItem,
} from './CounterpartyPage.mappers';
import { ClaimRow } from 'api/endpoints';
import { buildSingleClaimPath } from '../../utils/hooks/useClaimToolQueryParams';
import { SectionType, SubsectionType } from '../ClaimsContractsToolPage/types';
import { Link } from 'react-router-dom';
import { buildSingleContractPath } from '../../utils/hooks/useContractToolQueryParams';
import {
  boldKeys,
  filedClaimsHeaders,
  hiddenColumnValues,
} from './Counterparty.const';

export type TallyTableProps = {
  items: ClaimTallyItem[];
  title: string;
  className?: string;
  skipCount?: boolean;
};

function TallyTable(props: TallyTableProps) {
  const { items: itemsProp, className, title } = props;
  const items = useMemo(() => {
    return itemsProp.map(formatClaimTallyItem);
  }, [itemsProp]);

  const cn = classNames('simple-table', className);
  return (
    <section role="table" className={cn}>
      <h5 className="simple-table-title ">{title}</h5>
      <div className="simple-table-header">Secured</div>
      <div className="simple-table-header">Admin</div>
      <div className="simple-table-header">Priority</div>
      <div className="simple-table-header">Unsecured</div>
      <div className="simple-table-header simple-table-bold">Total</div>

      {items.map((r, index) => {
        return (
          <React.Fragment key={`${r.label}${index}`}>
            <div>{r.label}</div>
            <div>{r.secured_formatted}</div>
            <div>{r.admin_formatted}</div>
            <div>{r.priority_formatted}</div>
            <div>{r.unsecured_formatted}</div>
            <div className="simple-table-bold">{r.total_formatted}</div>
          </React.Fragment>
        );
      })}
    </section>
  );
}

export function FiledClaimsTallyTable(props: {
  items: ClaimTallyItem[];
  title: string;
}) {
  return (
    <TallyTable
      title={props.title}
      items={props.items}
      className="filed-claims-tally-table"
    />
  );
}

export function ScheduledClaimsTallyTable(props: {
  items: ClaimTallyItem[];
  title: string;
}) {
  return (
    <TallyTable
      title={props.title}
      skipCount
      items={props.items}
      className="scheduled-claims-tally-table"
    />
  );
}

export type ClaimsFullTableProps = {
  items: ClaimRow[];
  className?: string;
  title?: string;
  keys: claimKey[];
  tableType: ClaimTableType;
};

type ClaimColumnValueType = string | number | JSX.Element | undefined;

export const ClaimsFullTable = (props: ClaimsFullTableProps) => {
  const { items: itemsProp, title, keys, tableType } = props;
  const items: ClaimItemFormatted[] = useMemo(
    () =>
      itemsProp?.map(
        tableType === ClaimTableType.FILED
          ? formatFiledClaimItem
          : formatScheduledClaimItem,
      ),
    [itemsProp, tableType],
  );
  return (
    <div className="claims-full-table__container">
      <div className="filed-claims-full-table__start-padder"></div>
      <section className={classNames('thin-scrollbar', 'claims-full-table')}>
        <h5 className="simple-table-title simple-table-fullwidth">{title}</h5>
        <div className="claims-full-table__columns">
          <div className="claims-full-table__column">
            {keys?.map((key) => {
              return (
                <ClaimColumn
                  key={key}
                  title={filedClaimsHeaders[key]}
                  value={getValue(items[0], key)}
                  itemKey={key}
                />
              );
            })}
          </div>
        </div>
      </section>
      <div className="filed-claims-full-table__end-padder"></div>
    </div>
  );
};

interface ClaimColumnProps {
  title: string;
  value: ClaimColumnValueType;
  itemKey: claimKey;
}

const ClaimColumn = ({ title, value, itemKey }: ClaimColumnProps) => {
  const noBoldTitle = !boldKeys.includes(itemKey);
  if (itemKey === 'empty')
    return (
      <div
        className={classNames('claims-full-table__column__cell', {
          empty: itemKey === 'empty',
        })}
      ></div>
    );
  return (
    <div className="claims-full-table__column__cell">
      <div
        className={classNames('claims-full-table__column__cell__header', {
          'bold-title': noBoldTitle,
        })}
      >
        {title}
      </div>
      <div className="claims-full-table__column__cell__value">{value}</div>
    </div>
  );
};

const getValue = (item: ClaimItemFormatted, key: claimKey): ClaimColumnValueType => {
  if (key === 'referenceNumber') {
    const singleClaimUrl = buildSingleClaimPath(
      item.id,
      SectionType.Claims,
      SubsectionType.AllClaims,
    );
    return <Link to={singleClaimUrl}>{item[key]}</Link>;
  }
  if (hiddenColumnValues.includes(key) || key === 'empty') return '';
  return item[key];
};

export function ContractsTable(props: { items: ContractRowFormatted[] }) {
  const { items: itemsProp } = props;

  const items: ContractItemFormatted[] = useMemo(() => {
    return itemsProp.map(formatContractItem);
  }, [itemsProp]);

  return (
    <section role="table" className="scheduled-claims-table simple-table">
      <h5 className="simple-table-title simple-table-fullwidth">Contracts</h5>

      <div className="simple-table-header simple-table-bold">
        Contract Ref Number
      </div>
      <div className="simple-table-header simple-table-bold simple-table-space">
        Counterparty name
      </div>
      <div className="simple-table-header">Match code</div>
      <div className="simple-table-header">Category</div>
      <div className="simple-table-header">Resolution name</div>
      <div className="simple-table-header">Workflow status</div>
      <div className="simple-table-header">Resolution subtype</div>
      <div className="simple-table-header">Debtor</div>
      <div className="simple-table-header">Contract Owner</div>
      <div className="simple-table-header">Rejection Damages Low</div>
      <div className="simple-table-header">Rejection Damages High</div>
      <div className="simple-table-header">Contract Title</div>

      {items.map((r) => {
        const singleContractUrl = buildSingleContractPath(
          r?.id,
          SectionType.Contracts,
          SubsectionType.AllClaims,
        );
        return (
          <React.Fragment key={r.id}>
            <div className="simple-table-highlight">
              <Link to={singleContractUrl}>{r.referenceNumber}</Link>
            </div>
            <div className="simple-table-space">{r.counterpartyName}</div>

            <div>{r.matchCode}</div>
            <div>{r.category}</div>
            <div>{r.contractActionName}</div>
            <div>{r.contractStatus}</div>
            <div>{r.contractActionSubStatusName}</div>
            <div>{r.primaryDebtor}</div>
            <div>{r.contractsReviewers}</div>
            <div>{r.rejectionDamageLow_formatted}</div>
            <div>{r.rejectionDamagesHigh_formatted}</div>
            <div>{r.description}</div>
          </React.Fragment>
        );
      })}
    </section>
  );
}
