import { ReviewersBatchItem } from '../../components/ReviewersEdit/ReviewersEdit.utils';
import { downloadFile, get, post, postUploadFile, put } from '../apiRequest';

export enum ActionStatus {
  ScheduledClaim = 'ScheduledClaim',
  FiledClaim = 'FiledClaim',
  Categorized = 'Categorized',
  MatchingComplete = 'MatchingComplete',
  MatchesFound = 'MatchesFound',
  Matched = 'MatchCodeAssigned',
  Assigned = 'Assigned',
  ClientReconciled = 'ClientReconciled',
  AlixReconciled = 'AlixReconciled',
  ObjectionPending = 'ObjectionPending',
  ObjectionFiled = 'ObjectionFiled',
  ObjectionContinued = 'ObjectionContinued',
  ObjectionOrderedPartial = 'ObjectionOrderedPartial',
  ObjectionOrderedFinal = 'ObjectionOrderedFinal',
  ObjectionWithdrawn = 'ObjectionWithdrawn',
  Withdrawn = 'Withdrawn',
  SettlementPending = 'SettlementPending',
  SettlementPartial = 'SettlementPartial',
  SettlementFinal = 'SettlementFinal',
  Voided = 'Voided',
  TrumpedSchedule = 'TrumpedSchedule',
  ZeroDollarValueScheduledOnly = 'ZeroDollarValueScheduledOnly',
  Allowable = 'Allowable',
  Allowed = 'Allowed',
  OrderVacated = 'OrderVacated',
  SettlementResolved = 'SettlementResolved',
}

export type ClaimActionIdFields = {
  actionNameId?: number;
  actionExhibitTypeId?: number;
  actionSubStatusId?: number;
};

export type ClaimActionFields = {
  // The name of the actionName. It should be actionNameName ideally/correctly,
  actionName?: string;
  actionExhibitTypeName?: string;
  actionSubStatusName?: string;
} & ClaimActionIdFields;

export interface ClaimRow {
  id: number;
  referenceNumber: string;
  actionStatus?: ActionStatus;
  counterpartyName: string;
  matchCode: number;
  subMatchCode?: number;
  numberOfMatches?: number;
  notMatched?: boolean;
  hasOneHundredPercentMatch?: boolean;
  oneHundredPercentMatchCode?: string;
  createdDate?: string;
  sourceType?: string;
  majorCategory?: string;
  minorCategory?: string;
  currentTotal?: number;
  currentDebtor?: string;
  claimReviewers?: string;
  claimImage?: string;
  objectionCommentary?: string;
  actionExhibitTypeName?: string;
  actionName?: string;
  actionSubStatusName?: string;
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  email?: string;
  phone?: string;
  originalDebtor?: string;
  proposedDebtor?: string;
  clientDebtor?: string;
  counselDebtor?: string;
  pORDebtor?: string;
  barDateRef?: string;
  scheduledDescription?: string;
  claimScheduleNumber?: string;
  claimsAgentNumber?: string;
  redactImage?: boolean;
  underSeal?: boolean;
  officialClaimDate?: string;
  dateRecdFromClaimsAgent?: string;
  originalSecured?: number;
  originalAdministrative?: number;
  originalUnsecured?: number;
  originalTotal?: number;
  currentSecured?: number;
  currentAdministrative?: number;
  currentPriority?: number;
  currentUnsecured?: number;
  proposedSecured?: number;
  proposedAdministrative?: number;
  proposedPriority?: number;
  proposedUnsecured?: number;
  proposedTotal?: number;
  clientSecured?: number;
  clientAdministrative?: number;
  clientPriority?: number;
  clientUnsecured?: number;
  clientTotal?: number;
  counselSecured?: number;
  counselAdministrative?: number;
  counselPriority?: number;
  counselUnsecured?: number;
  counselTotal?: number;
  highSecured?: number;
  highAdministrative?: number;
  highPriority?: number;
  highUnsecured?: number;
  highTotal?: number;
  lowSecured?: number;
  lowAdministrative?: number;
  lowPriority?: number;
  lowUnsecured?: number;
  lowTotal?: number;
  pORSecured?: number;
  pORAdministrative?: number;
  pORPriority?: number;
  pORUnsecured?: number;
  pORTotal?: number;
  reviewLevel?: string;
  reviewLevelId?: number;
  originalContingent?: boolean;
  originalCurrentProposed?: string;
  originalUnliquidated?: boolean;
  originalDisputed?: boolean;
  originalUndetermined?: boolean;
  currentContingent?: boolean;
  currentUnliquidated?: boolean;
  currentDisputed?: boolean;
  currentUndetermined?: boolean;
  proposedContingent?: boolean;
  proposedUnliquidated?: boolean;
  proposedDisputed?: boolean;
  proposedUndetermined?: boolean;
  clientContingent?: boolean;
  clientUnliquidated?: boolean;
  clientDisputed?: boolean;
  clientUndetermined?: boolean;
  counselContingent?: boolean;
  counselUnliquidated?: boolean;
  counselDisputed?: boolean;
  counselUndetermined?: boolean;
  pORContingent?: boolean;
  pORUnliquidated?: boolean;
  pORDisputed?: boolean;
  pORUndetermined?: boolean;
  parentClaimNumber?: string;
  motionMajorCategory?: string;
  motionMinorCategory?: string;
  survivingClaimNumber?: string;
}

export interface ClaimBatchData {
  claimIds: number[];
  values: {
    counterpartyName: string;
    categoryId: string;
    actionStatus: number;
    alixPartnersSecured: number;
    alixPartnersAdministrative: number;
    alixPartnersUnsecured: number;
    alixPartnersPriority: number;
    alixPartnersTotal: number;
    clientSecured: number;
    clientAdministrative: number;
    clientUnsecured: number;
    clientPriority: number;
    clientTotal: number;
    counselSecured: number;
    counselAdministrative: number;
    counselUnsecured: number;
    counselPriority: number;
    counselTotal: number;
    claimReviewer: ReviewersBatchItem;
  };
}

export type ClaimEditChangeOptionsMarkFlags = {
  markClaimAsReconciled?: boolean;
  markClaimReviewAsComplete?: boolean;
  markClaimAsAssigned?: boolean;
  markClaimAsCategorized?: boolean;
  markClaimAsReviewComplete?: boolean;
  markClaimAsFiled?: boolean;
  markClaimAsOrdered?: boolean;
  markClaimAsSettledAndResolved?: boolean;
};

export type ClaimEditChangeOptions = {
  comment: string;
} & ClaimEditChangeOptionsMarkFlags;

export type LabeledClaimEditChangeOptionsMarkFlag = {
  label: string;
  changeOptionsMarkFlag: keyof ClaimEditChangeOptionsMarkFlags;
};

export interface ClaimEditData {
  claimId: number | undefined;
  values: ClaimEditValues;
  changeOptions: ClaimEditChangeOptions;
}

export type ClaimEditValues = {
  id: number;
  originalDebtorId?: number;
  currentDebtorId?: number;
  proposedDebtorId?: number;
  alixPartnersDebtorId?: number;
  clientDebtorId?: number;
  counselDebtorId?: number;
  pORDebtorId?: number;
  claimReviewer: ReviewersBatchItem;
  currentSecured?: number;
  currentAdministrative?: number;
  currentPriority?: number;
  currentUnsecured?: number;
  currentTotal?: number;
  dashboardCategory?: string;
  proposedSecured?: number;
  proposedAdministrative?: number;
  proposedPriority?: number;
  proposedUnsecured?: number;
  proposedTotal?: number;
  alixPartnersSecured?: number;
  alixPartnersAdministrative?: number;
  alixPartnersPriority?: number;
  alixPartnersUnsecured?: number;
  alixPartnersTotal?: number;
  clientSecured?: number;
  clientAdministrative?: number;
  clientPriority?: number;
  clientUnsecured?: number;
  clientTotal?: number;
  counselSecured?: number;
  counselAdministrative?: number;
  counselPriority?: number;
  counselUnsecured?: number;
  counselTotal?: number;
  highSecured?: number;
  highAdministrative?: number;
  highPriority?: number;
  highUnsecured?: number;
  highTotal?: number;
  lowSecured?: number;
  lowAdministrative?: number;
  lowPriority?: number;
  lowUnsecured?: number;
  lowTotal?: number;
  PORSecured?: number;
  PORAdministrative?: number;
  PORPriority?: number;
  PORUnsecured?: number;
  PORTotal?: number;
  parentClaimNumber?: string;

  currentContingent?: boolean;
  currentUnliquidated?: boolean;
  currentUndetermined?: boolean;
  currentDisputed?: boolean;
  proposedContingent?: boolean;
  proposedUnliquidated?: boolean;
  proposedUndetermined?: boolean;
  proposedDisputed?: boolean;
  alixPartnersContingent?: boolean;
  alixPartnersUnliquidated?: boolean;
  alixPartnersUndetermined?: boolean;
  alixPartnersDisputed?: boolean;
  clientContingent?: boolean;
  clientUnliquidated?: boolean;
  clientUndetermined?: boolean;
  clientDisputed?: boolean;
  counselContingent?: boolean;
  counselUnliquidated?: boolean;
  counselUndetermined?: boolean;
  counselDisputed?: boolean;
  PORContingent?: boolean;
  PORUnliquidated?: boolean;
  PORUndetermined?: boolean;
  PORDisputed?: boolean;

  majorCategoryId?: number;
  minorCategoryId?: number;

  customString01: string;
  customString02: string;
  customString03: string;
  customString04: string;
  customString05: string;
  customString06: string;
  customString07: string;
  customString08: string;
  customString09: string;
  customString10: string;
  customString11: string;
  customString12: string;
  customString13: string;
  customString14: string;
  customString15: string;
  customDecimal01?: number;
  customDecimal02?: number;
  customDecimal03?: number;
  customDecimal04?: number;
  customDecimal05?: number;
  customDecimal06?: number;
  customDecimal07?: number;
  customDecimal08?: number;
  customDecimal09?: number;
  customDecimal10?: number;
  customDecimal11?: number;
  customDecimal12?: number;
  customDecimal13?: number;
  customDecimal14?: number;
  customDecimal15?: number;

  customBoolean01?: boolean;
  customBoolean02?: boolean;
  customBoolean03?: boolean;
  customBoolean04?: boolean;
  customBoolean05?: boolean;

  customDateTime01?: Date;
  customDateTime02?: Date;
  customDateTime03?: Date;
  customDateTime04?: Date;
  customDateTime05?: Date;

  referenceNumber: string;
  actionStatus: ActionStatus;
  counterpartyName: string;
  matchCode: number;
  subMatchCode: number;
  numberOfMatches?: number;
  notMatched: boolean;
  hasOneHundredPercentMatch: boolean;
  oneHundredPercentMatchCode?: string;
  createdDate?: string;
  sourceType?: string;
  majorCategory?: string;
  minorCategory?: string;
  currentDebtor?: string;

  // ClaimRow fields
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  email?: string;
  phone?: string;
  originalDebtor?: string;
  proposedDebtor?: string;
  clientDebtor?: string;
  counselDebtor?: string;
  pORDebtor?: string;
  barDateRef?: string;
  claimScheduleNumber?: string;
  scheduledDescription?: string;
  claimsAgentNumber?: string;
  redactImage?: boolean;
  claimImage?: string;
  underSeal?: boolean;
  officialClaimDate?: string;
  dateRecdFromClaimsAgent?: string;
  originalSecured?: number;
  originalAdministrative?: number;
  originalUnsecured?: number;
  originalTotal?: number;
  pORSecured?: number;
  pORAdministrative?: number;
  pORPriority?: number;
  pORUnsecured?: number;
  pORTotal?: number;
  originalContingent?: boolean;
  originalUnliquidated?: boolean;
  originalDisputed?: boolean;
  originalUndetermined?: boolean;
  pORContingent?: boolean;
  pORUnliquidated?: boolean;
  pORDisputed?: boolean;
  pORUndetermined?: boolean;
  reviewLevel?: number;
  reviewLevelId?: number;
  survivingClaimNumber?: string;
  _fetchTimestamp?: string;
} & ClaimActionFields;

export enum SourceType {
  FiledClaim = 'FiledClaim',
  ScheduledClaim = 'ScheduledClaim',
}

export interface CounterpartySummaryAmounts {
  sourceType: SourceType;
  count: number;
  originalSecured: number;
  originalAdmin: number;
  originalPriority: number;
  originalUnsecured: number;
  originalTotal: number;
  currentSecured: number;
  currentAdmin: number;
  currentPriority: number;
  currentUnsecured: number;
  currentTotal: number;
  proposedSecured: number;
  proposedAdmin: number;
  proposedPriority: number;
  proposedUnsecured: number;
  proposedTotal: number;
}

export interface CounterpartySummary {
  matchCode: number;
  counterPartyName: string;
  counterpartySummaryAmounts: CounterpartySummaryAmounts[];
}

export type ClaimBatchEditResponse = {
  id: number;
  status: ClaimBatchEditStatus;
  claimsUpdated: number;
};

export enum ClaimBatchEditStatus {
  Failed = 'Failed',
  Success = 'Success',
  Pending = 'Pending',
}

export type BatchEditPostBackground = {
  displayName: string;
  fileName: string;
  directoryName: string;
  containerName: string;
};

export enum BatchEditBackgroundResponseType {
  Success = 1,
  Failed = 2,
}

export type BatchEditBackgroundResponse = {
  id: number;
  status: 'InProgess' | 'Completed' | 'Failed' | 'Queued';
  data: {
    claimsUpdated?: number;
    contractsUpdated?: number;
    id: number;
    status: BatchEditBackgroundResponseType;
  };
};

export const claims = {
  getCounterpartySummary: (matchCode: number) =>
    get<CounterpartySummary>(`v1/CounterpartySummary/${matchCode}`),
  edit: (data: ClaimEditData) => put('v1/Claims/edit', data),
  warnings: (data: ClaimEditData) => post<string[]>('v1/Claims/warnings', data),
  batchEdit: (data: ClaimBatchData) => post('v1/Claims/batchEdit', data),
  postBatchEditDoc: async (data: FormData) =>
    postUploadFile<ClaimBatchEditResponse>('v1/Claims/batchEditUpload', data),
  downloadErrorLogs: async (claimBatchEditFileId: number) =>
    await downloadFile(
      `v1/Claims/batchEditUploadErrors?claimBatchUpdateFileId=${claimBatchEditFileId}`,
      'ErrorLog.xlsx',
    ),
  batchEditUploadBackground: async (data: BatchEditPostBackground) =>
    post<{ jobId: number; statusEndpoint: string }>(
      'v1/Claims/batchEditUpload/background',
      data,
    ),
};
