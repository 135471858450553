import classNames from 'classnames';

import {
  ApThreeWaySwitch,
  ApThreeWayValue,
} from 'components/ApThreeWayToggleSwitch';
import { ApButtonCasual, ApButtonMainEx } from 'components/UIComponents/Buttons';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { SubheaderSearch } from 'pages/ClaimsContractsToolPage/components/SubheaderSearch';
import { ExportExcelArgs } from 'pages/ClaimsContractsToolPage/types';
import { Dispatch, SetStateAction } from 'react';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import './ClaimsAllSubheader.css';

export interface IClaimsToolSubheaderProps {
  searchText: string;
  onSearchChanged: (text: string) => void;
  showMineClaims: boolean;
  onShowMineClaimsChange: (value: boolean) => void;
  claimsCount?: number;
  onBatchClick: () => unknown;
  onExportClaims?: (params: ExportExcelArgs) => void;
  isColumnSelectionOpen: boolean;
  setColumnSelection: Dispatch<SetStateAction<boolean>>;
  disableResetFilters: boolean;
  onResetFilters?: () => unknown;
}

export const ClaimsAllSubheader = ({
  searchText,
  claimsCount,
  showMineClaims,
  onShowMineClaimsChange,
  onBatchClick,
  isColumnSelectionOpen,
  setColumnSelection,
  disableResetFilters,
  onResetFilters,
  onSearchChanged,
}: IClaimsToolSubheaderProps) => {
  const { isApUser } = useAppUserType();
  return (
    <div className="claims-tool-subheader">
      <SubheaderSearch
        count={claimsCount}
        type="claims"
        value={searchText}
        onSearchChanged={onSearchChanged}
      />

      <div className="claims-tool-subheader__menu">
        <ApThreeWaySwitch
          value={showMineClaims ? ApThreeWayValue.Yes : ApThreeWayValue.No}
          label="Display only my claims"
          onChange={(value) => {
            onShowMineClaimsChange(value === ApThreeWayValue.Yes);
          }}
          disabled={!showMineClaims && !claimsCount}
        />

        {isApUser && (
          <ApButtonMainEx
            thinShape
            iconName="outline_list_alt"
            onClick={onBatchClick}
          >
            Batch edit claims
          </ApButtonMainEx>
        )}
        <ApButtonCasual
          thinShape
          disabled={disableResetFilters}
          onClick={onResetFilters}
          className="custom-icon-btn"
        >
          <SvgIcon iconName="reset" />
          Reset Filters
        </ApButtonCasual>
        <ApButtonCasual
          onClick={() => setColumnSelection((v) => !v)}
          className={classNames('claims-tool-header__columns custom-icon-btn', {
            isOpen: isColumnSelectionOpen,
          })}
        >
          <SvgIcon iconName={isColumnSelectionOpen ? 'cancel_big' : 'columns'} />
          Columns
        </ApButtonCasual>
      </div>
    </div>
  );
};
