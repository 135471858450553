export const SCHEDULE_D_1_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Creditor Name',
      propertyName: 'creditorName',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Creditor Email',
      propertyName: 'creditorEMail',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Date Debt Incurred',
      propertyName: 'dateDebtIncurred',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Last 4 Acc Number',
      propertyName: 'last4AccNumber',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Specify Other Creditors - Name and Relative Priority',
      propertyName: 'otherCreditors',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'The relative priority of creditors is specified on lines',
      propertyName: 'relativePriorityOfCreditors',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Description of debtors property to a lien',
      propertyName: 'descriptionOfDebtorsPropertyToALien',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Describe the Lien',
      propertyName: 'describeTheLein',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Insider or Related Party',
      propertyName: 'insiderOrRelatedParty',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Others Liable on Claim',
      propertyName: 'othersLiableOnClaim',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Contingent',
      propertyName: 'contingent',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Unliquidated',
      propertyName: 'unliquidated',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Disputed',
      propertyName: 'disputed',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Amount of Claim',
      propertyName: 'amountOfClaim',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Value of Collateral',
      propertyName: 'valueOfCollateral',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Category',
      propertyName: 'category',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
