import { ApButtonMain, ApMenu, ApMenuItem } from '@alixpartners/ui-components';
import cs from 'classnames';
import { ApButtonCasual } from 'components/UIComponents/Buttons/ApButtonCasual';
import { useState } from 'react';
import { SvgIcon } from '../UIComponents/SvgIcon';
import './DropdownMenu.css';

type ChildrenType = React.ReactElement<typeof ApMenuItem>;

interface IDropdownProps extends React.ComponentProps<typeof ApButtonMain> {
  label: string;
  disableCloseOnSubmenuClick?: boolean;
  className?: string;
  buttonClassname?: string;
  thinShape?: boolean;
  children: ChildrenType | ChildrenType[];
  usePrimaryButton?: boolean;
}

const DEFAULT_HEIGHT = 6;
const DEFAULT_WIDTH = 8;

export const DropdownMenuSecondary = ({
  label,
  iconName,
  className,
  buttonClassname,
  children,
  disableCloseOnSubmenuClick,
  disabled,
  usePrimaryButton,
}: IDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ApMenu
      isOpen={isOpen || disableCloseOnSubmenuClick}
      toggleMenu={setIsOpen}
      className={className}
      placement="bottom-end"
      button={
        <ApButtonCasual
          autoFocus={false}
          disabled={!!disabled}
          theme="light"
          className={cs('dropdown-button', buttonClassname)}
          iconName={iconName}
          thinShape
        >
          <span className="dropdown-button__label">{label}</span>
          <span className="dropdown-button__arrow">
            <SvgIcon
              iconName={isOpen ? 'arrow_up' : 'arrow_down'}
              height={DEFAULT_HEIGHT}
              width={DEFAULT_WIDTH}
            />
          </span>
        </ApButtonCasual>
      }
      small
    >
      {children}
    </ApMenu>
  );
};
