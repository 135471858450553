import { ApButtonMain } from '@alixpartners/ui-components';
import classNames from 'classnames';
import './ClaimsGoBackButton.css';

export const ClaimsGoBackButton = ({
  className,
  ...props
}: React.ComponentProps<typeof ApButtonMain>) => {
  return (
    <ApButtonMain
      className={classNames('claims-goback-button', className)}
      iconName="baseline_arrow_back"
      medium
      {...props}
    >
      Go Back
    </ApButtonMain>
  );
};
