import { CONTRACT_STATUSES } from 'pages/SingleContractPage/sections/SingleContractOverview/components/CategorySelect/config';
import { ContractPaymentPositionsData } from 'reducer/contractPaymentPositionsReducer';
import { ContractReviewerData } from 'reducer/contractReviewerReducer';
import {
  ContractReviewersBatchItem,
  ReviewersBatchItem,
} from '../../components/ReviewersEdit/ReviewersEdit.utils';
import { downloadFile, get, post, put } from '../apiRequest';
import { formatDate, ISO_DATE } from 'utils/formatDate';
import { BatchEditPostBackground } from './claims';

export enum ContractActionStatus {
  ScheduledContract = 'ScheduledContract',
  FiledContract = 'FiledContract',
  Categorized = 'Categorized',
  MatchingComplete = 'MatchingComplete',
  MatchesFound = 'MatchesFound',
  Matched = 'Matched',
  Assigned = 'Assigned',
  ClientReconciled = 'ClientReconciled',
  AlixReconciled = 'AlixReconciled',
  ObjectionPending = 'ObjectionPending',
  ObjectionFiled = 'ObjectionFiled',
  ObjectionContinued = 'ObjectionContinued',
  ObjectionOrderedPartial = 'ObjectionOrderedPartial',
  ObjectionOrderedFinal = 'ObjectionOrderedFinal',
  Withdrawn = 'Withdrawn',
  SettlementPending = 'SettlementPending',
  SettlementPartial = 'SettlementPartial',
  SettlementFinal = 'SettlementFinal',
  Voided = 'Voided',
  TrumpedSchedule = 'TrumpedSchedule',
  ZeroDollarValueScheduledOnly = 'ZeroDollarValueScheduledOnly',
  Allowable = 'Allowable',
  Allowed = 'Allowed',
  OrderVacated = 'OrderVacated',
}

export type ContractActionFields = {
  // The name of the actionName. It should be actionNameName ideally/correctly,
  actionName?: string;
  actionExhibitTypeName?: string;
  actionSubStatusName?: string;
} & ContractActionIdFields;

export interface ContractRow {
  id: number;
  referenceNumber: string;
  category: string;
  contractType: string;
  sourceType?: string;
  vendorNumber: string;
  companyCode: string;
  oneTimeSavings: number;
  twelveMonthSavings: number;
  fYSpend: number;
  lastTwelveMonthsSpend: number;
  pORefOnInvoice: string;
  rejectionDamageLow: number;
  rejectionDamagesHigh: number;
  debtor: string;
  primaryDebtor: string;
  assumptionCureAmount: number;
  contractValue: number;
  approvalStatus: string;
  nonDebtorGuarantee: boolean;
  totalMatchPrePetitionValue: number;
  dateInvoiceReceived: string;
  contractDate: string;
  negotiatedCureAmount: number;
  cureCostReductionAmount: number;
  contractSavingsNotes: string;
  expirationDate: string;
  approvalDate: string;
  aPApprovalDate: string;
  rejectionDate: string;
  autoRenewal: boolean;
  totalMatchPrePetitionContractValue: number;
  counterpartyName: string;
  counterpartyNames: string;
  description: string;
  address1: string;
  address2: string;
  address3: string;
  address4: string;
  city: string;
  state: string;
  zip: string;
  country: string;
  priority: number;
  contractStatus: string;
  contractActionTypeName: string;
  contractActionSubStatusName: string;
  contractActionName: string;
  paymentPosition: string;
  contractExhibitTypeName: string;
  hasMultipleDebtors: boolean;
  hasMultipleCounterparties: boolean;
  hasMultipleMatchCodes: boolean;
  matchCode: number;
  matchCodes: number;
  customString01: string;
  customString02: string;
  customString03: string;
  customString04: string;
  customString05: string;
  customString06: string;
  customString07: string;
  customString08: string;
  customString09: string;
  customString10: string;
  customString11: string;
  customString12: string;
  customString13: string;
  customString14: string;
  customString15: string;
  customDecimal01: number;
  customDecimal02: number;
  customDecimal03: number;
  customDecimal04: number;
  customDecimal05: number;
  customDecimal06: number;
  customDecimal07: number;
  customDecimal08: number;
  customDecimal09: number;
  customDecimal10: number;
  customDecimal11: number;
  customDecimal12: number;
  customDecimal13: number;
  customDecimal14: number;
  customDecimal15: number;
  customBoolean01: boolean;
  customBoolean02: boolean;
  customBoolean03: boolean;
  customBoolean04: boolean;
  customBoolean05: boolean;
  customDateTime01: string;
  customDateTime02: string;
  customDateTime03: string;
  customDateTime04: string;
  customDateTime05: string;
  notes: string;
  lessor: string;
  realPropertyLease: string;
  leasedPropertyAddress1: string;
  leasedPropertyAddress2: string;
  leasedPropertyAddress3: string;
  leasedPropertyCity: string;
  leasedPropertyState: string;
  leasedPropertyZip: string;
  leasedPropertyCountry: string;
  actionName: string;
  actionSubStatusName: string;
  parentContractNumber?: string;
  isPrimary?: boolean;
}

export interface ContractBatchData {
  contractIds: number[];
  values: {
    contractCategoryId: number;
    contractTypeId: number;
    contractStatusId: number;
    contractActionTypeId: number;
    contractActionSubStatusId: number;
    contractActionNameId: number;
    paymentPositionId: number;
    vendorNumber: number;
    companyCode: number;
    proposedDebtorId: number;
    contractReviewer: ContractReviewersBatchItem;
  };
}

export type ContractEditChangeOptionsMarkFlags = {
  markContractAsReconciled?: boolean;
  markContractReviewAsComplete?: boolean;
  markContractAsAssigned?: boolean;
  markContractAsCategorized?: boolean;
  markContractAsReviewComplete?: boolean;
  markContractAsFiled?: boolean;
  markContractAsOrdered?: boolean;
  markContractAsSettledAndResolved?: boolean;
};

export type ContractEditChangeOptions = {
  comment: string;
} & ContractEditChangeOptionsMarkFlags;

export type LabeledContractEditChangeOptionsMarkFlag = {
  label: string;
  changeOptionsMarkFlag: keyof ContractEditChangeOptionsMarkFlags;
};

export interface ContractEditData {
  contractId: number;
  values: ContractEditValues;
  changeOptions: ContractEditChangeOptions;
}
export interface ContractCategoryItem {
  id: number;
  category: string;
}

export interface ContractTypeItem {
  id: number;
  contractType: string;
  contractCategoryId: number;
}

export interface ContractStatuses {
  id: number;
  contractStatus: string;
}

export type ContractActionIdFields = {
  contractActionNameId?: number;
  contractActionTypeId?: number;
  contractActionSubStatusId?: number;
};

export type ContractEditValues = {
  id: number;
  originalDebtorId?: number;
  currentDebtorId?: number;
  proposedDebtorId?: number;
  alixPartnersDebtorId?: number;
  clientDebtorId?: number;
  counselDebtorId?: number;
  pORDebtorId?: number;
  contractReviewer: ReviewersBatchItem;
  currentSecured?: number;
  currentAdministrative?: number;
  currentPriority?: number;
  currentUnsecured?: number;
  contractValue?: number;
  proposedSecured?: number;
  proposedAdministrative?: number;
  proposedPriority?: number;
  proposedUnsecured?: number;
  proposedTotal?: number;
  alixPartnersSecured?: number;
  alixPartnersAdministrative?: number;
  alixPartnersPriority?: number;
  alixPartnersUnsecured?: number;
  alixPartnersTotal?: number;
  clientSecured?: number;
  clientAdministrative?: number;
  clientPriority?: number;
  clientUnsecured?: number;
  clientTotal?: number;
  counselSecured?: number;
  counselAdministrative?: number;
  counselPriority?: number;
  counselUnsecured?: number;
  counselTotal?: number;
  highSecured?: number;
  highAdministrative?: number;
  highPriority?: number;
  highUnsecured?: number;
  highTotal?: number;
  lowSecured?: number;
  lowAdministrative?: number;
  lowPriority?: number;
  lowUnsecured?: number;
  lowTotal?: number;
  PORSecured?: number;
  PORAdministrative?: number;
  PORPriority?: number;
  PORUnsecured?: number;
  PORTotal?: number;
  lastTwelveMonthsSpend?: number;
  lID?: number;

  contractStatus?: string;
  contractStatusId?: number;
  contractActionTypeId?: number;
  contractActionTypeName?: string;
  contractActionSubStatusId?: number;
  contractActionNameId?: number;
  paymentPositionId?: number;
  contractExhibitTypeId?: number;
  contractCategoryId?: number;

  currentContingent?: boolean;
  currentUnliquidated?: boolean;
  currentUndetermined?: boolean;
  currentDisputed?: boolean;
  proposedContingent?: boolean;
  proposedUnliquidated?: boolean;
  proposedUndetermined?: boolean;
  proposedDisputed?: boolean;
  alixPartnersContingent?: boolean;
  alixPartnersUnliquidated?: boolean;
  alixPartnersUndetermined?: boolean;
  alixPartnersDisputed?: boolean;
  clientContingent?: boolean;
  clientUnliquidated?: boolean;
  clientUndetermined?: boolean;
  clientDisputed?: boolean;
  counselContingent?: boolean;
  counselUnliquidated?: boolean;
  counselUndetermined?: boolean;
  counselDisputed?: boolean;
  PORContingent?: boolean;
  PORUnliquidated?: boolean;
  PORUndetermined?: boolean;
  PORDisputed?: boolean;

  customString01: string;
  customString02: string;
  customString03: string;
  customString04: string;
  customString05: string;
  customString06: string;
  customString07: string;
  customString08: string;
  customString09: string;
  customString10: string;
  customString11: string;
  customString12: string;
  customString13: string;
  customString14: string;
  customString15: string;
  customDecimal01?: number;
  customDecimal02?: number;
  customDecimal03?: number;
  customDecimal04?: number;
  customDecimal05?: number;
  customDecimal06?: number;
  customDecimal07?: number;
  customDecimal08?: number;
  customDecimal09?: number;
  customDecimal10?: number;
  customDecimal11?: number;
  customDecimal12?: number;
  customDecimal13?: number;
  customDecimal14?: number;
  customDecimal15?: number;

  customBoolean01?: boolean;
  customBoolean02?: boolean;
  customBoolean03?: boolean;
  customBoolean04?: boolean;
  customBoolean05?: boolean;

  customDateTime01?: Date;
  customDateTime02?: Date;
  customDateTime03?: Date;
  customDateTime04?: Date;
  customDateTime05?: Date;

  referenceNumber: string;
  actionStatus: ContractActionStatus;
  counterpartyName: string;
  matchCode: number;
  subMatchCode: number;
  numberOfMatches?: number;
  notMatched: boolean;
  hasOneHundredPercentMatch: boolean;
  oneHundredPercentMatchCode?: string;
  contractDate?: string;
  sourceType?: string;
  debtor?: string;
  primaryDebtor?: string;
  approvalStatus?: string;

  // ContractRow fields
  address1?: string;
  address2?: string;
  address3?: string;
  address4?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  email?: string;
  phone?: string;
  originalDebtor?: string;
  proposedDebtor?: string;
  clientDebtor?: string;
  counselDebtor?: string;
  pORDebtor?: string;
  barDateRef?: string;
  scheduledDescription?: string;
  contractsAgentNumber?: string;
  redactImage?: boolean;
  contractImage?: string;
  underSeal?: boolean;
  officialContractDate?: string;
  dateRecdFromContractsAgent?: string;
  originalSecured?: number;
  originalAdministrative?: number;
  originalUnsecured?: number;
  originalTotal?: number;
  pORSecured?: number;
  pORAdministrative?: number;
  pORPriority?: number;
  pORUnsecured?: number;
  pORTotal?: number;
  originalContingent?: boolean;
  originalUnliquidated?: boolean;
  originalDisputed?: boolean;
  originalUndetermined?: boolean;
  pORContingent?: boolean;
  pORUnliquidated?: boolean;
  pORDisputed?: boolean;
  pORUndetermined?: boolean;
  survivingContractNumber?: string;
  _fetchTimestamp?: string;
  parentContractNumber?: string;
} & ContractActionFields;

export interface ContractCounterpartySummaryAmounts {
  count: number;
  originalSecured: number;
  originalAdmin: number;
  originalPriority: number;
  originalUnsecured: number;
  originalTotal: number;
  currentSecured: number;
  currentAdmin: number;
  currentPriority: number;
  currentUnsecured: number;
  currentTotal: number;
  proposedSecured: number;
  proposedAdmin: number;
  proposedPriority: number;
  proposedUnsecured: number;
  proposedTotal: number;
}

export interface ContractCounterpartySummary {
  matchCode: number;
  counterPartyName: string;
  counterpartySummaryAmounts: ContractCounterpartySummaryAmounts[];
}

export type ContractBatchEditResponse = {
  id: number;
  status: ContractBatchEditStatus;
  contractsUpdated: number;
};

export enum ContractBatchEditStatus {
  Failed = 'Failed',
  Success = 'Success',
  Pending = 'Pending',
}

export const scheduleGReport = 'exportscheduledg';

export const contracts = {
  getCounterpartySummary: (matchCode: number) =>
    get<ContractCounterpartySummary>(
      `v1/Contracts/getCounterpartySummary?matchCode=${matchCode}`,
    ),
  edit: (data: ContractEditData) => put('v1/Contracts/edit', data),
  batchEdit: (data: ContractBatchData) => post('v1/Contracts/batchEdit', data),
  getContractsCategories: () => get<ContractCategoryItem[]>('v1/ContractCategories'),
  getContractTypes: () => get<ContractTypeItem[]>('v1/ContractTypes'),
  staticGetStatuses: () => [...CONTRACT_STATUSES],
  getContractReviewers: (id: number) =>
    get<ContractReviewerData>(`v1/ContractReviewers?contractId=${id}`),
  getPaymentPositions: () =>
    get<ContractPaymentPositionsData[]>(`/v1/PaymentPositions`),
  downloadErrorLogs: async (fileId: number) =>
    await downloadFile(
      `v1/Contracts/batchEditUploadErrors?contractBatchUpdateFileId=${fileId}`,
      'ErrorLog.xlsx',
    ),
  downloadScheduleG: async () =>
    await downloadFile(
      `v1/Contracts/${scheduleGReport}`,
      `ScheduleG-${formatDate(new Date(), ISO_DATE)}.xlsx`,
    ),
  batchEditUploadBackground: async (data: BatchEditPostBackground) =>
    post<{ jobId: number; statusEndpoint: string }>(
      'v1/Contracts/batchEditUpload/background',
      data,
    ),
};
