import classNames from 'classnames';
import {
  ApThreeWaySwitch,
  ApThreeWayValue,
} from 'components/ApThreeWayToggleSwitch';
import { ApButtonCasual, ApButtonMainEx } from 'components/UIComponents/Buttons';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { SubheaderSearch } from 'pages/ClaimsContractsToolPage/components/SubheaderSearch';
import { ExportExcelArgs } from 'pages/ClaimsContractsToolPage/types';
import { Dispatch, SetStateAction } from 'react';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import './ContractsAllSubheader.css';

export interface IContractsToolSubheaderProps {
  searchText: string;
  onSearchChanged: (text: string) => void;
  showMineContracts: boolean;
  onShowMineContractsChange: (value: boolean) => void;
  contractsCount?: number;
  onBatchClick: () => unknown;
  onExportContracts?: (params: ExportExcelArgs) => void;
  isColumnSelectionOpen: boolean;
  setColumnSelection: Dispatch<SetStateAction<boolean>>;
  disableResetFilters: boolean;
  onResetFilters?: () => unknown;
}

export const ContractsAllSubheader = ({
  searchText,
  onSearchChanged,
  contractsCount,
  showMineContracts,
  onShowMineContractsChange,
  onBatchClick,
  isColumnSelectionOpen,
  setColumnSelection,
  disableResetFilters,
  onResetFilters,
}: IContractsToolSubheaderProps) => {
  const { isApUser } = useAppUserType();

  return (
    <div className="contracts-tool-subheader">
      <SubheaderSearch
        count={contractsCount}
        type="contracts"
        value={searchText}
        onSearchChanged={onSearchChanged}
      />

      <div className="contracts-tool-subheader__menu">
        {isApUser && (
          <ApThreeWaySwitch
            value={showMineContracts ? ApThreeWayValue.Yes : ApThreeWayValue.No}
            label="Display only my contracts"
            onChange={(value) => {
              onShowMineContractsChange(value === ApThreeWayValue.Yes);
              return;
            }}
            disabled={!showMineContracts && !contractsCount}
          />
        )}
        {isApUser && (
          <ApButtonMainEx
            thinShape
            iconName="outline_list_alt"
            onClick={onBatchClick}
          >
            Batch edit contracts
          </ApButtonMainEx>
        )}
        <ApButtonCasual
          thinShape
          disabled={disableResetFilters}
          onClick={onResetFilters}
          className="custom-icon-btn"
        >
          <SvgIcon iconName="reset" />
          Reset Filters
        </ApButtonCasual>
        <ApButtonCasual
          onClick={() => setColumnSelection((v) => !v)}
          className={classNames('claims-tool-header__columns custom-icon-btn', {
            isOpen: isColumnSelectionOpen,
          })}
        >
          <SvgIcon iconName={isColumnSelectionOpen ? 'cancel_big' : 'columns'} />
          Columns
        </ApButtonCasual>
      </div>
    </div>
  );
};
