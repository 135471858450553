export const SCHEDULE_AB_2_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: ['Deposit', 'Prepayment'],
      dataType: 'enum',
      displayName: 'Deposit / Prepayment',
      propertyName: 'assetValueTerm',
      queryType: 'ScheduleAssetValueTerm'
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Description',
      propertyName: 'description',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Value',
      propertyName: 'value',
    },
    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
