import { ClaimRow } from 'api/endpoints';
import { ActionName } from 'api/endpoints/action-names';
import { createApolloClient } from 'api/graphql';
import Excel from 'exceljs';
import _, { isNil, keyBy } from 'lodash';

import { IExhibitState } from 'reducer/exhibits/exhibitReducer';
import { splitCommaSeparatedUniq } from 'utils/commaSeparatedText';
import { getSurvivingClaims } from '../utils/apiExhibitClaim';
import { getClaimNumber } from '../utils/field';
import { IExcelCreator } from './excelExporter';
import {
  createTotalRowStyle,
  defCellStyle,
  defPositionNumberStyle,
} from './utils/excelConst';
import {
  createExcelWorkbook,
  ExcelDocumentHeader,
} from './utils/excelCreateWorkbook';
import { excelAddPageHeader, HeaderPositionCell } from './utils/excelHeader';
import { titleGroupStyle, titleStyle } from './utils/excelSheetStyle';
import {
  deleteObjectionCommentaryColumn,
  excelAddPageFooter,
  excelAddRow,
  excelAddTableHeaderRow,
  excelAddTotalRow,
} from './utils/excelUtils';
import {
  createClaimAmount,
  createHeaderFooter,
  getAddressLine,
  getSurvivingTotalCurrency,
  getTotalCurrency,
} from './utils/exporterUtils';
import { ClaimsWithSurviving, IExhibitWorkbookData, IRowValues, TRowStyle } from './utils/type';

const COLUMN_LIST: ExcelDocumentHeader[] = [
  { header: '', width: 3 },
  { header: 'Name and address of claimant', width: 23.2 },
  { header: 'Claim #', width: 6 },
  { header: 'Debtor', width: 12 },
  { header: 'Claim amount and priority (1)', width: 11.5 },
  { header: 'Basis for objection', width: 10 },
  { header: '', width: 2 },
  { header: 'Name and address of claimant', width: 23.2 },
  { header: 'Claim #', width: 6 },
  { header: 'Debtor', width: 12 },
  { header: 'Claim amount and priority (1)', width: 11.5 },
  { header: 'Comments', width: 11 },
];

const valuesStyle: TRowStyle = [
  { ...defPositionNumberStyle }, // 'positionNumber'
  { ...defCellStyle }, // 'disNameAdress'
  { ...defCellStyle, alignment: { horizontal: 'center', vertical: 'top' } }, // 'disClaimId'
  { ...defCellStyle }, // 'disDebtor'
  {
    ...defCellStyle,
    numFmt: '"£"#,##0.00',
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  }, // 'disClaimAmount'
  { ...defCellStyle }, // 'distBasisForObj'
  { ...defCellStyle }, // ''
  { ...defCellStyle }, // 'surNameAdress'
  { ...defCellStyle, alignment: { horizontal: 'center', vertical: 'top' } }, // 'surClaimId'
  { ...defCellStyle }, // 'surDebtor'
  {
    ...defCellStyle,
    numFmt: '[$$-409]#,##0.00',
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  }, // 'surClaimAmount'
  { ...defCellStyle }, //'objectionCommentary',
];

const tableTitleStyle = {
  ...titleStyle,
  border: { ...titleStyle.border, top: {} },
};

const valuesTotalStyle = createTotalRowStyle(valuesStyle.length);

const objectionColId = 12;

const PAGE_HEADER_POS: HeaderPositionCell = {
  left: 1,
  middle: 4,
  right: 8,
  length: 12,
};

export const templateExpungeWithSurviving: IExcelCreator = {
  fileName: 'Expunge_WithSurviving.xlsx',
  getWorkbook: async (data: IExhibitState, action: ActionName) => {
    const excelData = await getDataToShow(data, action);
    return await createWorkbook(excelData, data.displayObjection);
  },
};

async function createWorkbook(
  data: IExhibitWorkbookData,
  displayObjection: boolean | undefined,
): Promise<Excel.Workbook> {
  const workbook = createExcelWorkbook('Sheet1', COLUMN_LIST);
  const firstSheet = workbook.getWorksheet(1);

  excelAddPageHeader(firstSheet, data.headerFooter.header, PAGE_HEADER_POS);
  excelAddSurvivalHeaderGroup(firstSheet);
  excelAddTableHeaderRow(firstSheet, COLUMN_LIST, tableTitleStyle);
  excelAddPageFooter(firstSheet, data.headerFooter);

  data.claimsList.forEach((row) => excelAddRow(firstSheet, row, valuesStyle));
  excelAddTotalRow(firstSheet, data.totalRow, valuesTotalStyle);

  deleteObjectionCommentaryColumn(firstSheet, displayObjection, objectionColId);
  return workbook;
}

async function getDataToShow(
  data: IExhibitState,
  action: ActionName,
): Promise<IExhibitWorkbookData> {
  const { structureFooterOptions } = data;
  const apolloClient = await createApolloClient();

  // here is a bug. `r.value` is string type. But in real it contains an integer value
  const showClaimCount = !!structureFooterOptions.find((r) => r.value == '3'); // eslint-disable-line
  const survivingClaims = await getSurvivingClaims(apolloClient, data.claims);

  const claimsWithSurviving = getClaimsWithSurviving(data.claims, survivingClaims);

  const claimsList = getPreparedClaimsData(
    claimsWithSurviving,
    data.displayObjection,
    data.objectionBasis,
  );

  return {
    headerFooter: createHeaderFooter(data, action),
    claimsList,
    totalRow: getTotalRow(claimsWithSurviving, showClaimCount),
  };
}

const getClaimsWithSurviving = (
  claims: ClaimRow[],
  survivingClaims: ClaimRow[],
): ClaimsWithSurviving[] => {
  const survivingDict = keyBy(survivingClaims, 'referenceNumber');
  const claimsList = claims.map((claim) => {
    const survivingClaimIds = splitCommaSeparatedUniq(claim.survivingClaimNumber);
    const survivingClaims = _(survivingClaimIds)
      .map((r) => survivingDict[r])
      .compact()
      .value();

    return {
      claim,
      survivingClaims,
    };
  });

  return claimsList;
};

const getPreparedClaimsData = (
  claims: ClaimsWithSurviving[],
  displayObjection: boolean | undefined,
  objectionBasis: string,
) => {
  return claims.flatMap((c, index) => {
    const [firstSurviving, ...restSurviving] = c.survivingClaims;
    const commentary = displayObjection ? c.claim.objectionCommentary : '';
    const extra = { index, objectionBasis, commentary };
    const values = getRowItems(c.claim, firstSurviving, extra);
    const additional = restSurviving.map((r) => getRowItems(undefined, r));

    return { values, additional } as IRowValues;
  });
};

function getTotalRow(claims: ClaimsWithSurviving[], showClaimCount: boolean) {
  const allClaims = claims.map(c => c.claim);
  const values = [
    '', // positionNumber
    'Total', // disNameAddress
    '', // disClaimId
    showClaimCount ? `Count: ${claims.length}` : '', // disDebtor
    getTotalCurrency(allClaims, 'currentTotal'), // disClaimAmount
    '', // distBasisForObj
    '', // empty cell
    '', // surNameAddress
    '', // surClaimId
    '', // surDebtor
    getSurvivingTotalCurrency(claims, 'currentTotal'), // surClaimAmount
  ];

  return { values } as IRowValues;
}

function getRowItems(
  claim: ClaimRow | undefined,
  surviving: ClaimRow | undefined,
  extra?: {
    index?: number;
    objectionBasis?: string;
    objectionCommentary?: string;
  },
) {
  const { index, objectionBasis, objectionCommentary } = extra ?? {};

  return [
    !isNil(index) ? index + 1 : '', //positionNumber
    getAddressLine(claim), //disNameAddress
    getClaimNumber(claim), //disClaimId
    claim?.currentDebtor, //disDebtor
    createClaimAmount(claim), //disClaimAmount
    objectionBasis, //distBasisForObj
    '', // empty cell
    getAddressLine(surviving), //surNameAddress
    getClaimNumber(surviving), //surClaimId
    surviving?.currentDebtor ?? '', //surDebtor
    createClaimAmount(surviving), //surClaimAmount
    objectionCommentary ?? '', //objectionCommentary
  ];
}

const excelAddSurvivalHeaderGroup = (sheet: Excel.Worksheet) => {
  const groupRow = sheet.addRow([]);
  const id = sheet.rowCount;

  const group1 = groupRow.getCell(2);
  group1.value = 'CLAIMS TO BE DISALLOWED AND EXPUNGED';
  group1.style = titleGroupStyle;
  sheet.mergeCells(`B${id}:F${id}`);

  const group2 = groupRow.getCell(8);
  group2.value = 'SURVIVING CLAIMS';
  group2.style = titleGroupStyle;

  sheet.mergeCells(`H${id}:K${id}`);
};
