import {
  DeleteSection,
  FileType,
  IUploadSection,
  IUploadSectionHeaders,
  ModuleToDelete,
  PublishSection,
  SectionHeaders,
} from 'types/dataUploaderTypes';
import { Extension } from '../components/FileUploadPanel';

export const uploadSections: IUploadSection[] = [
  {
    id: '1',
    type: FileType.ScheduledClaims,
    caption: 'Scheduled Claims',
    requiredFiles: 0,
    header: SectionHeaders.Claims,
    moduleToDelete: ModuleToDelete.Claims,
    publishSection: PublishSection.Claims,
  },
  {
    id: '2',
    type: FileType.FiledClaims,
    caption: 'Filed Claims',
    requiredFiles: 0,
    header: SectionHeaders.Claims,
    moduleToDelete: ModuleToDelete.Claims,
    publishSection: PublishSection.Claims,
  },
  {
    id: '3',
    type: FileType.LegalEntity,
    caption: 'Legal Entities',
    description: `Attention: Uploading a new legal entity file will not alter the legal entity IDs associated with data already present on the platform. If you wish to update or modify a legal entity file, please follow these steps:

    - Delete the existing data from the platform.
    - Upload the new or amended legal entity file.
    - Re-upload your data.

    This process ensures that your data is accurately associated with the correct legal entity IDs.`,
    requiredFiles: 1,
    header: SectionHeaders.Global,
    publishSection: PublishSection.LegalEntities,
  },
  {
    id: '8',
    type: FileType.Sofa,
    caption: 'SOFA data',
    requiredFiles: 0,
    header: SectionHeaders.Sofas,
    moduleToDelete: ModuleToDelete.Sofas,
    publishSection: PublishSection.Sofas,
  },
  {
    id: '9',
    type: FileType.Schedule,
    caption: 'Schedule data',
    requiredFiles: 0,
    header: SectionHeaders.Sofas,
    moduleToDelete: ModuleToDelete.Schedules,
    publishSection: PublishSection.Schedules,
  },
  {
    id: '4',
    type: FileType.Category,
    caption: 'Claim Categories',
    requiredFiles: 0,
    header: SectionHeaders.Claims,
    moduleToDelete: ModuleToDelete.Claims,
    publishSection: PublishSection.Claims,
  },
  {
    id: '6',
    type: FileType.Contract,
    caption: 'Contracts',
    requiredFiles: 0,
    header: SectionHeaders.Contracts,
    moduleToDelete: ModuleToDelete.Contracts,
    publishSection: PublishSection.Contracts,
  },
  {
    id: '10',
    type: FileType.ContractCategory,
    caption: 'Contract Categories',
    requiredFiles: 0,
    header: SectionHeaders.Contracts,
    moduleToDelete: ModuleToDelete.Contracts,
    publishSection: PublishSection.Contracts,
  },
  {
    id: '15',
    type: FileType.Vendor,
    caption: 'Vendor IDs',
    requiredFiles: 0,
    header: SectionHeaders.Vendors,
    publishSection: PublishSection.Baseline,
  },
];

export const sectionHeaders: IUploadSectionHeaders[] = [
  {
    name: SectionHeaders.Global,
    title: 'Global Data (Required)',
    tooltip: 'The legal entity file must be uploaded to use any module',
  },
  {
    name: SectionHeaders.Sofas,
    title: 'Sofas and Schedules',
  },
  {
    name: SectionHeaders.Claims,
    title: 'Claims',
  },
  {
    name: SectionHeaders.Contracts,
    title: 'Contracts',
  },
  {
    name: SectionHeaders.Vendors,
    title: 'Vendors',
  },
];

export const HELP_SECTION_TEMPLATES_MAP = {
  [FileType.LegalEntity]: ['LegalEntityImport.xlsx'],
  [FileType.Sofa]: ['Sofa.xlsx', 'Sofa-v2.xlsx'],
  [FileType.Schedule]: [
    'Schedules-AB.xlsx',
    'Schedules-D.xlsx',
    'Schedules-EF.xlsx',
    'Schedules-G.xlsx',
    'Schedules-H.xlsx',
    'Schedules-Signature.xlsx',
  ],
  [FileType.ScheduledClaims]: ['ScheduledClaims.xlsx'],
  [FileType.FiledClaims]: ['FiledClaims.xlsx'],
  [FileType.Category]: ['ClaimCategory.xlsx'],
  [FileType.Contract]: ['Contract.xlsx'],
  [FileType.ContractCategory]: ['ContractCategory.xlsx'],
  [FileType.Vendor]: ['Vendor.xlsx'],
};

export const SCHEDULES_PARTS = [
  'Schedule AB',
  'Schedule D',
  'Schedule EF',
  'Schedule G',
  'Schedule H',
  'Signature',
];

export const DELETE_DATA_SECTIONS: DeleteSection[] = [
  {
    type: FileType.Sofa,
    label: 'SOFAs data',
    moduleToDelete: ModuleToDelete.Sofas,
  },
  {
    type: FileType.Schedule,
    label: 'Schedules data',
    moduleToDelete: ModuleToDelete.Schedules,
  },
  {
    type: FileType.Claims,
    label: 'Claims data',
    moduleToDelete: ModuleToDelete.Claims,
  },
  {
    type: FileType.Contracts,
    label: 'Contracts data',
    moduleToDelete: ModuleToDelete.Contracts,
  },
  {
    type: FileType.All,
    label: 'All data',
    moduleToDelete: ModuleToDelete.AllModules,
  },
];

export const CONTRACT_IMAGES_EXT: Extension[] = [
  '.xlsx',
  '.xls',
  '.pdf',
  '.docx',
  '.png',
  '.gif',
  '.jpg',
  '.jpeg',
  '.jfif',
  '.jif',
  '.heic',
  '.webp',
  '.zip',
];
