import { ApButtonMain } from '@alixpartners/ui-components';

export const MatchingStart = ({
  onMatchingTrigger,
  inProgressStatus,
}: {
  onMatchingTrigger: () => Promise<void>;
  inProgressStatus?: boolean;
}) => (
  <div className="matching-start">
    <div className="matching-start__header flex-align-center">
      <h4>Matching process</h4>
      <ApButtonMain onClick={onMatchingTrigger} disabled={inProgressStatus} medium>
        Start matching
      </ApButtonMain>
    </div>
  </div>
);
