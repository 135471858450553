import { ActionName } from 'api/endpoints/action-names';
import Excel from 'exceljs';
import { IExhibitState } from 'reducer/exhibits/exhibitReducer';
import { ClaimRow } from '../../../api/endpoints';
import { US_DATE_FORMAT, formatDate } from '../../../utils/formatDate';
import { getClaimNumber } from '../utils/field';
import { IExcelCreator } from './excelExporter';
import {
  createTotalRowStyle,
  defCellStyle,
  defPositionNumberStyle,
} from './utils/excelConst';
import {
  createExcelWorkbook,
  ExcelDocumentHeader,
} from './utils/excelCreateWorkbook';
import { excelAddPageHeader, HeaderPositionCell } from './utils/excelHeader';
import {
  deleteObjectionCommentaryColumn,
  excelAddPageFooter,
  excelAddRow,
  excelAddTableHeaderRow,
  excelAddTotalRow,
} from './utils/excelUtils';
import {
  createClaimAmount,
  createHeaderFooter,
  createReducedClaimAmount,
  getAddressLine,
  getTotalCurrency,
} from './utils/exporterUtils';
import { IExhibitWorkbookData, IRowValues, TRowStyle } from './utils/type';

const COLUMN_LIST: ExcelDocumentHeader[] = [
  { header: '', width: 4 },
  { header: 'Original Creditor', width: 20 },
  { header: 'Claim to be modified', width: 12 },
  { header: 'Original Debtor', width: 19 },
  { header: 'Date filed/scheduled', width: 11 },
  { header: 'Unliquidated', width: 9 },
  { header: 'Claim amount and priority (1)', width: 13 },
  { header: 'Modified Claim amount and/or priority (1)', width: 13 },
  { header: 'Basis for objection', width: 15 },
  { header: 'Comments', width: 15 },
];

const valuesStyle: TRowStyle = [
  { ...defPositionNumberStyle },
  { ...defCellStyle },
  {
    ...defCellStyle,
    alignment: { horizontal: 'center', vertical: 'top' },
  },
  { ...defCellStyle },
  { ...defCellStyle },
  { ...defCellStyle },
  {
    ...defCellStyle,
    numFmt: '[$$-409]#,##0.00',
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  },
  {
    ...defCellStyle,
    numFmt: '[$$-409]#,##0.00',
    alignment: { horizontal: 'right', vertical: 'top', wrapText: true },
  },
  { ...defCellStyle },
  { ...defCellStyle },
];

const valuesTotalStyle = createTotalRowStyle(valuesStyle.length);

const objectionColId = 10;

const PAGE_HEADER_POS: HeaderPositionCell = {
  left: 1,
  middle: 4,
  right: 7,
  length: 10,
};

export const templateAlterClaimAmount: IExcelCreator = {
  fileName: 'Alter_ClaimAmount.xlsx',
  getWorkbook: async (exhibitState: IExhibitState, actionName: ActionName) => {
    const excelData = getDataToShow(exhibitState, actionName);
    return await createWorkbook(excelData, exhibitState.displayObjection);
  },
};

async function createWorkbook(
  data: IExhibitWorkbookData,
  displayObjection: boolean | undefined,
): Promise<Excel.Workbook> {
  const workbook = createExcelWorkbook('Sheet1', COLUMN_LIST);
  const firstSheet = workbook.getWorksheet(1);

  excelAddPageHeader(firstSheet, data.headerFooter.header, PAGE_HEADER_POS);
  excelAddTableHeaderRow(firstSheet, COLUMN_LIST);
  excelAddPageFooter(firstSheet, data.headerFooter);

  data.claimsList.forEach((row) => excelAddRow(firstSheet, row, valuesStyle));
  excelAddTotalRow(firstSheet, data.totalRow, valuesTotalStyle);

  deleteObjectionCommentaryColumn(firstSheet, displayObjection, objectionColId);
  return workbook;
}

function getDataToShow(
  exhibitState: IExhibitState,
  actionName: ActionName,
): IExhibitWorkbookData {
  const { structureFooterOptions, displayObjection } = exhibitState;

  // here is a bug. `r.value` is string type. But in real it contains an integer value
  const showClaimCount = !!structureFooterOptions.find((r) => r.value == '3'); // eslint-disable-line eqeqeq

  return {
    headerFooter: createHeaderFooter(exhibitState, actionName),
    claimsList: getPreparedClaimsData(
      exhibitState.claims,
      displayObjection,
      exhibitState.objectionBasis,
    ),
    totalRow: getTotalRow(exhibitState.claims, showClaimCount),
  };
}

function getTotalRow(claims: ClaimRow[], showClaimCount: boolean): IRowValues {
  const values = [
    '', //positionNumber
    'Claims to be modified totals', //originalCreditor
    showClaimCount ? `Count: ${claims.length}` : '', //referenceNumber
    '', //originalDebtor
    '', //officialClaimDate
    '', //unliquidated
    getTotalCurrency(claims, 'currentTotal'), //claimAmount
    getTotalCurrency(claims, 'proposedTotal'), //reducedClaimAmount
    '', //objectionBasis
  ];

  return { values };
}

const getPreparedClaimsData = (
  claims: ClaimRow[],
  displayObjection: boolean | undefined,
  objectionBasis: string,
) => {
  return claims.map(
    (c, index) =>
      ({
        values: [
          index + 1, //positionNumber
          getAddressLine(c), //originalCreditor
          getClaimNumber(c), //referenceNumber
          c.originalDebtor, //originalDebtor
          formatDate(c.officialClaimDate, US_DATE_FORMAT), //officialClaimDate
          getUnliquidated(c.originalUnliquidated), //unliquidated
          createClaimAmount(c), //claimAmount
          createReducedClaimAmount(c), //reducedClaimAmount
          objectionBasis, //objectionBasis
          displayObjection ? c.objectionCommentary : '', //objectionCommentary
        ],
      } as IRowValues),
  );
};

const getUnliquidated = (checked: boolean | undefined) => {
  return checked ? 'YES' : 'NO';
};
