import { ValueFormatterParams } from 'ag-grid-community';
import { SettlementStatus } from 'api/endpoints/settlements';
import _ from 'lodash';
import { ColumnBase, getGridFilterProps } from 'utils/agGrid/agGridColumn';
import { READABLE_DAY_MONTH_YEAR, formatDate } from 'utils/formatDate';
import { formatMoney } from 'utils/formatNumber';
import {
  getSettlementStatusName,
  settlementStatusValues,
} from 'utils/settlements/settlementType';
import { SettlementColDef } from '../settlementType';
import { SettlementAgreementCell } from './cells/SettlementAgreementCell';
import { SettlementLinkCell } from './cells/SettlementLinkCell';
import { SettlementStatusCell } from './cells/SettlementStatusCell';

export const SETTLEMENT_ADMIN_COLUMNS: ColumnBase[] = [
  {
    propertyName: 'settlementNumber',
    displayName: 'Settlement Number',
    dataType: 'int',
  },
  {
    propertyName: 'claimNumbers',
    displayName: 'Claim Numbers',
    dataType: 'string',
  },
  {
    propertyName: 'countersignerName',
    displayName: 'Client Name',
    dataType: 'string',
  },
  {
    propertyName: 'signerName',
    displayName: 'Counterparty Name',
    dataType: 'string',
  },
  {
    propertyName: 'proposedTotal',
    displayName: 'Proposed Total',
    dataType: 'decimal',
  },
  {
    propertyName: 'currentTotal',
    displayName: 'Current Total',
    dataType: 'decimal',
  },
  {
    propertyName: 'status',
    displayName: 'Status',
    dataType: 'string',
  },
  {
    propertyName: 'agreementFileName',
    displayName: 'Agreement',
    dataType: 'string',
  },

  //----- hidden fields -------------
  {
    propertyName: 'proposedSecured',
    displayName: 'Proposed Secured',
    dataType: 'decimal',
  },
  {
    propertyName: 'proposedAdministrative',
    displayName: 'Proposed Administrative',
    dataType: 'decimal',
  },
  {
    propertyName: 'proposedPriority',
    displayName: 'Proposed Priority',
    dataType: 'decimal',
  },
  {
    propertyName: 'proposedUnsecured',
    displayName: 'Proposed Unsecured',
    dataType: 'decimal',
  },
  {
    propertyName: 'currentSecured',
    displayName: 'Current Secured',
    dataType: 'decimal',
  },
  {
    propertyName: 'currentAdministrative',
    displayName: 'Current Administrative',
    dataType: 'decimal',
  },
  {
    propertyName: 'currentPriority',
    displayName: 'Current Priority',
    dataType: 'decimal',
  },
  {
    propertyName: 'currentUnsecured',
    displayName: 'Current Unsecured',
    dataType: 'decimal',
  },

  {
    propertyName: 'counterpartyName',
    displayName: 'Name',
    dataType: 'string',
  },
  {
    propertyName: 'counterpartyEmail',
    displayName: 'Email',
    dataType: 'string',
  },
  {
    propertyName: 'counterpartyPhone',
    displayName: 'Phone',
    dataType: 'int',
  },
  {
    propertyName: 'counterpartyCountry',
    displayName: 'Country',
    dataType: 'string',
  },
  {
    propertyName: 'counterpartyState',
    displayName: 'State',
    dataType: 'string',
  },
  {
    propertyName: 'counterpartyCity',
    displayName: 'City',
    dataType: 'string',
  },
  {
    propertyName: 'counterpartyZip',
    displayName: 'Zip',
    dataType: 'string',
  },
  {
    propertyName: 'counterpartyAddress',
    displayName: 'Address',
    dataType: 'string',
  },
];

const initialVisibleColumns = _(SETTLEMENT_ADMIN_COLUMNS)
  .take(8)
  .map((r) => r.propertyName);

export const SETTLEMENT_ADMIN_GRID_COLUMNS = (() => {
  const gridColumns = SETTLEMENT_ADMIN_COLUMNS.map(mapColumsDefs);
  const [hiddenCols, visibleCols] = _.partition(gridColumns, (r) => r.initialHide);
  const hiddenSortedCols = _.sortBy(hiddenCols, (r) => r.field);

  return [...visibleCols, ...hiddenSortedCols];
})();

function mapColumsDefs(col: ColumnBase): SettlementColDef {
  const { filter, filterParams } = getGridFilterProps(col);
  const customProps = getCustomColumnProps(col);

  const result: SettlementColDef = {
    field: col.propertyName,
    headerName: col.displayName,
    initialHide: !initialVisibleColumns.includes(col.propertyName),
    sortable: true,
    unSortIcon: false,
    resizable: true,
    filter,
    filterParams,

    ...customProps,
  };

  return result;
}

function getCustomColumnProps(
  col: ColumnBase,
): Pick<
  SettlementColDef,
  | 'cellRenderer'
  | 'cellRendererParams'
  | 'valueFormatter'
  | 'editable'
  | 'sortable'
  | 'resizable'
  | 'minWidth'
  | 'maxWidth'
  | 'filter'
  | 'filterParams'
  | 'cellClass'
> {
  if (col.propertyName === 'settlementNumber') {
    return {
      cellRenderer: SettlementLinkCell,
    };
  }

  if (col.propertyName === 'status') {
    return {
      cellRenderer: SettlementStatusCell,
      filter: 'agSetColumnFilter',
      filterParams: {
        values: settlementStatusValues,
        valueFormatter: ({ value }: ValueFormatterParams<any, SettlementStatus>) =>
          getSettlementStatusName(value),
      },
    };
  }

  if (col.propertyName === 'agreementFileName') {
    return {
      cellRenderer: SettlementAgreementCell,
      editable: false,
      sortable: false,
      //next props are workaround to prevent resize (resizable, minWidth, maxWidth)
      resizable: true,
      minWidth: 180,
      maxWidth: 180,
      filter: undefined,
    };
  }

  if (col.dataType === 'date') {
    return {
      valueFormatter: (params) => formatDate(params.value, READABLE_DAY_MONTH_YEAR),
    };
  }

  if (col.dataType === 'decimal') {
    return {
      valueFormatter: (params) => formatMoney(params.value),
      cellClass: 'ag-right-aligned-cell',
    };
  }

  return {};
}
