import { ApButtonMain } from '@alixpartners/ui-components';
import classNames from 'classnames';
import 'components/UIComponents/css-animations.css';
import { useCallback, useState } from 'react';
import { FetchStatus } from 'types/fetch-status.types';
import { UseSubmitHandler, useSubmitHandler } from 'utils/form/form-utils';
import { useEntityContext } from './EntityContext';
import './EntityTable.css';
import { EntityAddRowProps, EntityRowState as Ers } from './EntityTable.types';
import { useControlledValues } from './useControlledValues';

export const EntityAddRow = (_props: EntityAddRowProps) => {
  const {
    onAddSave,
    addColumnKeys,
    inputComponent: InputComp,
    options,
  } = useEntityContext();
  const [expanded, setExpanded] = useState<Ers>(Ers.Initial);
  const [error, setError] = useState<{ message: string } | null>(null);
  const [formSubmitted, setFormSubmitted] = useState(false);

  const {
    clearValues,
    getValue,
    setValue,
    controlledValues,
    setValidity,
    generalValidity,
  } = useControlledValues(setFormSubmitted);

  const inputsDisabled = expanded === Ers.Saving;

  const handleSave: UseSubmitHandler = useCallback(
    (evt, { validState }, formValues) => {
      const form = evt.target as HTMLFormElement;
      setError(null);

      if (validState === 'invalid') return;
      setExpanded(Ers.Saving);

      if (onAddSave) {
        onAddSave({ ...formValues, ...controlledValues }).then((result) => {
          if (result?.type === FetchStatus.Error) {
            setError(result.error);
            setExpanded(Ers.Collapsing);
          } else {
            form.reset();
            clearValues();
            setExpanded(Ers.Collapsing);
            setFormSubmitted(true);
          }
        });
      } else {
        setExpanded(Ers.Collapsing);
      }
    },
    [clearValues, controlledValues, onAddSave],
  );
  const { handleSubmit, validState } = useSubmitHandler(handleSave);

  const cn = classNames(
    'dynamicfields__row-el dynamicfields__row-el-add transition-squeeze utils-form',
    {
      trigger: expanded === Ers.Expading || expanded === Ers.Saving,
      'utils-form-invalid': validState === 'invalid',
      'dynamicfields__row-el-saving utils-obstruct': expanded === Ers.Saving,
      'dynamicfields__row-el-collapsed': expanded === Ers.Collapsing,
    },
  );

  const addCn = classNames(
    'dynamicfields__edit  dynamicfields__add entity-table__action',
    {},
  );

  const disabledSumit = generalValidity === 'invalid';

  return (
    <form
      action="#"
      autoComplete="off"
      noValidate
      className={cn}
      onSubmit={handleSubmit}
    >
      {addColumnKeys.map((ck, i) => {
        const isLast = i === addColumnKeys.length - 1 && i !== 0;
        return (
          <div
            key={ck}
            className={classNames('dynamicfields__cell dynamicfields__cell-add', {
              'edit-table__cell-last': isLast,
            })}
          >
            <InputComp
              columnKey={ck}
              item={null}
              fromTable={{
                operation: 'new',
                setValidity,
                getValue,
                setValue,
                disabled: inputsDisabled,
                className: 'dynamicfields__cell__body',
                formSubmitted,
                options,
              }}
            />
          </div>
        );
      })}

      <ApButtonMain className={addCn} type="submit" disabled={disabledSumit} medium>
        Add
      </ApButtonMain>
      {error && (
        <p className="anim-appear entity-table__error-row">{error.message}</p>
      )}
    </form>
  );
};
