import { userDashboardTasks } from 'api/endpoints/dashboard-tasks';
import { ResponseType } from 'api/jsonFetch/jsonFetch.types';

import { downloadExcelFile } from 'utils/file';
import { getCurrentDate, ISO_DATE, formatDate } from 'utils/formatDate';
import { ClaimsDashboardCategory, ClaimsDetailedData } from '../types';

import {
  ClaimsData,
  ClaimsRowSection,
  claimTableWorkbook,
} from './claimTableWorkbook';

import { showHttpSingleError } from 'api/apiRequest/httpErrorAlert';

const workflowIdToLabel: Record<ClaimsDashboardCategory, string> = {
  [ClaimsDashboardCategory.Resolved]: 'RESOLVED',
  [ClaimsDashboardCategory.ResolutionProccessInitiated]:
    'RESOLUTION PROCESS INITIATED',
  [ClaimsDashboardCategory.ResolutionProcessPending]: 'RESOLUTION PROCESS PENDING',
  [ClaimsDashboardCategory.UnassignedClaims]: 'UNASSIGNED CLAIMS',
};

const WORKFLOW_ORDER = [
  ClaimsDashboardCategory.Resolved,
  ClaimsDashboardCategory.ResolutionProccessInitiated,
  ClaimsDashboardCategory.ResolutionProcessPending,
  ClaimsDashboardCategory.UnassignedClaims,
];

export const exportClaimsTable = async (showMine?: boolean) => {
  const response = await userDashboardTasks.getApClaimsItems(showMine);
  if (response.type !== ResponseType.OK) {
    showHttpSingleError({ title: 'Could not fetch claims data' });
    return;
  }

  const dataToExport = mapDataToExport(response.data);

  const excelWorkbook = await claimTableWorkbook(dataToExport);

  const fileName = 'Claims-Dashboard Export';
  const currentIsoDate = formatDate(getCurrentDate(), ISO_DATE);
  await downloadExcelFile(excelWorkbook, `${fileName} ${currentIsoDate}.xlsx`);
};

export const mapDataToExport = (data: ClaimsDetailedData[]): ClaimsData =>
  WORKFLOW_ORDER.map((workflowId) => data.find((item) => item.id === workflowId))
    .filter((item) => item !== undefined)
    .map((item) => mapWorkflowValues(item as ClaimsDetailedData));

const mapWorkflowValues = (item: ClaimsDetailedData): ClaimsRowSection => {
  const name = workflowIdToLabel[item.id as ClaimsDashboardCategory];
  const values = item.items.map((r) => [r.label, r.count, r.totalProposed]);

  return { name, values };
};
