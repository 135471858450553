import { ApButtonGroup, ApButtonTab } from '@alixpartners/ui-components';
import { ContractEditValues } from 'api/endpoints';
import classNames from 'classnames';
import 'components/UIComponents/ui-button-reset.css';
import { useMemo } from 'react';
import { useContractAttachmentsContext } from '../context/ContractAttachmentsProvider';
import { ContractTab } from '../types';
import './SingleContractSubheader.css';
import { useSingleContractMatches } from '../hooks/useSingleContractMatches';

export interface SingleContractSubheaderProps {
  contract: ContractEditValues;
  tab: ContractTab;
  onTabClick: (s: ContractTab) => unknown;
  relatedContractsCount: number;
}

const SINGLE_CONTRACT_SUBHEADER_OPTIONS = [
  {
    type: ContractTab.Overview,
    label: 'Overview',
    hidden: false,
  },
  {
    type: ContractTab.Matches,
    label: 'Matches',
    hidden: false,
    disabled: false,
  },
  {
    type: ContractTab.ChangesAndComments,
    label: 'Changes & Comments',
    hidden: false,
    disabled: false,
  },
  {
    type: ContractTab.Documents,
    label: 'Documents',
    hidden: false,
    disabled: false,
  },
  {
    type: ContractTab.CounterpartiesAndDebtors,
    label: 'Counterparties & Debtors',
    hidden: false,
    disabled: false,
  },
  {
    type: ContractTab.RelatedContracts,
    label: 'Related Contracts',
    hidden: false,
    disabled: false,
  },
];

export const SingleContractSubheader = ({
  contract,
  tab,
  onTabClick,
  relatedContractsCount
}: SingleContractSubheaderProps) => {
  const { attachments } = useContractAttachmentsContext();
  const { matchedContracts } = useSingleContractMatches(contract);
  const documentsCount = useMemo(
    () => (attachments.length > 0 ? ` [${attachments.length}]` : ''),
    [attachments],
  );
  const matchesCount = useMemo(
    () => (matchedContracts ? ` [${matchedContracts?.length}]` : ''),
    [matchedContracts],
  );
  const relatedCount = useMemo(
    () => (relatedContractsCount ? ` [${relatedContractsCount}]` : ''),
    [relatedContractsCount],
  );

  const labelGenerator = (s: { label: string; type: ContractTab }) => {
    switch(s.type) {
      case(ContractTab.Documents): return `${s.label}${documentsCount}`;
      case(ContractTab.Matches): return `${s.label}${matchesCount}`;
      case(ContractTab.RelatedContracts): return `${s.label}${relatedCount}`
    }
    return s.label;
  };

  return (
    <div className="single-contract-subheader">
      <div className="single-contract-subheader__tabs">
        <ApButtonGroup justifyContent="initial" spacingSize={0}>
          {SINGLE_CONTRACT_SUBHEADER_OPTIONS.map((s) => {
            if (s.hidden) return null;
            if (s.type === ContractTab.Matches && contract.notMatched) return null;
            const label = labelGenerator(s);

            return (
              <ApButtonTab
                key={s.type}
                className={classNames('single-contract-subheader__tab', {
                  selected: tab === s.type,
                })}
                autoFocus={false}
                isSelected={tab === s.type}
                onClick={() => onTabClick(s.type)}
                theme="light"
                disabled={
                  s.disabled ||
                  (s.type === ContractTab.RelatedContracts &&
                    !relatedCount)
                }
              >
                {label}
              </ApButtonTab>
            );
          })}
        </ApButtonGroup>
      </div>
    </div>
  );
};
