import { ApButtonMain, ApLoaderDefault } from '@alixpartners/ui-components';

import './ButtonGenerate.css';

interface Props {
  onClick: () => void;
  showSpinner?: boolean;
}

export const ButtonGenerate = ({ onClick, showSpinner }: Props) => {
  return (
    <div className="claims-reports__generate-button">
      <ApButtonMain iconName="save_alt" onClick={onClick} medium>
        Generate report {showSpinner ? <ApLoaderDefault loaderSize="sm" /> : ''}
      </ApButtonMain>
    </div>
  );
};
