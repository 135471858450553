import classNames from 'classnames';
import { useId } from 'hooks/useId';
import './Datepicker.css';
import { DatepickerProps } from './DatePicker.types';
import { useDatepicker } from './Datepicker.hooks';

export function Datepicker(props: DatepickerProps) {
  const {
    id: idProp,
    className,
    name,
    value: valueProp,
    defaultValue: defaultValueProp,
    onChange,
    disabled,
    required,
    formatLabel,
    ...rest
  } = props;
  const id = useId(idProp);

  const {
    defaultValue,
    handleChange,
    handleClick,
    handleSpacebar,
    label,
    validity,
    inputRef,
  } = useDatepicker(props);

  return (
    <div
      tabIndex={disabled ? -1 : 0}
      data-input={validity}
      onClick={handleClick}
      onKeyDown={handleSpacebar}
      className={classNames('datepicker', className, {
        'datepicker-disabled': disabled,
      })}
    >
      <input
        ref={inputRef}
        required={required}
        name={name}
        onChange={handleChange}
        className="datepicker__input"
        id={id}
        disabled={disabled}
        defaultValue={defaultValue}
        type="date"
        {...rest}
      />
      <label className="datepicker__label" htmlFor={id}>
        {label}
      </label>
    </div>
  );
}
