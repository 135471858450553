import { ApLoaderDefault } from '@alixpartners/ui-components';
import { SingleContractHeader } from 'components/SingleEntityHeader/SingleContractHeader';
import { SingleEntityMenu } from 'components/SingleEntityMenu/SingleEntityMenu';
import { useSubheaderMenuNav } from 'hooks/useSubheaderMenuNav';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useContractsUserGroups } from 'pages/SettingsPage/sections/UserGroups/hooks/useContractsUserGroups';
import { useQueryParam, withDefault } from 'use-query-params';
import { useAllUsers } from '../../hooks/useAllUsers';
import '../general-page.css';
import { SavePopup } from './components/SavePopup';
import { SingleContractSubheader } from './components/SingleContractSubheader';
import { ContractAttachmentProvider } from './context/ContractAttachmentsProvider';
import useEditContract from './sections/SingleContractOverview/hook/useEditContract';
import './SingleContractPage.css';
import { SingleContractSections } from './SingleContractSections';
import { ContractTab } from './types';
import { Layout } from 'components/Layout';
import { useCallback } from 'react';

const DEFAULT_TAB = ContractTab.Overview;

const SingleContractSubheaderParam = {
  encode: (v: ContractTab) => v,
  decode: (v: any) =>
    Object.keys(ContractTab).includes(v) ? (v as ContractTab) : DEFAULT_TAB,
};

export const SingleContractPage = () => {
  const [tab, setTab] = useQueryParam<ContractTab>(
    'tab',
    withDefault(SingleContractSubheaderParam, DEFAULT_TAB),
  );

  useContractsUserGroups();
  useAllUsers();

  const editContractHook = useEditContract();
  const {
    loading,
    isEdited,
    contract,
    changeOptionsHook,
    isSaving,
    contractId,
    save,
    relatedContractsData
  } = editContractHook;
  const popupDisplayed = isEdited;

  const { handleBackClick, handleSubMenuClick } = useSubheaderMenuNav(
    SectionType.Contracts,
  );

  const handleSaveButtonClick = useCallback(() => {
    save();
    setTab(DEFAULT_TAB);
  }, [save, setTab])

  return (
    <ContractAttachmentProvider contractId={contractId}>
      <Layout stickyFooter={false}>
        <SingleEntityMenu
          selected={SectionType.Contracts}
          onClickBack={handleBackClick}
          onSectionClick={handleSubMenuClick}
        />
        <div className="general-page__outer-container">
          {loading ? (
            <ApLoaderDefault className="single-contract__loader" />
          ) : !contract ? (
            <div className="single-contract__not-found">Contract not found</div>
          ) : (
            <div className="general-page__inner-container">
              <SingleContractHeader
                contract={contract}
                editContractHook={editContractHook}
              />
              <div className="single-contract__sections-container">
                <SingleContractSubheader
                  contract={contract}
                  tab={tab}
                  onTabClick={setTab}
                  relatedContractsCount={relatedContractsData.relatedContractsCount}
                />
                <SingleContractSections
                  tab={tab}
                  contract={contract}
                  editContractHook={editContractHook}
                  relatedContractsData={relatedContractsData}
                />
              </div>
            </div>
          )}
        </div>
        <SavePopup
          open={popupDisplayed}
          changeOptionsHook={changeOptionsHook}
          onSave={handleSaveButtonClick}
          isSaving={isSaving}
        />
      </Layout>
    </ContractAttachmentProvider>
  );
};
