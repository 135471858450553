import React from 'react';
import { ApButtonGroup, ApButtonMain } from '@alixpartners/ui-components';
import { ApButtonCasual } from '../../Buttons/ApButtonCasual';

interface IMultiSelectFooterProps {
  clearSelection: () => void;
  applySelection: () => void;
  disabled?: boolean;
}

export default function MultiSelectFooter({
  clearSelection,
  applySelection,
  disabled,
}: IMultiSelectFooterProps) {
  return (
    <div className="multiselect--footer">
      <ApButtonGroup justifyContent="flex-end">
        <ApButtonCasual onClick={clearSelection} style={{ textTransform: 'none' }}>
          Clear Selection
        </ApButtonCasual>
        <ApButtonMain
          onClick={applySelection}
          disabled={disabled}
          style={{ textTransform: 'none' }}
          medium
        >
          Apply
        </ApButtonMain>
      </ApButtonGroup>
    </div>
  );
}
