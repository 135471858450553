export const SCHEDULE_AB_8_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: ['Automobile', 'Watercraft', 'Aircraft', 'Other'],
      dataType: 'enum',
      displayName: 'Type of Motor Asset',
      propertyName: 'typeOfMotorAsset',
      queryType: 'ScheduleTypeOfMotorAsset'
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Name',
      propertyName: 'name',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Net Book Value',
      propertyName: 'netBookValue',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Valuation Method',
      propertyName: 'valutationMethod',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Current Value',
      propertyName: 'currentValue',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Depreciation Schedule',
      propertyName: 'depreciationSchedule',
    },
    {
      allowedValues: null,
      dataType: 'bool',
      displayName: 'Appraised in the Last Year',
      propertyName: 'appraisedInLastYear',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
