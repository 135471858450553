import { ApLoaderDefault } from '@alixpartners/ui-components';
import { WarningModal } from 'components/ModalWindows/WarningModal/WarningModal';
import { SingleClaimHeader } from 'components/SingleEntityHeader/SingleClaimHeader';
import { useQueryParam, withDefault } from 'use-query-params';

import { SingleEntityMenu } from 'components/SingleEntityMenu';
import { useSubheaderMenuNav } from 'hooks/useSubheaderMenuNav';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { ClaimAttachmentProvider } from 'pages/SingleContractPage/context/ClaimAttachmentsProvider';
import { useAllUsers } from '../../hooks/useAllUsers';
import { useAssignedGroupUsers } from '../../hooks/useAssignedGroupUsers';
import '../general-page.css';
import { SavePopup } from './components/SavePopup';
import { SingleClaimSubheader } from './components/SingleClaimSubheader';
import { useEditClaim } from './sections/SingleClaimOverview/hook/useEditClaim';
import './SingleClaimPage.css';
import { SingleClaimSections } from './SingleClaimSections';
import { SingleClaimSubheaderType } from './types';
import { useClaimEditableForClient } from './hooks/useClaimEditableForClient';
import { useAppUserType } from 'utils/hooks/useAppUserType';
import { Layout } from 'components/Layout';
import { useCallback } from 'react';

const DEFAULT_TAB = SingleClaimSubheaderType.Overview;

const SingleClaimSubheaderParam = {
  encode: (v: SingleClaimSubheaderType) => v,
  decode: (v: any) =>
    Object.keys(SingleClaimSubheaderType).includes(v)
      ? (v as SingleClaimSubheaderType)
      : DEFAULT_TAB,
};

export const SingleClaimPage = () => {
  const [tab, setTab] = useQueryParam<SingleClaimSubheaderType>(
    'tab',
    withDefault(SingleClaimSubheaderParam, DEFAULT_TAB),
  );

  useAssignedGroupUsers();
  useAllUsers();

  const editClaimHook = useEditClaim();
  const {
    loading,
    isEdited,
    claim,
    changeOptionsHook,
    isSaving,
    confirmationModal,
    saveButtonClick,
    claimId,
    relatedClaimsData
  } = editClaimHook;
  const popupDisplayed = isEdited;

  const handleSaveButtonClick = useCallback(() => {
    saveButtonClick();
    setTab(DEFAULT_TAB);
  }, [saveButtonClick, setTab])

  const { handleBackClick, handleSubMenuClick } = useSubheaderMenuNav(
    SectionType.Claims,
  );
  const { editableByClient } = useClaimEditableForClient(claim?.actionStatus);
  const { isApUser } = useAppUserType();

  return (
    <ClaimAttachmentProvider claimId={claimId} claim={claim}>
      <Layout stickyFooter={false}>
      <SingleEntityMenu
        selected={SectionType.Claims}
        onClickBack={handleBackClick}
        onSectionClick={handleSubMenuClick}
      />
        <div className="general-page__outer-container">
          {loading ? (
            <ApLoaderDefault className="single-claim__loader" />
          ) : !claim ? (
            <div className="single-claim__not-found">Claim not found</div>
          ) : (
            <div className="general-page__inner-container">
              <SingleClaimHeader claim={claim} editClaimHook={editClaimHook} />
              <div className="single-claim__sections-container">
                <SingleClaimSubheader
                  relatedClaimsCount={relatedClaimsData.relatedClaimsCount}
                  claim={claim} 
                  tab={tab} 
                  onTabClick={setTab} 
                />
                <SingleClaimSections
                  tab={tab}
                  claim={claim}
                  editClaimHook={editClaimHook}
                  relatedClaimsData={relatedClaimsData}
                />
              </div>
            </div>
          )}
        </div>
        {(isApUser || editableByClient) && (
          <SavePopup
            open={popupDisplayed}
            changeOptionsHook={changeOptionsHook}
            onSave={handleSaveButtonClick}
            isSaving={isSaving}
          />
        )}
      </Layout>
      {confirmationModal && (
        <WarningModal
          title="Warning!"
          mainBtnText="Go back and fix the errors"
          dangerBtnText="Ignore"
          message={confirmationModal.message}
          onConfirm={confirmationModal.onConfirm}
          disabled={isSaving}
        />
      )}
    </ClaimAttachmentProvider>
  );
};
