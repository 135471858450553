import { ReportType } from 'api/endpoints/sofas-schedules';
import {
  ExportDropdownItems,
  SelectedItems,
  SofasSelected,
} from '../sofaExportTypes';
import { ReportTypeInputs } from './ReportTypeInputs';
import { SofasFormExportPdf } from './SofasFormExportPdfl';
import { SofasFormTemplate } from './SofasFormTemplate';
import { SubmitButton } from './SubmitButton';

interface Props {
  reportType: ReportType;
  template: SelectedItems;
  exportSelected: SofasSelected;
  dropdownItems: ExportDropdownItems;
  isFieldsValid: boolean;
  onTemplateSelectedChange: (value: SelectedItems) => void;
  onTemplateSubmit: () => Promise<void>;
  onExportSelectedChange: (value: SofasSelected) => void;
  onExportSubmit: () => Promise<void>;
  onReportTypeChange: (value: ReportType) => void;
}

export const SofasExportForm = ({
  reportType,
  template,
  exportSelected,
  dropdownItems,
  isFieldsValid,
  onTemplateSelectedChange,
  onTemplateSubmit,
  onExportSelectedChange,
  onExportSubmit,
  onReportTypeChange,
}: Props) => {
  return (
    <>
      <div className="sofas-export-type">
        <ReportTypeInputs selected={reportType} onChange={onReportTypeChange} />
      </div>

      <div className="sofas-export-options">
        {reportType === 'Excel' ? (
          <>
            <SofasFormTemplate
              value={template}
              onChange={onTemplateSelectedChange}
            />
            <div>
              <SubmitButton
                disabled={!template.items.length}
                onClick={onTemplateSubmit}
                text="Export Data (.xlsx)"
              />
            </div>
          </>
        ) : (
          <>
            <SofasFormExportPdf
              dropdownItems={dropdownItems}
              selected={exportSelected}
              onSelectedChange={onExportSelectedChange}
            />
            <div>
              <SubmitButton
                disabled={!isFieldsValid}
                text={`Export ${reportType} Report (.pdf)`}
                onClick={onExportSubmit}
              />
            </div>
          </>
        )}
      </div>
    </>
  );
};
