import {
  ApButtonMain,
  ApMenu,
  ApMenuItem,
  ApIcon,
} from '@alixpartners/ui-components';
import cs from 'classnames';
import { useState } from 'react';
import './DropdownMenu.css';

type ChildrenType = React.ReactElement<typeof ApMenuItem>;

interface IDropdownProps extends React.ComponentProps<typeof ApButtonMain> {
  label: string;
  disableCloseOnSubmenuClick?: boolean;
  className?: string;
  buttonClassname?: string;
  thinShape?: boolean;
  children: ChildrenType | ChildrenType[];
  usePrimaryButton?: boolean;
}

export const DropdownMenuMain = ({
  label,
  iconName,
  className,
  buttonClassname,
  children,
  disableCloseOnSubmenuClick,
  disabled,
}: IDropdownProps) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <ApMenu
      isOpen={isOpen || disableCloseOnSubmenuClick}
      toggleMenu={setIsOpen}
      className={className}
      placement="bottom-end"
      button={
        <ApButtonMain
          autoFocus={false}
          disabled={!!disabled}
          theme="light"
          className={cs('dropdown-button', buttonClassname)}
          iconName={iconName}
          medium
        >
          <span className="dropdown-button__label">{label}</span>
          <span className="dropdown-button__arrow">
            <ApIcon
              iconName={isOpen ? 'arrow_drop_up' : 'arrow_drop_down'}
              iconSize={24}
            />
          </span>
        </ApButtonMain>
      }
    >
      {children}
    </ApMenu>
  );
};
