import { IconLoading } from 'components/UIComponents/IconLoading';
import { isNil } from 'lodash';
import pluralize from 'pluralize';
import { useSofasExportContext } from '../Domain/SofasExportContextProvider';
import './ExportHistoryButton.css';
import { ApButtonCasual } from 'components/UIComponents/Buttons';

interface Props {
  onClick: () => void;
}

export const ExportHistoryButton = ({ onClick }: Props) => {
  const { inProgress, queued } = useSofasExportContext();

  if (isNil(inProgress) || isNil(queued)) return <span></span>;

  const totalCount = inProgress.length + queued.length;

  return (
    <span className="sofas-export-history-link">
      {totalCount <= 0 ? (
        <ApButtonCasual onClick={onClick}>
          See Previous Exported Files
        </ApButtonCasual>
      ) : (
        <>
          <IconLoading />
          <span>
            Exporting {totalCount} {pluralize('file', totalCount)}
          </span>
          <ApButtonCasual
            className="sofas-export-history-link__progress"
            onClick={onClick}
          >
            See progress
          </ApButtonCasual>
        </>
      )}
    </span>
  );
};
