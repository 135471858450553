import { ApButtonMain } from '@alixpartners/ui-components';

interface Props {
  disabled: boolean;
  isGenerating?: boolean;
  text?: string;
  onClick: () => void;
}

export const SubmitButton = ({
  disabled,
  isGenerating,
  text = 'Export',
  onClick,
}: Props) => {
  return (
    <ApButtonMain
      className="sofas-schedules-export__button"
      disabled={disabled}
      type="submit"
      onClick={onClick}
      iconName={isGenerating ? 'spinner' : 'download'}
      medium
    >
      {isGenerating ? 'Preparing file...' : text}
    </ApButtonMain>
  );
};
