import { SingleClaimContractChangesComments } from 'components/SingleClaimContractChangesComments/SingleClaimContractChangesComments';
import { SingleClaimMatches } from './sections/SingleClaimMatches';
import { SingleClaimOverview } from './sections/SingleClaimOverview/SingleClaimOverview';
import { SingleClaimSubheaderType } from './types';

import { ClaimEditValues } from 'api/endpoints';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { SingleClaimDocuments } from './sections/SingleClaimDocuments';
import { EditClaimHook } from './sections/SingleClaimOverview/hook/useEditClaim';
import { SingleClaimRelatedClaims } from './sections/SingleClaimRelatedClaims/SingleClaimRelatedClaims';
import { RelatedClaimsData } from './sections/SingleClaimOverview/util/getRelatedClaims';

export interface SingleClaimSectionsProps {
  tab: SingleClaimSubheaderType;
  claim: ClaimEditValues;
  editClaimHook: EditClaimHook;
  relatedClaimsData: RelatedClaimsData;
}

export const SingleClaimSections = ({
  tab,
  claim,
  editClaimHook,
  relatedClaimsData
}: SingleClaimSectionsProps) => {
  switch (tab) {
    case SingleClaimSubheaderType.Overview:
      return <SingleClaimOverview editClaimHook={editClaimHook} />;
    case SingleClaimSubheaderType.Matches:
      return <SingleClaimMatches claim={claim} />;
    case SingleClaimSubheaderType.ChangesAndComments:
      return (
        <SingleClaimContractChangesComments
          claimId={claim.id}
          contractId={0}
          section={SectionType.Claims}
        />
      );
    case SingleClaimSubheaderType.Documents:
      return <SingleClaimDocuments />;
    case SingleClaimSubheaderType.RelatedClaims:
      return (
        <SingleClaimRelatedClaims
          relatedClaimsData={relatedClaimsData}
        />
      );
    default: 
      return null;
  }
};
