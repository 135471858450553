export const SOFA_13A_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['25', '26a', '26b', '26c', '26d', '27'],
      queryType: 'String!'
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Name',
      propertyName: 'name',
    },
    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },

    {
      dataType: 'string',
      displayName: 'Description',
      propertyName: 'description',
    },
    {
      dataType: 'string',
      displayName: 'EIN',
      propertyName: 'ein',
    },
    {
      dataType: 'string',
      displayName: 'From Date',
      propertyName: 'fromDate',
    },
    {
      dataType: 'string',
      displayName: 'To Date',
      propertyName: 'toDate',
    },
    {
      dataType: 'string',
      displayName: 'Unavailable Explanation',
      propertyName: 'unavailableExplanation',
    },
    {
      dataType: 'string',
      displayName: 'Supervisor Name',
      propertyName: 'supervisor',
    },
    {
      dataType: 'string',
      displayName: 'Inventory Date',
      propertyName: 'inventoryDate',
    },
    {
      dataType: 'string',
      displayName: 'Amount',
      propertyName: 'amount',
    },
    {
      dataType: 'string',
      displayName: 'Basis',
      propertyName: 'basis',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
