import { ApButtonSecondary } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { ComponentProps, forwardRef } from 'react';
import { SvgIcon } from '../SvgIcon';
import './ApButtonCasual.css';

type ApButtonProps = ComponentProps<typeof ApButtonSecondary>;
interface Props extends ApButtonProps {
  svgIcon?: ComponentProps<typeof SvgIcon>['iconName'];
  thinShape?: boolean;
}

export const ApButtonCasual = forwardRef<HTMLButtonElement, Props>((props, ref) => {
  const { className, thinShape, svgIcon, children, ...buttonProps } = props;
  return (
    <ApButtonSecondary
      {...buttonProps}
      className={classNames('ap-button-casual', className, {
        'ap-button-thin': thinShape,
      })}
      medium
      ref={ref}
    >
      {svgIcon && <SvgIcon className="ap-button-casual__icon" iconName={svgIcon} />}
      {children}
    </ApButtonSecondary>
  );
});
