import { api } from 'api';
import { LinkText } from 'components/UIComponents/LinkText';
import { useNavigate } from 'react-router';
import './SettlementAgreemantHelp.css';

const urlHelpPage = '/help?section=Settlements&topics=Settings,Agreement%20Template';

export const SettlementAgreemantHelp = () => {
  const navigate = useNavigate();
  const handleDownloadHelpClick = () =>
    api.settlementAgreementTemplates.downloadHelp();

  const handleHelpPageClick = () => navigate(urlHelpPage);

  return (
    <div className="settlement-agreement-help">
      <HelpSection step={1} title="Download our Standard Agreement template">
        On this page click on the "Download A Standard Agreement Template" button.
      </HelpSection>
      <HelpSection step={2} title="Edit an Agreement document using Microsoft Word">
        You can edit the agreement's content to follow the Court's requirements and
        use tokens to populate the agreement with sensitive data (names, amounts,
        claim numbers). More info on how to create and use tokens can be{' '}
        <LinkText onClick={handleDownloadHelpClick}>found here</LinkText>.
      </HelpSection>
      <HelpSection step={3} title="Upload a new Agreement">
        After you finish editing your template you will need to upload it using the
        "Browse" button from the upload section on this page. The new uploaded
        template will be used on the Single Settlement Page to generate the
        settlement agreements - in PDF format - ready to be sent to the client and
        counterparties.
      </HelpSection>
      <div className="settlement-agreement-help__additional-info">
        For more information on how to create and edit the agreement templates please
        visit <LinkText onClick={handleHelpPageClick}>the help page</LinkText>.
      </div>
    </div>
  );
};

const HelpSection = (props: {
  step: number;
  title: React.ReactNode;
  children: React.ReactNode;
}) => {
  const { step, title, children } = props;
  return (
    <div className="settlement-agreement-help__section">
      <ol className="settlement-agreement-help__title">
        <li value={step}>{title}</li>
      </ol>
      <div className="settlement-agreement-help__body">{children}</div>
    </div>
  );
};
