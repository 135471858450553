import { Workbook } from 'exceljs';
import {
  formatDate,
  getCurrentDate,
  READABLE_MONTH_DAY_YEAR,
} from 'utils/formatDate';
import {
  rowStyleResolutionHeader,
  rowStyleResolutionValues,
  rowStyleWorflowValues,
  rowStyleWorkflowHeader,
  styleBoldText,
} from './tableStyles';
import { CellValue, excelAddRow, excelMergeCellsVertical } from './excelUtils';

const HEADER_TEXT_WORKFLOW = [
  '',
  'Workflow Status',
  'Resolution Subtype',
  'Count',
  'Percentage of all Contracts',
];

const HEADER_TEXT_RESOLUTION = [
  '',
  'Resolution Type',
  'Resolution Subtype',
  'Count',
  'Percentage of all Contracts',
  'Low',
  'High',
  'Negotiated Cure Amount',
];

export type ContractRowSection = {
  name: string;
  values: CellValue[][];
};

export type ContractData = {
  workflow: ContractRowSection[];
  resolution: ContractRowSection[];
};

export const contractTableWorkbook = async (data: ContractData) => {
  const workbook = new Workbook();
  const firstSheet = workbook.addWorksheet('Contracts Report', {
    views: [{ showGridLines: false, zoomScale: 80 }],
  });

  firstSheet.columns = [
    { width: 10 },
    { width: 25 },
    { width: 35 },
    ...Array(5).fill({ width: 26 }),
  ];

  excelAddRow(
    firstSheet,
    ['Contracts by Resolution Subtype [All entities]'],
    [styleBoldText],
  );

  excelAddRow(
    firstSheet,
    [formatDate(getCurrentDate(), READABLE_MONTH_DAY_YEAR)],
    [styleBoldText],
  );
  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);

  excelAddRow(firstSheet, HEADER_TEXT_WORKFLOW, rowStyleWorkflowHeader);

  const colNumTitle = 1;

  data.workflow.forEach(({ values, name }) => {
    const rowValues = values.map((v) => ['', '', ...v]);
    rowValues[0][colNumTitle] = name;

    const addedRows = rowValues.map((values) =>
      excelAddRow(firstSheet, values, rowStyleWorflowValues),
    );
    excelMergeCellsVertical(addedRows, colNumTitle + 1);
  });

  excelAddRow(firstSheet, []);
  excelAddRow(firstSheet, []);

  excelAddRow(firstSheet, HEADER_TEXT_RESOLUTION, rowStyleResolutionHeader);

  data.resolution.forEach(({ values, name }) => {
    const rowValues = values.map((v) => ['', '', ...v]);
    rowValues[0][colNumTitle] = name;

    const addedRows = rowValues.map((values) =>
      excelAddRow(firstSheet, values, rowStyleResolutionValues),
    );
    excelMergeCellsVertical(addedRows, colNumTitle + 1);
  });

  return workbook;
};
