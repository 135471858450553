import { ClaimRow, configuration } from 'api/endpoints';
import { GraphQlApolloClient } from 'api/graphql';
import { map } from 'lodash';
import { GridColumnsSectionType } from 'pages/ClaimsContractsToolPage/types';
import { sortByNameAsc } from './const';
import { createGraphQLQueryFromParams } from './createGraphQLQueryFromParams';
import { FilterRequest } from './utils/hotchocolateUtils';

const REQUEST_DEFAULT: FilterRequest = {
  sortModel: sortByNameAsc,
};

export const graphqlGetClaims = async (
  filter: FilterRequest,
  client: GraphQlApolloClient,
  customRequestColumns?: string[],
) => {
  const request = { ...REQUEST_DEFAULT, ...filter };

  const gridColumns = await configuration.getGridColumns(
    GridColumnsSectionType.Claim,
  );
  const requestColumns = customRequestColumns ?? map(gridColumns, 'propertyName');

  const { query, variables } = createGraphQLQueryFromParams(
    request,
    requestColumns,
    {
      queryName: 'GetClaims',
      graphqlEntity: 'claims',
      booleanColumns: [],
      gridColumns: gridColumns ?? [],
    },
  );
  
  const { data } =
    (await client.query({
      query,
      variables,
    })) ?? {};

  const result = await data?.claims?.items ?? [];
  return result as ClaimRow[];
};
