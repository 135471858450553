import dayjs from 'dayjs';

export const READABLE_MONTH_DAY_YEAR: DateFormat = 'MMMM Do, YYYY'; // January 1st, 2025
export const READABLE_DAY_MONTH_YEAR: DateFormat = 'Do MMMM YYYY'; // 9th November 2022
export const READABLE_DATETIME_FORMAT: DateFormat = 'Do MMM YYYY HH:mm'; // 9th Dec 2022 11:13
export const DEFAULT_DATE_FORMAT: DateFormat = 'YYYY/MM/DD';
export const ISO_DATE: DateFormat = 'YYYY-MM-DD';
export const US_DATE_FORMAT: DateFormat = 'MM/DD/YYYY';

export type DateFormat =
  | 'YYYY-MM-DD'
  | 'YYYY/MM/DD'
  | 'MM/DD/YYYY'
  | 'DD-MM-YYYY'
  | 'MM-DD-YYYY'
  | 'MMMM Do, YYYY'
  | 'Do MMM YYYY HH:mm'
  | 'Do MMMM YYYY';

export const getCurrentDate = () => new Date();

/**
 *
 * @param date
 * @param dateFormat
 * @returns date in specified format or defaults to 'MM/DD/YYYY'
 */
export const formatDate = (date?: string | Date, dateFormat?: DateFormat) =>
  date ? dayjs(date).format(dateFormat ?? US_DATE_FORMAT) : '';

// output example: 2023-08-23T17:13:33.658Z
export function dateISO8601toISO(value?: string) {
  if (!value) return undefined;
  return new Date(value).toISOString();
}
