import {
  ColDef,
  ITooltipParams,
  ValueFormatterParams,
  ValueGetterParams,
} from 'ag-grid-community';
import { GridColumn } from 'api/endpoints';
import classNames from 'classnames';
import _ from 'lodash';
import { FORMATTING_CURRENCY } from 'pages/ClaimsContractsToolPage/const';

import {
  dateFilterOptions,
  numberFilterOptions,
  stringFilterOptions,
} from 'utils/agGrid/columnFilter';
import { READABLE_DAY_MONTH_YEAR, formatDate } from 'utils/formatDate';
import { LegalEntityKeys } from '../../types/legalEntity';
import { CellBooleanEditor } from './TableComponents/CellBooleanEditor';
import { CellDateEditor } from './TableComponents/CellDateEditor';
import { CellDecimalEditor } from './TableComponents/CellDecimalEditor';
import { CellNumberEditor } from './TableComponents/CellNumberEditor';
import { LegalEntityEditor } from './TableComponents/LegalEntityEditor';

const DO_NOT_DISPLAY_COLUMNS: string[] = [];
const ALWAYS_DISPLAY_COLUMN: string[] = ['id'];
const NON_EDITABLE_COLUMNS: string[] = ['id'];

export const mapSofasGridColumnToColDef =
  (props: { legalEntityKeys: LegalEntityKeys }) =>
  (row: GridColumn): ColDef | undefined => {
    if (DO_NOT_DISPLAY_COLUMNS.includes(row.propertyName)) return;

    const {
      cellEditor,
      cellEditorParams,
      filter,
      filterParams,
      valueFormatter,
      valueGetter,
      tooltipValueGetter,
    } = getFieldProps(row, props.legalEntityKeys);

    return {
      field: row.propertyName,
      headerClass: row.propertyName,
      cellClass: classNames(row.propertyName, {
        currency: row.formatting === FORMATTING_CURRENCY,
      }),

      filter,
      filterParams,
      sortable: true,
      unSortIcon: true,
      resizable: true,
      cellEditor,
      cellEditorParams,
      menuTabs: [],
      editable: !NON_EDITABLE_COLUMNS.includes(row.propertyName),
      suppressColumnsToolPanel: ALWAYS_DISPLAY_COLUMN.includes(row.propertyName),
      valueFormatter,
      valueGetter,
      tooltipValueGetter,
      headerValueGetter: () => row.displayName,
    };
  };

const getFieldProps = (
  row: GridColumn,
  legalEntityKeys: LegalEntityKeys,
): Partial<ColDef> => {
  const { dataType, propertyName, allowedValues } = row;

  switch (propertyName) {
    case 'legalEntityId':
      return {
        cellEditor: LegalEntityEditor,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: _.values(legalEntityKeys),
        },
        cellEditorParams: {
          legalEntityKeys,
        },
        valueGetter: (params: ValueGetterParams) => {
          const id = params.data[propertyName];
          return legalEntityKeys[id];
        },
        tooltipValueGetter,
      };
  }

  switch (dataType) {
    case 'date':
      return {
        cellEditor: CellDateEditor,
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: dateFilterOptions,
        },
        valueFormatter: ({ value }: ValueFormatterParams) =>
          formatDate(value, READABLE_DAY_MONTH_YEAR),
      };
    case 'datetime':
      return {
        cellEditor: CellDateEditor,
        filter: 'agDateColumnFilter',
        filterParams: {
          filterOptions: dateFilterOptions,
        },
        valueFormatter: ({ value }: ValueFormatterParams) =>
          formatDate(value, READABLE_DAY_MONTH_YEAR),
      };
    case 'int':
      return {
        cellEditor: CellNumberEditor,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      };
    case 'decimal':
      return {
        cellEditor: CellDecimalEditor,
        filter: 'agNumberColumnFilter',
        filterParams: {
          filterOptions: numberFilterOptions,
        },
      };
    case 'bool':
      return {
        cellEditor: CellBooleanEditor,
        filter: 'agSetColumnFilter',
        filterParams: { values: [true, false] },
      };
    case 'enum':
      return {
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
          values: allowedValues,
        },
        filter: 'agSetColumnFilter',
        filterParams: {
          values: allowedValues,
        },
      };
    case 'string':
      return {
        filter: 'agTextColumnFilter',
        filterParams: {
          applyMiniFilterWhileTyping: true,
          filterOptions: stringFilterOptions,
        },
      };

    default:
      return {};
  }
};

const tooltipValueGetter = (params: ITooltipParams<any>) => params.value;
