import { fromCamelCase } from 'utils/format';
import { ISO_DATE, formatDate } from 'utils/formatDate';
import { formatMoney } from 'utils/formatNumber';

const inputValueFormatter = (value: string, field?: string) => {
  if (!value) return '';
  if (field === 'actionReview') {
    const result = fromCamelCase(value);
    return result.replace('Alix Partners', 'AlixPartners');
  }
  return value;
};

export const formatValue = ({
  value,
  dateField,
  field,
}: {
  value: string | number;
  dateField?: boolean;
  field?: string;
}) => {
  if (dateField && typeof value === 'string')
    return formatDate(new Date(value), ISO_DATE);
  if (typeof value === 'number') return formatMoney(value);
  return inputValueFormatter(value, field);
};
