import { useMemo, useState } from 'react';
import { ApButtonLink, ApButtonSecondary } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { ButtonIcon } from 'components/UIComponents/Buttons';
import { SvgIcon } from 'components/UIComponents/SvgIcon';
import { downloadFile } from 'pages/DataUploaderPage/api/apiDataDownload';
import { FileStatusIcon } from 'pages/DataUploaderPage/components/FileStatusIcon';
import { FileStatus, IUploadItem } from 'types/dataUploaderTypes';
import { formatDate } from 'utils/formatDate';
import { useMatchingContext } from '../../MatchingContextProvider';
import { pluralize } from 'utils/pluralize';

type Props = {
  file: IUploadItem;
};

export const MatchingPreviouslyUpload = ({ file }: Props) => {
  const [hidden, setHidden] = useState(true);

  const { onDeleteFile, fileErrors, setFileErrors } = useMatchingContext();

  const isError = useMemo(
    () => fileErrors?.fileName === file.name,
    [file, fileErrors],
  );

  const handleDelete = () => {
    setFileErrors(undefined);
    onDeleteFile(file);
  };

  return (
    <div className="matching__prev-uploaded-file flex-align-center">
      <div className="flex-align-center left-content">
        <FileStatusIcon fileStatus={file.status} isError={isError} />
        <span>
          <ApButtonLink
            className={classNames(
              'file-panel__download',
              file.status?.toLowerCase(),
              { hasError: isError },
            )}
            onClick={() => downloadFile(file)}
          >
            {file.displayName}
          </ApButtonLink>
          <br></br>
          {isError && fileErrors?.errorCount ? (
            <ApButtonSecondary
              onClick={() => setHidden((prev) => !prev)}
              className="file-panel__show-hide__button"
            >
              <span className="errors-count">
                {fileErrors?.errorCount} {pluralize('error', fileErrors?.errorCount)}
              </span>
              <span>{hidden ? 'Show' : 'Hide'}</span>
              <span
                className={classNames('file-panel__chevron', {
                  'file-panel--rotated': hidden,
                })}
              >
                <SvgIcon iconName="caret" height={10} width={10} />
              </span>
            </ApButtonSecondary>
          ) : (
            `(${file.status})`
          )}
        </span>
      </div>
      <div className="right-content flex-align-center">
        <span>
          Uploaded by {file.uploadedFile?.createdBy}
          <br></br> on {formatDate(file.uploadedFile?.createdDate)}
        </span>
        <ButtonIcon
          width={40}
          height={34}
          iconColor="var(--ap-color-ui-grey-darker)"
          icon="download_file"
          onClick={() => downloadFile(file)}
        />
        {file.status !== FileStatus.Published && (
          <ButtonIcon width={40} height={34} icon="trash" onClick={handleDelete} />
        )}
      </div>
      {!!fileErrors?.errorMessages?.length && !hidden && (
        <div className="errors-content">
          {fileErrors.errorMessages.map((e, idx) => (
            <span className="file-panel__error" key={`error${idx}`}>
              <pre>{e}</pre>
            </span>
          ))}
        </div>
      )}
    </div>
  );
};
