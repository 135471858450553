import React, { useCallback, useEffect } from 'react';
import { ApButtonMain } from '@alixpartners/ui-components';
import classNames from 'classnames';
import { Pagination } from 'components/Pagination';
import Select from 'components/UIComponents/Select';
import './SingleClaimContractChangesComments.css';
import { ClaimContractLogPaginationSort } from 'api/endpoints/claimLogs';
import { downloadFile } from 'api/apiRequest';
import {
  generateClaimChangeLogExportDownloadUrl,
  generateContractChangeLogExportDownloadUrl,
} from 'utils/uploader';
import { FetchStatus } from 'types/fetch-status.types';
import { SORT_OPTIONS } from 'pages/SingleClaimPage/types';
import { ChangesCommentsList } from './ChangesCommentsList/ChangesCommentsList';
import { useClaimContractLogPagination } from 'pages/SingleClaimPage/hooks/useClaimContractLogPagination';
import { useClaimContractChangesCommentsLogs } from 'pages/ClaimsContractsToolPage/hooks/useClaimContractChangesCommentsLogs';
import { SectionType } from 'pages/ClaimsContractsToolPage/types';
import { useAppDispatch } from 'hooks/reducerHooks';
import {
  setContractLogsIdle,
  paginationChanged as contractsPaginationChanged,
} from 'reducer/contractLogsDataReducer';
import {
  paginationChanged as claimsPaginationChanged,
  setClaimLogsIdle,
} from 'reducer/claimLogsDataReducer';

import { ApIcon } from '@alixpartners/ui-components';

export const SingleClaimContractChangesComments = ({
  claimId,
  contractId,
  section,
}: {
  claimId: number;
  contractId: number;
  section: SectionType;
}) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(setContractLogsIdle());
    dispatch(setClaimLogsIdle());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logPagination = useClaimContractLogPagination();
  const { page, setSort, sort, formatUrl } = logPagination;
  const id = section === SectionType.Claims ? claimId : contractId;
  const logEntriesResult = useClaimContractChangesCommentsLogs(
    {
      id,
      currentPage: page,
      currentSort: sort,
    },
    section,
  );

  const handlePaginationChange = (pageNo: number) => {
    if (section === SectionType.Claims) {
      dispatch(
        claimsPaginationChanged({ currentPage: pageNo, id, currentSort: sort }),
      );
    } else if (section === SectionType.Contracts) {
      dispatch(
        contractsPaginationChanged({ currentPage: pageNo, id, currentSort: sort }),
      );
    }
  };

  const handleSortChange = useCallback(
    (e: React.ChangeEvent<HTMLSelectElement>) => {
      if (section === SectionType.Claims) {
        dispatch(setClaimLogsIdle());
      } else if (section === SectionType.Contracts) {
        dispatch(setContractLogsIdle());
      }

      const target = e.target as HTMLSelectElement;
      const sort = target.value as ClaimContractLogPaginationSort;
      setSort(sort);
    },
    [setSort, dispatch, section],
  );
  const hasEntries = !!logEntriesResult.data?.entries?.length;

  return (
    <div
      className={classNames('single-claim-changes-comments', {
        'sk-on': logEntriesResult.status === FetchStatus.Fetching,
        'utils-obstruct': !hasEntries,
      })}
    >
      <h6 className="warning">
        <ApIcon iconName="error" iconColor="#D97706" />
        <span> Changes and comments may take up to 15 minutes to display here</span>
      </h6>

      <div
        className={classNames('single-claim-changes-comments__bar', {
          'utils-none': !hasEntries,
        })}
      >
        <ExportsDownloadAction
          claimId={claimId}
          contractId={contractId}
          disabled={logEntriesResult.status === FetchStatus.Fetching || !hasEntries}
        />
        <Select
          options={SORT_OPTIONS}
          onChange={handleSortChange}
          defaultValue={sort}
        />
      </div>
      <ChangesCommentsList logEntriesResult={logEntriesResult} section={section} />
      <Pagination
        current={page}
        formatUrl={formatUrl}
        className={classNames('single-claim-changes-pagination', {
          'utils-hidden': !hasEntries,
        })}
        showWhenSinglePage={false}
        total={logEntriesResult.data?.totalPages ?? 0}
        handlePaginationChange={handlePaginationChange}
      />
    </div>
  );
};

export function downloadLogs(
  id: number,
  fileNameWhenDownloaded: string,
  downloadContracts: boolean,
) {
  const url = downloadContracts
    ? generateContractChangeLogExportDownloadUrl(id)
    : generateClaimChangeLogExportDownloadUrl(id);
  return downloadFile(url, fileNameWhenDownloaded);
}

export const ExportsDownloadAction = ({
  claimId = 0,
  disabled,
  contractId = 0,
}: {
  claimId: number;
  contractId: number;
  disabled?: boolean;
}) => {
  const handleClick = (event: React.MouseEvent<Element, MouseEvent>) => {
    const id = contractId ? +contractId : +claimId;
    const name = contractId
      ? `contract-${contractId}-change-logs.xlsx`
      : `claim-${claimId}-change-logs.xlsx`;
    event.preventDefault();
    downloadLogs(+id, name, !!contractId);
  };

  return (
    <ApButtonMain
      onClick={handleClick}
      disabled={disabled}
      iconName="download"
      medium
    >
      Export Log
    </ApButtonMain>
  );
};
