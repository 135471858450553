import { downloadBlobStorageFile } from 'api/apiRequest';
import {
  backgroundJobs,
  BackgroundJobStatus,
  ExcelBackgroundJobStatus,
} from 'api/endpoints';
import { useAppDispatch, useAppSelector } from 'hooks/reducerHooks';
import { useCallback, useRef } from 'react';
import { setExportState } from 'reducer/claimsContractsExportReducer';
import { error } from 'utils/alert';
import { ISO_DATE, formatDate } from 'utils/formatDate';
import { useUpdateEffect } from 'utils/hooks/useUpdateEffect';
import { SectionType } from '../types';

const INTERVAL = 5000;

export const useExportBackgroundJob = (activeSection: SectionType) => {
  const dispatch = useAppDispatch();
  const intevalRef = useRef<NodeJS.Timeout>();
  const { fileName, generating, jobId, section } = useAppSelector(
    (s) => s.claimsContractsExport,
  );

  // we use ref instead of state to persist value between re-renders
  // so we don't queue status API calls if prev one took longer than POLL_INTERVAL to complete
  const statusFetching = useRef(false);

  const clearExportState = useCallback(() => {
    statusFetching.current = false;
    dispatch(setExportState({ generating: false }));
  }, [dispatch]);

  const getFileExtension = useCallback((fileName: string) => {
    return fileName.indexOf('.csv') > 0 ? '.csv' : '.xlsx';
  }, []);

  const getJobStatus = useCallback(async () => {
    if (!jobId || statusFetching.current) return;

    statusFetching.current = true;
    const resp =
      await backgroundJobs.getBackgroundJobStatus<ExcelBackgroundJobStatus>(jobId);
    if (!resp) {
      clearInterval(intevalRef.current);
      statusFetching.current = false;
      return;
    }
    switch (resp.status) {
      case BackgroundJobStatus.Completed: {
        clearInterval(intevalRef.current);
        if (resp.data) {
          const fileName = `${resp.name}_${formatDate(
            new Date(),
            ISO_DATE,
          )}${getFileExtension(resp.data.fileDownloadUrl)}`;
          await downloadBlobStorageFile(resp.data.fileDownloadUrl, fileName);
        } else {
          error(
            "Couldn't generate file, no download URL was returned. Please try again",
          );
        }
        clearExportState();
        break;
      }
      case BackgroundJobStatus.Failed: {
        clearInterval(intevalRef.current);
        error("Couldn't generate file. Please try again");
        clearExportState();
        break;
      }
      default:
        statusFetching.current = false;
    }
  }, [jobId, clearExportState, getFileExtension]);

  const runExportBackgroundJob = useCallback(() => {
    intevalRef.current = setInterval(getJobStatus, INTERVAL);
  }, [getJobStatus]);

  useUpdateEffect(() => {
    // this hook is called from both useClaimsData and useContractsData
    // so we poll only for the section where the export was generated from
    if (generating && jobId && activeSection === section) {
      runExportBackgroundJob();
    }
  }, [generating, jobId, activeSection]);

  return {
    generating,
    fileName,
  };
};
