export const SCHEDULE_AB_1_COLUMNS = {
  gridColumns: [
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      allowedValues: ['Cash', 'CheckingSavings', 'Other'],
      dataType: 'enum',
      displayName: 'Asset Type',
      propertyName: 'assetValueType',
      queryType: 'ScheduleAssetValueType'
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Name',
      propertyName: 'name',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Type of Account',
      propertyName: 'typeOfAccount',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Last 4 Acc Number',
      propertyName: 'last4AccNumber',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Value',
      propertyName: 'value',
    },
    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },

    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      allowedValues: null,
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
