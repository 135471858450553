import { ApButtonMain } from '@alixpartners/ui-components';
import './AddButton.css';

type AddButtonProps = {
  disabled?: boolean;
  onClick?: (event: React.MouseEvent) => void;
};

export function AddButton(props: AddButtonProps) {
  return (
    <ApButtonMain
      disabled={props.disabled}
      onClick={props.onClick}
      className={'sofas-schedules-add-btn'}
      type="button"
      iconName="add"
      medium
    >
      Add New
    </ApButtonMain>
  );
}
