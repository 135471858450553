import { SortModelItem } from 'ag-grid-community';
import _, { isEmpty } from 'lodash';
import concat from 'lodash/concat';
import forEach from 'lodash/forEach';
import map from 'lodash/map';
import { ColumnBase } from 'utils/agGrid/agGridColumn';

import { GRAPHQL_VAR_NAME } from '../const';
import {
  AgGridFilter,
  AgGridFilterModel,
  DateFilter,
  DateFilterMulti,
  DateFilterOne,
  DateFilterRange,
  DateOperator,
  FilterType,
  isDateFilterMulti,
  isDateFilterRange,
  IServerSideGetRowsRequestWithFilterModel,
  isNumberFilterMulti,
  isNumberFilterRange,
  isTextFilterMulti,
  NumberFilter,
  NumberFilterMulti,
  NumberFilterOne,
  NumberFilterRange,
  NumberOperator,
  SetFilter,
  TextFilter,
  TextFilterMulti,
  TextFilterOne,
  TextOperator,
} from '../types';
import {
  dicomposeVariableType,
  QueryFilterItem,
  VariableType,
  VariableValue,
  WhereQuery,
} from './types';

export type FilterRequest = Partial<IServerSideGetRowsRequestWithFilterModel>;

// documentation of C# graphql operators:
// https://chillicream.com/docs/hotchocolate/fetching-data/filtering

export const createGraphQLOrder = (
  sortModel: SortModelItem[],
  unmatchedClaims?: boolean,
) => {
  const order: Record<string, 'DESC' | 'ASC'> = {};

  sortModel?.forEach((m) => {
    order[m.colId] = m.sort.toUpperCase() as 'DESC' | 'ASC';
  });

  if (unmatchedClaims) {
    order['numberOfMatches'] = 'DESC';
  }

  return order;
};

export function agGridFilterModelToWhereStatement(
  filterModel: AgGridFilterModel,
  booleanColumns?: string[],
): WhereQuery {
  if (isEmpty(filterModel)) return { query: {}, variables: [] };

  let index = 0;
  const queryList: QueryFilterItem[] = [];
  let variables: VariableType[] = [];
  forEach(filterModel, (filter, key) => {
    const isBoolean = booleanColumns?.length ? booleanColumns.includes(key) : false;
    const [query, vars] = agGridFilterToGraphQL(filter, key, index, isBoolean);

    index += vars.length;
    queryList.push(query);
    variables = concat(variables, vars);
  });
  /*
    and = [
      { counterpartyName: { eq: $var0 }} }
      { matchCount: { or: [{ eq: $var1 }, { eq: $var2 }]} },
    ]
    variables = ["hello", 5, 10]
  */
  if (queryList.length > 1) {
    return {
      query: { and: queryList },
      variables,
    };
  }

  // query = { counterpartyName: { eq: $var1 }} }
  // variables = ["hello"]
  return {
    query: queryList[0],
    variables,
  };
}

export const createVariablesObject = (variables: VariableType[]) => {
  if (!variables.length) return {};

  const variablesObject = variables.reduce((acc, v, index) => {
    const { value } = dicomposeVariableType(v);

    acc[`${GRAPHQL_VAR_NAME}${index}`] = value;
    return acc;
  }, {} as Record<string, VariableValue>);

  return variablesObject;
};

export const createVaribalesArguments = (
  variables: VariableType[],
  gridColumns: ColumnBase[],
) => {
  if (!variables.length) return '';

  const varString = variables
    .map((value, index) => {
      const type = getGraphQlType(value, gridColumns);
      return `$${GRAPHQL_VAR_NAME}${index}: ${type}`;
    })
    .join(', ');

  return !isEmpty(varString) ? `(${varString})` : '';
};

function graphQLOperatorToHotchocolateOperator(
  filterType: FilterType,
  operator: TextOperator | NumberOperator | DateOperator,
) {
  const MAP = {
    text: {
      contains: 'contains',
      notContains: 'ncontains',
      equals: 'eq',
      notEqual: 'neq',
      startsWith: 'startsWith',
      endsWith: 'endsWith',
    },
    number: {
      equals: 'eq',
      notEqual: 'neq',
      lessThan: 'lt',
      lessThanOrEqual: 'lte',
      greaterThan: 'gt',
      greaterThanOrEqual: 'gte',
    },
    date: {
      equals: 'eq',
      notEqual: 'neq',
      lessThan: 'lt',
      greaterThan: 'gt',
    },
  };
  const operators = MAP[filterType];
  return (operators as any)[operator];
}

export const agGridTextOperator = (
  filter: TextFilter,
  key: string,
  variableStartIndex: number,
) => {
  if (isTextFilterMulti(filter)) {
    const multi = filter as TextFilterMulti;
    const andOroperator = multi.operator.toLowerCase();
    const firstConditionFilterOperator = graphQLOperatorToHotchocolateOperator(
      multi.filterType,
      multi.condition1.type,
    );
    const secondConditionFilterOperator = graphQLOperatorToHotchocolateOperator(
      multi.filterType,
      multi.condition2.type,
    );
    return [
      {
        [andOroperator]: [
          {
            [key]: {
              [firstConditionFilterOperator]: `$${GRAPHQL_VAR_NAME}${variableStartIndex}`,
            },
          },
          {
            [key]: {
              [secondConditionFilterOperator]: `$${GRAPHQL_VAR_NAME}${
                variableStartIndex + 1
              }`,
            },
          },
        ],
      },
      [multi.condition1.filter, multi.condition2.filter],
    ];
  }
  const single = filter as TextFilterOne;
  const filterOperator = graphQLOperatorToHotchocolateOperator(
    single.filterType,
    single.type,
  );

  return [
    {
      [key]: {
        [filterOperator]: `$${GRAPHQL_VAR_NAME}${variableStartIndex}`,
      },
    },
    [single.filter],
  ];
};

export const agGridNumberOperator = (
  filter: NumberFilter,
  key: string,
  variableStartIndex: number,
) => {
  if (isNumberFilterRange(filter)) {
    const range = filter as NumberFilterRange;
    return [
      {
        and: [
          { [key]: { gte: `$${GRAPHQL_VAR_NAME}${variableStartIndex}` } },
          { [key]: { lte: `$${GRAPHQL_VAR_NAME}${variableStartIndex + 1}` } },
        ],
      },
      [
        { key, value: range.filter },
        { key, value: range.filterTo },
      ],
    ];
  }
  if (isNumberFilterMulti(filter)) {
    const multi = filter as NumberFilterMulti;
    const andOroperator = multi.operator.toLowerCase();
    const firstConditionFilterOperator = graphQLOperatorToHotchocolateOperator(
      multi.filterType,
      multi.condition1.type,
    );
    const secondConditionFilterOperator = graphQLOperatorToHotchocolateOperator(
      multi.filterType,
      multi.condition2.type,
    );
    return [
      {
        [andOroperator]: [
          {
            [key]: {
              [firstConditionFilterOperator]: `$${GRAPHQL_VAR_NAME}${variableStartIndex}`,
            },
          },
          {
            [key]: {
              [secondConditionFilterOperator]: `$${GRAPHQL_VAR_NAME}${
                variableStartIndex + 1
              }`,
            },
          },
        ],
      },
      [
        { key, value: multi.condition1.filter },
        { key, value: multi.condition2.filter },
      ],
    ];
  }
  const single = filter as NumberFilterOne;
  const filterOperator = graphQLOperatorToHotchocolateOperator(
    single.filterType,
    single.type,
  );

  return [
    {
      [key]: {
        [filterOperator]: `$${GRAPHQL_VAR_NAME}${variableStartIndex}`,
      },
    },
    [{ key, value: single.filter }],
  ];
};

const convertDateWithoutTime = (date: string) => date.slice(0, 10);

export const agGridDateOperator = (
  filter: DateFilter,
  key: string,
  variableStartIndex: number,
): any => {
  if (isDateFilterRange(filter)) {
    const range = filter as DateFilterRange;
    return [
      {
        and: [
          { [key]: { gte: `$${GRAPHQL_VAR_NAME}${variableStartIndex}` } },
          { [key]: { lte: `$${GRAPHQL_VAR_NAME}${variableStartIndex + 1}` } },
        ],
      },
      [
        { key, value: convertDateWithoutTime(range.dateFrom) },
        { key, value: convertDateWithoutTime(range.dateTo) },
      ],
    ];
  }
  if (isDateFilterMulti(filter)) {
    const multi = filter as DateFilterMulti;
    const andOroperator = multi.operator.toLowerCase();
    let additionToStartIndex = 0;
    let allVars: any[] = [];
    let firstQuery;
    let secondQuery;
    if (isDateFilterRange(multi.condition1)) {
      const [query, vars] = agGridDateOperator(
        multi.condition1,
        key,
        variableStartIndex,
      );
      additionToStartIndex += vars.length;
      firstQuery = query;
      allVars = concat(allVars, vars);
    } else {
      firstQuery = {
        [key]: {
          [graphQLOperatorToHotchocolateOperator(
            multi.filterType,
            multi.condition1.type,
          )]: `$${GRAPHQL_VAR_NAME}${variableStartIndex}`,
        },
      };

      const vars = [
        { key, value: convertDateWithoutTime(multi.condition1.dateFrom) },
      ];
      additionToStartIndex++;
      allVars = concat(allVars, vars);
    }
    if (isDateFilterRange(multi.condition2)) {
      const [query, vars] = agGridDateOperator(
        multi.condition2,
        key,
        variableStartIndex + additionToStartIndex,
      );
      secondQuery = query;
      allVars = concat(allVars, vars);
    } else {
      secondQuery = {
        [key]: {
          [graphQLOperatorToHotchocolateOperator(
            multi.filterType,
            multi.condition2.type,
          )]: `$${GRAPHQL_VAR_NAME}${variableStartIndex + additionToStartIndex}`,
        },
      };
      const vars = [
        { key, value: convertDateWithoutTime(multi.condition2.dateFrom) },
      ];
      allVars = concat(allVars, vars);
    }

    return [
      {
        [andOroperator]: [firstQuery, secondQuery],
      },
      allVars,
    ];
  }
  const single = filter as DateFilterOne;
  const filterOperator = graphQLOperatorToHotchocolateOperator(
    single.filterType,
    single.type,
  );
  return [
    {
      [key]: {
        [filterOperator]: `$${GRAPHQL_VAR_NAME}${variableStartIndex}`,
      },
    },
    [{ key, value: convertDateWithoutTime(single.dateFrom) }],
  ];
};

export const agGridSetOperator = (
  filter: SetFilter,
  key: string,
  variableStartIndex: number,
  booleanColumn?: boolean,
) => {
  const vars: VariableType[] = booleanColumn
    ? filter.values.map((b) => b === true || b === 'true')
    : filter.values.map(value => ({key, value}));
  const variables = [...vars];

  if (vars.length === 0) {
    // in case vars array is empty, we should always return some condition that will return false
    return [
      {
        id: { lt: 0 },
      },
      [],
    ];
  }

  if (vars.length === 1) {
    return [
      {
        [key]: { eq: `$${GRAPHQL_VAR_NAME}${variableStartIndex}` },
      },
      variables,
    ];
  }

  return [
    {
      or: map(vars, (_, index) => ({
        [key]: { eq: `$${GRAPHQL_VAR_NAME}${variableStartIndex + index}` },
      })),
    },
    variables,
  ];
};

export function agGridFilterToGraphQL(
  filter: AgGridFilter,
  key: string,
  variableStartIndex: number,
  booleanColumn?: boolean,
) {
  switch (filter.filterType) {
    case 'text':
      return agGridTextOperator(filter as TextFilter, key, variableStartIndex);
    case 'date':
      return agGridDateOperator(filter as DateFilter, key, variableStartIndex);
    case 'number':
      return agGridNumberOperator(filter as NumberFilter, key, variableStartIndex);
    case 'set':
      return agGridSetOperator(
        filter as SetFilter,
        key,
        variableStartIndex,
        booleanColumn,
      );
  }
}

function getGraphQlType(inputValue: VariableType, gridColumns: ColumnBase[]) {
  const { value, key, type } = dicomposeVariableType(inputValue);

  if (typeof value === 'boolean') {
    return 'Boolean!';
  }

  const column = gridColumns.find((r) => r.propertyName === key);

  if (column?.queryType) return column?.queryType;

  if (typeof value === 'number') {
    //additional columns that have Int! type
    const ID_COLUMNS = ['id', 'applicationUserId', 'actionNameId'];
    if (ID_COLUMNS.includes(key!)) {
      return 'Int!';
    }

    if (column?.dataType === 'int' || type === 'int') {
      return 'Int!';
    }

    if (column?.dataType === 'decimal' || type === 'decimal') {
      return 'Decimal!';
    }

    return isInt(value) ? 'Int!' : 'Decimal!';
  }

  if (_.isDate(value) || ['date', 'datetime'].includes(column?.dataType!)) {
    return 'DateTime!';
  }

  if (column?.dataType === 'enum') {
    return 'String!';
  }

  return 'String!';
}

export function isInt(n: number) {
  return n % 1 === 0;
}
