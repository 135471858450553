import { ApButtonLink, ApButtonSecondary } from '@alixpartners/ui-components';
import { useCallback } from 'react';
import { useNavigate } from 'react-router';
import './LinkButton.css';

export const LinkButtonSecondary = (props: {
  text: string;
  icon?: string;
  href?: string;
  onClick?: () => void;
}) => {
  const { text, icon, href, onClick } = props;
  const navigate = useNavigate();

  const handleClick = useCallback(() => {
    if (href) {
      navigate(href);
      return;
    }

    onClick?.();
  }, [href, navigate, onClick]);

  return (
    <ApButtonSecondary
      className="ap-link-button-secondary"
      iconName={icon}
      onClick={handleClick}
      medium
    >
      {text}
    </ApButtonSecondary>
  );
};

export const LinkButtonText = (props: {
  text: string;
  href: string;
  onClick: () => void;
}) => {
  const { text, href, onClick } = props;
  return (
    <ApButtonLink className="ap-link-button-text" href={href} onClick={onClick}>
      {text}
    </ApButtonLink>
  );
};
