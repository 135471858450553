export const SOFA_13B_COLUMNS = {
  gridColumns: [
    {
      dataType: 'enum',
      displayName: 'Question',
      propertyName: 'section',
      allowedValues: ['28', '29', '30', '31', '32'],
      queryType: 'String!'
    },
    {
      dataType: 'string',
      displayName: 'Legal Entity',
      propertyName: 'legalEntityId',
    },
    {
      dataType: 'string',
      displayName: 'Name',
      propertyName: 'name',
    },
    {
      dataType: 'string',
      displayName: 'Address 1',
      propertyName: 'address1',
    },
    {
      dataType: 'string',
      displayName: 'Address 2',
      propertyName: 'address2',
    },
    {
      dataType: 'string',
      displayName: 'Address 3',
      propertyName: 'address3',
    },
    {
      dataType: 'string',
      displayName: 'City',
      propertyName: 'city',
    },
    {
      dataType: 'string',
      displayName: 'State',
      propertyName: 'state',
    },
    {
      dataType: 'string',
      displayName: 'Zip',
      propertyName: 'zip',
    },
    {
      dataType: 'string',
      displayName: 'Country',
      propertyName: 'country',
    },

    {
      dataType: 'string',
      displayName: 'Position and nature of any interest',
      propertyName: 'position',
    },
    {
      dataType: 'string',
      displayName: '% of Interest',
      propertyName: 'interest',
    },
    {
      dataType: 'string',
      displayName: 'From Date',
      propertyName: 'fromDate',
    },
    {
      dataType: 'string',
      displayName: 'To Date',
      propertyName: 'toDate',
    },
    {
      dataType: 'string',
      displayName: 'Amount',
      propertyName: 'amount',
    },
    {
      dataType: 'string',
      displayName: 'Amount Description',
      propertyName: 'amountDescription',
    },
    {
      dataType: 'string',
      displayName: 'Dates',
      propertyName: 'dates',
    },
    {
      dataType: 'string',
      displayName: 'Reason',
      propertyName: 'reason',
    },
    {
      dataType: 'string',
      displayName: 'EIN',
      propertyName: 'ein',
    },
    {
      dataType: 'string',
      displayName: 'Relationship to Debtor',
      propertyName: 'relationshipToDebtor',
    },

    {
      allowedValues: ['No', 'All', 'Address', 'Name'],
      dataType: 'enum',
      displayName: 'Redact',
      propertyName: 'redact',
      queryType: 'RedactType'
    },
    {
      dataType: 'string',
      displayName: 'Footnote',
      propertyName: 'footnote',
    },
    {
      dataType: 'string',
      displayName: 'Entity Comments',
      propertyName: 'entityComments',
    },
  ],
};
